<template>
  <div class="content">
    <h1 :style="`font-size: var(--el-font-size-extra-large)`">亮點成果清單管理</h1>
    <El-Table
      :data="state.highlights"
      style="width: 100%"
      :default-sort = "{prop: 'order', order: 'ascending'}"
      v-loading="state.loading"
    >
      <El-Table-Column prop="id" label="Id" width="60" sortable />
      <!-- <El-Table-Column prop="time" label="時間" width="120">
        <template #default="scope">
          <p>{{formatTime(scope.row.time, 'YYYY/MM/DD')}}</p>
          <p>{{formatTime(scope.row.time, 'HH:mm:ss')}}</p>
        </template>
      </El-Table-Column> -->
      <El-Table-Column prop="imgSrc" label="Banner" width="120">
        <template #default="scope">
          <El-Image
            style="width: 100px; height: 100px"
            :src="scope.row.imgSrc"
            fit="cover"
          ></El-Image>
        </template>
      </El-Table-Column>
      <El-Table-Column prop="title" label="標題" />
      <El-Table-Column prop="text" label="說明" />
      <El-Table-Column label="順序" width="80">
        <template #default="scope">
          <El-Row justify="space-between" align="middle">
            <El-Button class="flip" type="text" size="small" @click="handleChangeOrder(scope.row, scope.row.order - 1)" :disabled="scope.row.order==0"><El-Icon><SortUp /></El-Icon></El-Button>
            <span>{{scope.row.order}}</span>
            <El-Button class="flip" type="text" size="small" @click="handleChangeOrder(scope.row, scope.row.order + 1)" :disabled="state.highlights.length-1==scope.row.order"><El-Icon><SortDown /></El-Icon></El-Button>
          </El-Row>
        </template>
      </El-Table-Column>
      <El-Table-Column fixed="right" label="" width="120" align="right">
        <template #header>
          <El-Link v-if="state.isAdmin" type="primary" href="/admin/highlights/new">New</El-Link>
        </template>
        <template #default="scope">
          <El-Button type="text" size="small" @click="handleEdit(scope.$index, scope.row)">Edit</El-Button>
          <El-Button type="text" size="small" @click="handleDelete(scope.$index, scope.row)" v-if="state.isAdmin">Delete</El-Button>
        </template>
      </El-Table-Column>
    </El-Table>
  </div>
</template>

<script lang="ts">
import { defineComponent, getCurrentInstance, reactive } from 'vue'
import ManagementLayout from '@/layout/ManagementLayout.vue'
import { useRouter } from 'vue-router'
import { usrState, Highlight, updateResource } from '@/model'
import { formatTime, isAuthUserAdmin } from '@/utils'
import { SortDown, SortUp } from '@element-plus/icons'
import { ElLoading } from 'element-plus'
import api from '@/api'

export default defineComponent({
  name: 'ManageHighlightListPage',
  components: {
    SortDown,
    SortUp,
  },
  created() {
    this.$emit('update:layout', ManagementLayout)
  },
  setup() {
    const proxy = getCurrentInstance()?.proxy
    const router = useRouter()

    const state = reactive({
      isAdmin: false,
      highlights: usrState.highlights,
      loading: false,
    })

    isAuthUserAdmin().then(isAdmin => {
      state.isAdmin = isAdmin
    })

    const handleEdit = (index: number, item: Highlight) => {
      router.push('/admin/highlights/' + item.id)
    }

    const handleDelete = (index: number, highlight: Highlight) => {
      console.log('handleDeleteHighlight', { index, highlight })
      proxy?.$confirm(`確認刪除「${highlight.title}(${highlight.id})」？`)
        .then(async () => {
          const loading = ElLoading.service({
            lock: true,
            text: 'Loading',
            background: 'rgba(0, 0, 0, 0.7)',
          })

          api.deleteHighlight(highlight.id)
            .then(() => updateResource())
            .then(() => loading.close())
            .catch(() => loading.close())
        })
        .catch(() => { /* prevent exception */ })
    }

    const handleChangeOrder = (highlight: Highlight, newOrder: number) => {
      console.log('handleChangeOrder', { highlight, newOrder })
      proxy?.$confirm(`修改順序「${highlight.title}(${highlight.id})」？`)
        .then(async () => {
          const loading = ElLoading.service({
            lock: true,
            text: 'Loading',
            background: 'rgba(0, 0, 0, 0.7)',
          })

          api.updateHighlight({ ...highlight, order: newOrder })
            .then(() => updateResource())
            .then(() => loading.close())
            .catch(() => loading.close())
        })
        .catch(() => { /* prevent exception */ })
    }

    return {
      state,
      handleEdit,
      handleDelete,
      handleChangeOrder,
      formatTime,
    }
  },
})
</script>

<style lang="scss" scoped>
.flip {
  transform: scaleX(-1);
}
</style>
