<template>
  <El-Container class="management-layout">
    <El-Header>
      <router-link to="/"><img src="../assets/logo.svg" alt="" class="logo"></router-link>
      NTUST USR Management
      <div class="user">
        {{state.username}}
        <el-button type="text" @click="logout">Logout</el-button>
      </div>
    </El-Header>
    <El-Container>
      <El-Aside width="200px">
        <El-Menu
          :uniqueOpened="true"
          :default-active="$route.path"
          class="el-menu-vertical-demo"
          background-color="#545c64"
          text-color="#fff"
          active-text-color="#ffd04b"
          router
          v-if="state.username"
        >
          <El-Menu-Item index="/admin">
            <template #title><El-Icon><House /></El-Icon>首頁</template>
          </El-Menu-Item>
          <El-Menu-Item index="/admin/activities" v-if="state.isAdmin" :class="state.activityClass">
            <template #title><El-Icon><Files /></El-Icon>近期活動清單</template>
          </El-Menu-Item>
          <El-Menu-Item index="/admin/news" :class="state.newsClass">
            <template #title><El-Icon><Message /></El-Icon>電⼦報清單</template>
          </El-Menu-Item>
          <El-Menu-Item index="/admin/about">
            <template #title><El-Icon><ChatLineSquare /></El-Icon>關於我們</template>
          </El-Menu-Item>
          <El-Menu-Item index="/admin/projects" :class="state.projectClass">
            <template #title><El-Icon><FolderOpened /></El-Icon>團隊與場域</template>
          </El-Menu-Item>
          <El-Menu-Item index="/admin/history" v-if="state.isAdmin">
            <template #title><El-Icon><Document /></El-Icon>實踐紀錄</template>
          </El-Menu-Item>
          <El-Menu-Item index="/admin/highlights" :class="state.highlightClass">
            <template #title><El-Icon><Collection /></El-Icon>亮點成果清單</template>
          </El-Menu-Item>
        </El-Menu>
      </El-Aside>
      <El-Main>
        <slot />
      </El-Main>
    </El-Container>
  </El-Container>
</template>

<script lang="ts">
import { computed, defineComponent, reactive, watch } from 'vue'

import { useRoute } from 'vue-router'
import * as Icons from '@element-plus/icons'
import { Auth } from 'aws-amplify'
import router from '@/router'
import { isAuthUserAdmin } from '@/utils'

export default defineComponent({
  name: 'ManagementLayout',
  props: {
    path: String,
  },
  components: {
    ...Icons,
  },
  setup() {
    const route = useRoute()

    const state = reactive({
      isAdmin: false,
      username: '',
      activityClass: computed(() => ({ 'is-active': route.path.match(/admin\/activities/g) })),
      newsClass: computed(() => ({ 'is-active': route.path.match(/admin\/news/g) })),
      projectClass: computed(() => ({ 'is-active': route.path.match(/admin\/projects/g) })),
      highlightClass: computed(() => ({ 'is-active': route.path.match(/admin\/highlights/g) })),
    })

    isAuthUserAdmin().then(isAdmin => {
      state.isAdmin = isAdmin
    })

    const logout = () => {
      Auth.signOut().then(() => router.push('/admin/login'))
    }

    const checkAuth = () => {
      Auth.currentAuthenticatedUser()
        .then(data => {
          state.username = data.username
        })
        .catch(() => router.push('/'))
    }

    checkAuth()

    watch(() => route.path, checkAuth)

    return {
      state,
      logout,
    }
  },
})
</script>
<style lang="scss">
.management-layout {
  height: 100vh;
  overflow: hidden;

  .el-header {
    height: 78px;
    background: #EBEBEB;
    display: flex;
    align-items: center;

    a {
      display: flex;
    }

    .logo{
      margin-right: 20px;
      height: 31px;
    }

    .user {
      margin-left: auto;
    }
  }

  .el-container {
    flex: 1;
    overflow: auto;

    .el-aside {
      background-color: #545c64;

      .el-menu {
        border-right: none;
      }
    }
  }

  h1 + h2 {
    margin-top: 10px;
  }

  h2 + * {
    margin-top: 10px;
  }

  .el-form-item__content {
    display: block
  }
  .el-button--text {
    padding-left: 0;
    padding-right: 0;
  }
}

.el-date-table-cell__text { /** should fix text-align: justify later */
  text-align: center;
}
</style>
