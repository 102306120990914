<template>
  <div class="usr-link">
    <span>影片連結</span>
    <a :href="url" target="_blank">{{title}}</a>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Link1',
  props: {
    title: {
      type: String,
      required: true,
    },
    url: {
      type: String,
      required: true,
    }
  },
  setup() {
  },
})
</script>

<style lang="scss">
.usr-link {
  display: flex;
  align-items: center;

  span {
    display: block;
    padding: 3px 18px 6px;
    font: normal normal bold 23px/28px Arial;
    letter-spacing: 1.15px;
    color: #FFFFFF;
    background-color: #277B32;
  }

  a {
    margin-left: 17px;
    text-decoration: underline;
    font: normal normal normal 22px/26px Arial;
    letter-spacing: 1.1px;
    color: #4C2A13;
  }
}

@media screen and (max-width: 991px) {
  .usr-link {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    span {
      padding: 1px 6px 1px;
      font-size: 11px;
      letter-spacing: 0.55px;
      line-height: 13.2px;
    }

    a {
      margin-left: 0;
      margin-top: 7px;
      font-size: 11px;
      letter-spacing: 0.55px;
      line-height: 13.2px;
    }
  }
}
</style>
