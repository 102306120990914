
import { defineComponent, reactive } from 'vue'
import MainLayout from '@/layout/MainLayout.vue'
import Banner from '@/components/Banner.vue'
import Breadcrumb from '@/components/Breadcrumb.vue'
import Title1 from '@/components/Title1.vue'
import Title4 from '@/components/Title4.vue'
import USRComponentList from '@/components/USRComponentList.vue'
import { useUsrHelper } from '@/model'

export default defineComponent({
  name: 'Activity',
  components: {
    Banner,
    Breadcrumb,
    Title1,
    Title4,
    USRComponentList,
  },
  created() {
    this.$emit('update:layout', MainLayout)
  },
  setup () {
    const helper = useUsrHelper()

    const state = reactive({
      activity: helper.activity,
    })

    return {
      state,
      dmStyle: (imgUrl: string) => ({ backgroundImage: `url(${imgUrl})` })
    }
  }
})
