import { createApp } from 'vue'
import { Auth } from 'aws-amplify'
import App from './App.vue'
import router from './router'
import installElementPlus from './plugins/element'
import '../node_modules/wowjs/css/libs/animate.css'
import { USER_POOL_ID, CLIENT_ID } from '@/config'

Auth.configure({
  userPoolId: USER_POOL_ID,
  userPoolWebClientId: CLIENT_ID,
})

const app = createApp(App)
installElementPlus(app)
app.use(router).mount('#app')
