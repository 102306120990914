<template>
  <label class="image-uploader" :class="uploaderClass(state.previewImageUrl || state.imgUrl)">
    <img :src="state.previewImageUrl || state.imgUrl" alt="">
    <El-Icon class="uploader-icon"><Plus /></El-Icon>
    <input type="file" hidden @change="handleFileChange">
  </label>
  <!-- <p>Debug:{{modelValue}}</p> -->
</template>

<script lang="ts">
import { defineComponent, reactive } from 'vue'
import { Plus } from '@element-plus/icons'
import api from '@/api'

export default defineComponent({
  name: 'ImageUploader',
  props: ['modelValue'],
  emits: [
    'update:modelValue',
    'upload'
  ],
  components: { Plus },
  setup(props, context) {
    const state = reactive({
      previewImageUrl: '',
      imgUrl: props.modelValue,
    })

    const uploaderClass = (imageUrl: string) => {
      return imageUrl ? { 'has-image': true } : null
    }

    const handleFileChange = (event: Event) => {
      const target = event.target as HTMLInputElement
      if (target.files?.length) {
        const [file] = target.files

        state.previewImageUrl = URL.createObjectURL(file)

        context.emit('upload', api.uploadFile(file).then(url => {
          console.log('good', url)
          context.emit('update:modelValue', url)
          state.previewImageUrl = url
        }))
      }
    }

    return {
      state,
      uploaderClass,
      handleFileChange,
    }
  },
})
</script>

<style lang="scss">
  .image-uploader {
    display: block;
    width: 178px;
    height: 178px;
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      position: absolute;
      left: 0;
      top: 0;
    }

    .uploader-icon {
      display: flex;
      width: 100%;
      height: 100%;
      justify-content: center;
      align-items: center;
      position: absolute;
      left: 0;
      top: 0;
    }

    &:not(.has-image) {
      img {
        display: none;
      }
    }

    &.has-image {
      &:hover img {
        opacity: 0.3;
      }

      &:not(:hover) {
        border: none;

        .uploader-icon {
          display: none;
        }
      }
    }
  }
</style>
