<template>
  <div class="usr-title-1">
    <div class="decoration"></div>
    <h1>{{title}}</h1>
    <h2>{{subtitle}}</h2>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Title1',
  props: {
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      required: true,
    }
  },
  setup() {
  },
})
</script>

<style lang="scss">
.usr-title-1 {
  position: relative;
  display: flex;
  align-items: center;

  .decoration {
    height: 26px;
    display: flex;
    align-items: center;
    position: absolute;
    right: calc(100% + 12px);

    &:before {
      content: '';
      width: 149px;
      height: 100%;
      display: block;
      background: transparent linear-gradient(90deg, #AFD49F99 0%, #3EAC4A 100%) 0% 0% no-repeat padding-box;
    }

    &:after {
      content: '';
      margin-left: 4px;
      display: block;
      width: 6px;
      height: 100%;
      background-color: #44A650;
    }
  }

  h1 {
    font: normal normal bold 33px/40px Arial;
    letter-spacing: 1.66px;
    color: #277B32;

    &::after {
      content: '/';
      margin-left: 9px;
      margin-right: 9px;
    }
  }

  h2 {
    font: italic normal normal 18px/22px Arial;
    letter-spacing: 0.9px;
    color: #277B32;
  }
}

@media screen and (max-width: 991px) {
  .usr-title-1 {
    .decoration {
      height: 16px;
      right: calc(100% + 6px);

      &:before {
        content: '';
        width: 20px;
      }

      &:after {
        content: '';
        margin-left: 2px;
        width: 4px;
      }
    }

    h1 {
      font: normal normal bold 17px/20px Arial;
      letter-spacing: 0.85px;

      &::after {
        margin-left: 4px;
      }
    }

    h2 {
      margin-left: -5px;
      font-size: 9.46px;
      line-height: 11.35px;
      letter-spacing: 0.47px;
    }
  }
}
</style>
