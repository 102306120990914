<template>
  <div class="content">
    <h1 :style="`font-size: var(--el-font-size-extra-large)`">{{isNew ? '建立近期活動' : '編輯近期活動'}}</h1>
    <!-- Debug: {{state.editingData}} -->
    <El-Form ref="form" :model="state.editingData" label-width="120px"
      v-loading="state.editing"
    >
      <El-Form-Item label="Id" v-if="state.editingData.id">
        <El-Input v-model="state.editingData.id" disabled></El-Input>
      </El-Form-Item>
      <El-Form-Item label="時間">
        <El-Date-Picker v-model="state.editingData.timestamp" type="datetime" placeholder="Pick date and time" :default-value="new Date()" />
      </El-Form-Item>
      <El-Form-Item label="Banner(選用)">
        <ImageUploader v-model="state.editingData.imgUrl" @upload="handleUpload" />
      </El-Form-Item>
      <El-Form-Item label="標題">
        <El-Input v-model="state.editingData.title"></El-Input>
      </El-Form-Item>
      <El-Form-Item label="地點">
        <El-Input v-model="state.editingData.local"></El-Input>
      </El-Form-Item>
      <El-Form-Item label="時間區段">
        <El-Input v-model="state.editingData.time"></El-Input>
      </El-Form-Item>

      <El-Form-Item label="活動DM">
        <El-Card class="content">
          <El-Space wrap>
            <El-Card class="box-card" v-for="(dm, index) in state.editingData.dmList" :key="index">
              <El-Row justify="end" style="font-size: var(--el-font-size-medium); margin-bottom: 10px">
                <El-Button
                  circle
                  @click="removeFromArray(state.editingData.dmList, dm)"
                ><El-Icon><Close /></El-Icon></El-Button>
              </El-Row>
              <El-Form ref="form" :model="dm" label-width="80px">
                <El-Form-Item label="DM">
                  <ImageUploader v-model="dm.imgSrc" @upload="handleUpload" />
                </El-Form-Item>
              </El-Form>
            </El-Card>
            <El-Button
              circle
              @click="addToArray(state.editingData.dmList, dmTemplate, state.editingData.dmList.length)" v-if="state.editingData.dmList.length < 2"
            ><El-Icon><Plus /></El-Icon></El-Button>
          </El-Space>
        </El-Card>
      </El-Form-Item>
      <El-Form-Item label="自訂內容">
        <USRComponentListEditor :components="state.editingData.components" />
      </El-Form-Item>
    </El-Form>

    <El-Row justify="center">
      <El-Button-Group>
        <El-Button type="info" @click="cancel">Cancel</El-Button>
        <El-Button type="primary" @click="save">{{isNew ? 'Add' : 'Edit'}}</El-Button>
        <El-Button type="warning" @click="preview">Preview</El-Button>
      </El-Button-Group>
    </El-Row>
  </div>
</template>

<script lang="ts">
import USRComponentListEditor from '@/components/USRComponentListEditor.vue'
import ManagementLayout from '@/layout/ManagementLayout.vue'
import { Activity, usrState, usrPreviewState, updateResource } from '@/model'
import { addToArray, removeFromArray } from '@/utils'
import { Close, Plus } from '@element-plus/icons'
import { defineComponent, reactive, watch } from 'vue'
import { useRoute } from 'vue-router'
import { ElLoading } from 'element-plus'
import api from '@/api'
import ImageUploader from '@/components/ImageUploader.vue'

interface State {
  editing: boolean;
  editingData: Activity;
  previewImageUrl: string;
}

export default defineComponent({
  name: 'ManageActivityPage',
  components: {
    Close,
    Plus,
    USRComponentListEditor,
    ImageUploader,
  },
  created() {
    this.$emit('update:layout', ManagementLayout)
  },
  setup() {
    const route = useRoute()
    const pId = route.params.id as string
    const isNew = pId === 'new'

    const dmTemplate = { imgSrc: '' }

    const editingData = getEditingData()

    function getEditingData(): Activity {
      return (isNew ? {
        timestamp: new Date(),
        imgUrl: '',
        title: '',
        local: '',
        time: '',
        dmList: [],
        components: [],
      } : usrState.activities.find(activity => activity.id === parseInt(pId, 10))) as Activity
    }

    const state = reactive({
      editing: false,
      editingData,
      previewImageUrl: '',
    }) as State

    watch(() => usrState.activities, () => {
      console.log('updated!')
      state.editingData = getEditingData()
    })

    const cancel = () => {
      state.editingData = getEditingData()
    }

    const save = () => {
      const loading = ElLoading.service({
        lock: true,
        text: 'Loading',
        background: 'rgba(0, 0, 0, 0.7)',
      })

      api.updateActivity(state.editingData)
        .then(() => updateResource())
        .then(() => loading.close())
        .catch(() => loading.close())
    }

    const preview = () => {
      usrPreviewState.activity = JSON.parse(JSON.stringify(state.editingData))
      localStorage.setItem('resourcesPreview', JSON.stringify(usrPreviewState))
      window.open('/activities/Preview', '_blank')
    }

    const handleUpload = (promise: Promise<void>) => {
      const loading = ElLoading.service({
        lock: true,
        text: 'Loading',
        background: 'rgba(0, 0, 0, 0.7)',
      })
      promise
        .then(() => loading.close())
        .catch(() => loading.close())
    }

    return {
      isNew,
      dmTemplate,
      state,
      addToArray,
      removeFromArray,
      cancel,
      save,
      preview,
      handleUpload,
    }
  },
})
</script>
<style lang="scss">
  .mt-10 {
    margin-top: 10px;
  }

  .el-card + * {
    margin-top: 10px
  }

  .el-form-item + * {
    margin-top: 10px;
  }
</style>
