
import { computed, defineComponent } from 'vue'

export default defineComponent({
  name: 'Text1',
  props: {
    text: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const multilineText = computed(() => {
      return props.text.replace(/\n/g, '<br>')
    })

    return {
      multilineText,
    }
  },
})
