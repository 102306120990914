<template>
  <div class="content">
    <h1 :style="`font-size: var(--el-font-size-extra-large)`">近期活動清單管理</h1>
    <El-Table
      :data="state.activities"
      style="width: 100%"
      :default-sort = "{prop: 'time', order: 'descending'}"
      v-loading="state.loading"
    >
      <El-Table-Column prop="id" label="ID" width="60" />
      <El-Table-Column prop="timestamp" label="時間" width="120">
        <template #default="scope">
          <p>{{formatTime(scope.row.timestamp, 'YYYY/MM/DD')}}</p>
          <p>{{formatTime(scope.row.timestamp, 'HH:mm:ss')}}</p>
        </template>
      </El-Table-Column>
      <El-Table-Column prop="imageUrl" label="Banner" width="120">
        <template #default="scope">
          <El-Image
            style="width: 100px; height: 100px"
            :src="scope.row.imgUrl"
            fit="cover"
          ></El-Image>
        </template>
      </El-Table-Column>
      <El-Table-Column prop="title" label="標題" />
      <El-Table-Column prop="time" label="時間區段" width="220" sortable />
      <El-Table-Column fixed="right" label="Operations" width="120" align="right">
        <template #header>
          <El-Link type="primary" href="/admin/activities/new">New</El-Link>
        </template>
        <template #default="scope">
          <El-Button type="text" size="small" @click="handleEdit(scope.$index, scope.row)">Edit</El-Button>
          <El-Button type="text" size="small" @click="handleDelete(scope.$index, scope.row)">Delete</El-Button>
        </template>
      </El-Table-Column>
    </El-Table>
  </div>
</template>

<script lang="ts">
import { defineComponent, getCurrentInstance, reactive } from 'vue'
import ManagementLayout from '@/layout/ManagementLayout.vue'
import { useRouter } from 'vue-router'
import { usrState, Activity, updateResource } from '@/model'
import { formatTime } from '@/utils'
import { ElLoading } from 'element-plus'
import api from '@/api'

export default defineComponent({
  name: 'ManageActivityListPage',
  components: {
  },
  created() {
    this.$emit('update:layout', ManagementLayout)
  },
  setup() {
    const proxy = getCurrentInstance()?.proxy
    const router = useRouter()

    const state = reactive({
      activities: usrState.activities,
      loading: false,
    })

    const handleEdit = (index: number, item: Activity) => {
      router.push('/admin/activities/' + item.id)
    }

    const handleDelete = (index: number, activity: Activity) => {
      console.log('handleDeleteActivity', { index, activity })
      proxy?.$confirm(`確認刪除「${activity.title}(${activity.id})」？`)
        .then(async () => {
          const loading = ElLoading.service({
            lock: true,
            text: 'Loading',
            background: 'rgba(0, 0, 0, 0.7)',
          })

          api.deleteActivity(activity.id)
            .then(() => updateResource())
            .then(() => loading.close())
            .catch(() => loading.close())
        })
        .catch(() => { /* prevent exception */ })
    }

    return {
      state,
      handleEdit,
      handleDelete,
      formatTime,
    }
  },
})
</script>
