
import { defineComponent, reactive } from 'vue'
import { Auth } from 'aws-amplify'
import { ElMessage, ElLoading } from 'element-plus'
import { Back } from '@element-plus/icons'
import router from '@/router'

export default defineComponent({
  name: 'ForgetPasswordPage',
  components: {
    Back,
  },
  created() {
    this.$emit('update:layout', '')
  },
  setup() {
    const state = reactive({
      username: '',
    })

    const send = () => {
      const loading = ElLoading.service({
        lock: true,
        text: 'Loading',
        background: 'rgba(0, 0, 0, 0.7)',
      })

      Auth.forgotPassword(state.username)
        .then(() => {
          loading.close()
          ElMessage.success('重設密碼信已寄出，請去信箱收信')
          router.push('/admin/login')
        })
        .catch(error => {
          loading.close()
          ElMessage.error('發生錯誤' + JSON.stringify(error))
        })
    }

    return {
      state,
      send,
    }
  },
})
