<template>
  <ul class="usr-list-2">
    <li
      v-for="(item, index) in list"
      :key="index"
      class="wow fadeInUp"
      data-wow-duration="2s"
    >
      <img :src="item.imgSrc" alt="" />
      <h1>{{item.title}}</h1>
      <p>{{item.text}}</p>
    </li>
  </ul>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import { List2Item } from '@/model'

export default defineComponent({
  name: 'List2',
  props: {
    list: {
      type: Array as PropType<Array<List2Item>>,
      required: true,
    },
  },
})
</script>

<style lang="scss">
.usr-list-2 {
  li {
    width: 100%;
    display: grid;
    grid-template-columns: 207px 1fr;
    grid-template-rows: 56px 1fr;

    * {
      margin: 0;
      padding: 0;
    }

    img {
      width: 207px;
      height: 207px;
      object-fit: cover;
      object-position: center;
      grid-row: 1 / 3;
    }

    h1 {
      padding-left: 20px;
      display: grid;
      align-items: center;
      font: normal normal bold 26px/31px Arial;
      letter-spacing: 1.3px;
      color: #277b32;
      background: #e0e0df;
    }

    p {
      background: #f1f1f1;
      padding: 18.5px 20px;
      font: normal normal normal 20px/30px Arial;
      letter-spacing: 0px;
      text-align: justify;
      color: #141414;
    }
  }

  li + li {
    margin-top: 42px;
  }
}

@media screen and (max-width: 991px) {
  .usr-list-2 {
    li {
      margin-bottom: 22px;
      grid-template-columns: 77px 1fr;
      grid-template-rows: 77px 1fr;

      h1 {
        padding-left: 17px;
        font: normal normal bold 14px/17px Arial;
        letter-spacing: 0.7px;
      }

      img {
        width: 77px;
        height: 77px;
        object-fit: cover;
        object-position: center;
        grid-row: auto;
      }

      p {
        height: auto;
        grid-column: 1 / 3;
        padding: 18.5px 20px;
        font: normal normal normal 11px/16px Arial;
        letter-spacing: 0px;
      }
    }

    li + li {
      margin-top: 24px;
    }
  }
}
</style>
