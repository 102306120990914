<template>
  <div class="content">
    <h1 :style="`font-size: var(--el-font-size-extra-large)`">電子報清單管理</h1>
    <El-Table
      :data="state.news"
      style="width: 100%"
      :default-sort = "{prop: 'time', order: 'descending'}"
      v-loading="state.loading"
    >
      <El-Table-Column prop="id" label="Id" width="60" />
      <El-Table-Column prop="time" label="時間" width="120" sortable>
        <template #default="scope">
          <p>{{formatTime(scope.row.time, 'YYYY/MM/DD')}}</p>
          <p>{{formatTime(scope.row.time, 'HH:mm:SS')}}</p>
        </template>
      </El-Table-Column>
      <El-Table-Column prop="title" label="中文標題" />
      <El-Table-Column prop="titleEn" label="英文標題" />
      <El-Table-Column fixed="right" label="" width="120" align="right">
        <template #header>
          <El-Link v-if="state.isAdmin" type="primary" href="/admin/news/new">New</El-Link>
        </template>
        <template #default="scope">
          <El-Button type="text" size="small" @click="handleEdit(scope.$index, scope.row)">Edit</El-Button>
          <El-Button type="text" size="small" @click="handleDelete(scope.$index, scope.row)" v-if="state.isAdmin">Delete</El-Button>
        </template>
      </El-Table-Column>
    </El-Table>
  </div>
</template>

<script lang="ts">
import { defineComponent, getCurrentInstance, reactive } from 'vue'
import ManagementLayout from '@/layout/ManagementLayout.vue'
import { useRouter } from 'vue-router'
import { usrState, News, updateResource } from '@/model'
import { formatTime, isAuthUserAdmin } from '@/utils'
import { ElLoading } from 'element-plus'
import api from '@/api'

export default defineComponent({
  name: 'ManageNewsListPage',
  components: {
  },
  created() {
    this.$emit('update:layout', ManagementLayout)
  },
  setup() {
    const proxy = getCurrentInstance()?.proxy
    const router = useRouter()

    const state = reactive({
      isAdmin: false,
      news: usrState.news,
      loading: false,
    })

    isAuthUserAdmin().then(isAdmin => {
      state.isAdmin = isAdmin
    })

    const handleEdit = (index: number, item: News) => {
      router.push('/admin/news/' + item.id)
    }

    const handleDelete = (index: number, news: News) => {
      console.log('handleDeleteNews', { index, news })
      proxy?.$confirm(`確認刪除「${news.title}(${news.id})」？`)
        .then(async () => {
          const loading = ElLoading.service({
            lock: true,
            text: 'Loading',
            background: 'rgba(0, 0, 0, 0.7)',
          })

          api.deleteNews(news.id)
            .then(() => updateResource())
            .then(() => loading.close())
            .catch(() => loading.close())
        })
        .catch(() => { /* prevent exception */ })
    }

    return {
      state,
      handleEdit,
      handleDelete,
      formatTime,
    }
  },
})
</script>
