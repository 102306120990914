
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'CaptionedImage',
  props: {
    url: {
      type: String,
      required: true,
    },
    text: {
      type: String,
    }
  },
  setup() {
  },
})
