
import { computed, defineComponent } from 'vue'
import { Carousel, Slide, Pagination } from 'vue3-carousel'
import MainLayout from '@/layout/MainLayout.vue'
import Banner from '@/components/Banner.vue'
import Breadcrumb from '@/components/Breadcrumb.vue'
import Title1 from '@/components/Title1.vue'
import moment from 'moment'
import { useUsrHelper, usrState } from '@/model'
import utils from '@/utils'

export default defineComponent({
  name: 'History',
  components: {
    Carousel,
    Slide,
    Banner,
    Pagination,
    Breadcrumb,
    Title1,
  },
  created() {
    this.$emit('update:layout', MainLayout)
  },
  setup () {
    const helper = useUsrHelper()
    const reportList = computed(() => utils.splitArrayList(helper.history.value.reports, 5))
    const highlightList = computed(() => utils.splitArrayList(usrState.highlights, 5))

    const carouselSetting = {
      itemsToShow: 1,
      snapAlign: 'start'
    }

    const title = (title: string) => `【${title}】`

    return {
      history: helper.history,
      reportList,
      highlightList,
      carouselSetting,
      moment,
      title,
    }
  }
})
