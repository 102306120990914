
import { defineComponent, reactive, watch } from 'vue'
import ManagementLayout from '@/layout/ManagementLayout.vue'
import { Highlight, usrState, usrPreviewState, updateResource } from '@/model'
import { useRoute } from 'vue-router'
import USRComponentListEditor from '@/components/USRComponentListEditor.vue'
import { addToArray, removeFromArray } from '@/utils'
import { ElLoading } from 'element-plus'
import api from '@/api'
import ImageUploader from '@/components/ImageUploader.vue'

interface State {
  editing: boolean;
  editingData: Highlight;
}

export default defineComponent({
  name: 'ManageHighlightPage',
  components: {
    USRComponentListEditor,
    ImageUploader,
  },
  created() {
    this.$emit('update:layout', ManagementLayout)
  },
  setup() {
    const route = useRoute()
    const pId = route.params.id as string
    const isNew = pId === 'new'

    const editingData = getEditingData()

    function getEditingData(): Highlight {
      return (isNew ? {
        time: new Date(),
        imgSrc: '',
        title: '',
        text: '',
        components: [],
      } : usrState.highlights.find(highlight => highlight.id === parseInt(pId, 10))) as Highlight
    }

    const state = reactive({
      editing: false,
      editingData,
    }) as State

    watch(() => usrState.activities, () => {
      console.log('updated!')
      state.editingData = getEditingData()
    })

    const cancel = () => {
      state.editingData = getEditingData()
    }

    const save = () => {
      const loading = ElLoading.service({
        lock: true,
        text: 'Loading',
        background: 'rgba(0, 0, 0, 0.7)',
      })

      api.updateHighlight(state.editingData)
        .then(() => updateResource())
        .then(() => loading.close())
        .catch(() => loading.close())
    }

    const preview = () => {
      usrPreviewState.highlight = JSON.parse(JSON.stringify(state.editingData))
      localStorage.setItem('resourcesPreview', JSON.stringify(usrPreviewState))
      window.open('/highlights/Preview', '_blank')
    }

    const handleUpload = (promise: Promise<void>) => {
      const loading = ElLoading.service({
        lock: true,
        text: 'Loading',
        background: 'rgba(0, 0, 0, 0.7)',
      })
      promise
        .then(() => loading.close())
        .catch(() => loading.close())
    }

    return {
      isNew,
      state,
      addToArray,
      removeFromArray,
      cancel,
      save,
      preview,
      handleUpload,
    }
  },
})
