<template>
  <div class="usr-carousel-1">
    <Carousel
      :settings="settings"
      :breakpoints="breakpoints"
      :wrapAround="true"
    >
      <Slide v-for="(item, i) in slides" :key="i">
        <div class="usr-carousel-container">
          <img :src="item.imgUrl" alt="" />
          <div class="usr-carousel-overlay">
            <p class="text7">{{ item.title }}</p>
            <p class="text6">{{ item.content }}</p>
          </div>
        </div>
      </Slide>
      <template #addons>
        <Navigation v-if="state.showNativation" />
      </template>
    </Carousel>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, reactive, onMounted, onUnmounted } from 'vue'
import { Carousel, Navigation, Slide } from 'vue3-carousel'
import { Carousel1Slide } from '@/model'

export default defineComponent({
  name: 'Carousel1',
  props: {
    slides: {
      type: Array as PropType<Array<Carousel1Slide>>,
      required: true,
    },
  },
  components: {
    Carousel,
    Slide,
    Navigation,
  },
  setup(props) {
    const settings = {
      itemsToShow: 1,
      snapAlign: 'start',
    }
    const breakpoints = {
      992: {
        itemsToShow: 2,
        snapAlign: 'start',
      },
    }
    const state = reactive({
      showNativation: true,
    })

    const handleResize = () => {
      if (window.innerWidth >= 992) {
        state.showNativation = props.slides.length > 2
      } else {
        state.showNativation = props.slides.length > 1
      }
    }

    onMounted(() => {
      window.addEventListener('resize', handleResize)
      handleResize()
    })

    onUnmounted(() => {
      window.removeEventListener('resize', handleResize)
    })

    return {
      settings,
      breakpoints,
      state
    }
  },
})
</script>

<style lang="scss">
.usr-carousel-1 {
  word-wrap: break-word;
  word-break: break-all;
  position: relative;
  width: 100%;
  margin-left: -24px;

  .carousel {
    width: calc(100% + 54px);

    .carousel__slide {
      padding: 0 27px;
    }

    .carousel__prev {
      left: auto;
      right: calc(100% - 20px);
    }

    .carousel__next {
      right: auto;
      left: calc(100% - 20px);
    }

    .carousel__prev,
    .carousel__next {
      box-sizing: content-box;
      background-color: #dcdad4;
      color: #9e9e9f;
      width: 45px;
      height: 45px;

      .carousel__icon {
        width: 40px;
        height: 40px;
      }
    }

    .usr-carousel-container {
      width: 100%;
      height: 389px;
      position: relative;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }

      .usr-carousel-overlay {
        display: none;
        position: absolute;
        padding: 0 44px;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        height: 100%;
        width: 100%;
        opacity: 0.9;
        background-color: #e0e0df;

        .text7 {
          margin: 20% 0 4% 0;
          font: normal normal bold 30px/40px Arial;
          letter-spacing: 0.3px;
          color: #277b32;
          text-align: justify;
        }

        .text6 {
          font: normal normal normal 21px/36px Arial;
          letter-spacing: 0px;
          text-align: justify;
        }
      }
    }

    .usr-carousel-container:hover .usr-carousel-overlay {
      display: block;
    }
  }
}

@media screen and (max-width: 991px) {
  .usr-carousel-1 {
    margin-left: 0;

    .carousel {
      width: 100%;

      .carousel__slide {
        padding: 0;
      }

      .usr-carousel-container {
        width: 93%;
        height: 197px;

        .usr-carousel-overlay {
          padding: 0 22px;

          .text7 {
            font: normal normal bold 15px/20px Arial;
            letter-spacing: 0.15px;
          }

          .text6 {
            font: normal normal normal 12px/18px Arial;
            letter-spacing: 0px;
          }
        }
      }
      .carousel__prev,
      .carousel__next {
        width: 30px;
        height: 30px;

        .carousel__icon {
          width: 28px;
          height: 28px;
        }
      }
      .carousel__prev {
        left: auto;
        right: calc(100% - 20px);
      }

      .carousel__next {
        left: calc(100% - 20px);
        right: auto;
      }
    }
  }
}
</style>
