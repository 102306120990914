<template>
  <p class="usr-text" v-html="multilineText"></p>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue'

export default defineComponent({
  name: 'Text1',
  props: {
    text: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const multilineText = computed(() => {
      return props.text.replace(/\n/g, '<br>')
    })

    return {
      multilineText,
    }
  },
})
</script>

<style lang="scss">
.usr-text {
  font: normal normal normal 21px/36px Arial;
  letter-spacing: 0.21px;
  color: #141414;
  text-align: justify;

  sup {
    vertical-align: super;
    font-weight: inherit;
  }
}

@media screen and (max-width: 991px) {
  .usr-text {
    font-size: 12px;
    letter-spacing: 0;
    line-height: 20.94px;
  }
}
</style>
