<template>
  <div class="usr-column-2">
    <component v-for="(component, index) in components" :is="component.type" v-bind="component.data" :key="index" />
    <slot />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import Text1 from '@/components/Text1.vue'
import CaptionedImage from '@/components/CaptionedImage.vue'

export default defineComponent({
  name: 'Row',
  props: {
    components: {
      type: Array,
      required: true,
    }
  },
  components: {
    Text1,
    CaptionedImage,
  },
  setup() {
  },
})
</script>

<style lang="scss">
.usr-column-2 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 55px;

  > * + * {
    margin-top: 0 !important;
  }
}

@media screen and (max-width: 991px) {
  .usr-column-2 {
    grid-template-columns: 1fr;
    grid-column-gap: 0;

    > * + * {
      margin-top: 30px !important;
    }
  }
}
</style>
