
import { defineComponent, getCurrentInstance, reactive } from 'vue'
import ManagementLayout from '@/layout/ManagementLayout.vue'
import { useRouter } from 'vue-router'
import { usrState, Activity, updateResource } from '@/model'
import { formatTime } from '@/utils'
import { ElLoading } from 'element-plus'
import api from '@/api'

export default defineComponent({
  name: 'ManageActivityListPage',
  components: {
  },
  created() {
    this.$emit('update:layout', ManagementLayout)
  },
  setup() {
    const proxy = getCurrentInstance()?.proxy
    const router = useRouter()

    const state = reactive({
      activities: usrState.activities,
      loading: false,
    })

    const handleEdit = (index: number, item: Activity) => {
      router.push('/admin/activities/' + item.id)
    }

    const handleDelete = (index: number, activity: Activity) => {
      console.log('handleDeleteActivity', { index, activity })
      proxy?.$confirm(`確認刪除「${activity.title}(${activity.id})」？`)
        .then(async () => {
          const loading = ElLoading.service({
            lock: true,
            text: 'Loading',
            background: 'rgba(0, 0, 0, 0.7)',
          })

          api.deleteActivity(activity.id)
            .then(() => updateResource())
            .then(() => loading.close())
            .catch(() => loading.close())
        })
        .catch(() => { /* prevent exception */ })
    }

    return {
      state,
      handleEdit,
      handleDelete,
      formatTime,
    }
  },
})
