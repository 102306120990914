
import { defineComponent, reactive } from 'vue'
import { Plus } from '@element-plus/icons'
import api from '@/api'

export default defineComponent({
  name: 'ImageUploader',
  props: ['modelValue'],
  emits: [
    'update:modelValue',
    'upload'
  ],
  components: { Plus },
  setup(props, context) {
    const state = reactive({
      previewImageUrl: '',
      imgUrl: props.modelValue,
    })

    const uploaderClass = (imageUrl: string) => {
      return imageUrl ? { 'has-image': true } : null
    }

    const handleFileChange = (event: Event) => {
      const target = event.target as HTMLInputElement
      if (target.files?.length) {
        const [file] = target.files

        state.previewImageUrl = URL.createObjectURL(file)

        context.emit('upload', api.uploadFile(file).then(url => {
          console.log('good', url)
          context.emit('update:modelValue', url)
          state.previewImageUrl = url
        }))
      }
    }

    return {
      state,
      uploaderClass,
      handleFileChange,
    }
  },
})
