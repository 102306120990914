import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import { App } from 'vue'

export default (app: App<Element>): void => {
  app.use(ElementPlus)
}

type $confirm = (message: string) => Promise<void>

declare module '@vue/runtime-core' {
  export interface ComponentInternalInstance { // getCurrentInstance
    ctx: {
      $confirm: $confirm;
    }
  }

  interface ComponentCustomProperties { // this
    $confirm: $confirm;
  }
}
