import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Home from '../views/Home.vue'
import About from '../views/About.vue'
import News from '../views/News.vue'
import Activity from '../views/Activity.vue'
import Projects from '../views/Projects.vue'
import Project from '../views/Project.vue'
import History from '../views/History.vue'
import Highlight from '../views/Highlight.vue'
import LoginPage from '../views/admin/LoginPage.vue'
import ForgetPasswordPage from '../views/admin/ForgetPasswordPage.vue'
import ResetPasswordPage from '../views/admin/ResetPasswordPage.vue'
import ManageHomePage from '../views/admin/ManageHomePage.vue'
import ManageActivityListPage from '../views/admin/ManageActivityListPage.vue'
import ManageActivityPage from '../views/admin/ManageActivityPage.vue'
import ManageNewsListPage from '../views/admin/ManageNewsListPage.vue'
import ManageNewsPage from '../views/admin/ManageNewsPage.vue'
import ManageAboutPage from '../views/admin/ManageAboutPage.vue'
import ManageProjectListPage from '../views/admin/ManageProjectListPage.vue'
import ManageProjectPage from '../views/admin/ManageProjectPage.vue'
import ManageHistoryPage from '../views/admin/ManageHistoryPage.vue'
import ManageHighlightListPage from '../views/admin/ManageHighlightListPage.vue'
import ManageHighlightPage from '../views/admin/ManageHighlightPage.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/homePreview',
    name: 'HomePreview',
    component: Home,
  },
  {
    path: '/about',
    name: 'About',
    component: About
  },
  {
    path: '/aboutPreview',
    name: 'AboutPreview',
    component: About
  },
  {
    path: '/news/:id',
    name: 'News',
    component: News
  },
  {
    path: '/activities/:id',
    name: 'Activity',
    component: Activity
  },
  {
    path: '/projects',
    name: 'Projects',
    component: Projects
  },
  {
    path: '/projectsPreview',
    name: 'ProjectsPreview',
    component: Projects
  },
  {
    path: '/projects/:id',
    name: 'Project',
    component: Project
  },
  {
    path: '/history',
    name: 'History',
    component: History
  },
  {
    path: '/historyPreview',
    name: 'HistoryPreview',
    component: History
  },
  {
    path: '/highlights/:id',
    name: 'Highlight',
    component: Highlight,
  },
  {
    path: '/admin/login',
    name: 'Login',
    component: LoginPage,
  },
  {
    path: '/admin/forgetPassword',
    name: 'ForgetPassword',
    component: ForgetPasswordPage,
  },
  {
    path: '/admin/resetPassword',
    name: 'ManageResetPassword',
    component: ResetPasswordPage,
  },
  {
    path: '/admin/',
    name: 'ManageHome',
    component: ManageHomePage,
  },
  {
    path: '/admin/activities',
    name: 'ManageActivityList',
    component: ManageActivityListPage,
  },
  {
    path: '/admin/activities/:id',
    name: 'ManageActivity',
    component: ManageActivityPage,
  },
  {
    path: '/admin/news',
    name: 'ManageNewsList',
    component: ManageNewsListPage,
  },
  {
    path: '/admin/news/:id',
    name: 'ManageNews',
    component: ManageNewsPage,
  },
  {
    path: '/admin/about',
    name: 'ManageAbout',
    component: ManageAboutPage,
  },
  {
    path: '/admin/projects',
    name: 'ManageProjectList',
    component: ManageProjectListPage,
  },
  {
    path: '/admin/projects/:id',
    name: 'ManageProject',
    component: ManageProjectPage,
  },
  {
    path: '/admin/history',
    name: 'ManageHistory',
    component: ManageHistoryPage,
  },
  {
    path: '/admin/highlights',
    name: 'ManageHighlightList',
    component: ManageHighlightListPage,
  },
  {
    path: '/admin/highlights/:id',
    name: 'ManageHighlight',
    component: ManageHighlightPage,
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
