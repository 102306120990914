
import { defineComponent } from 'vue'
import MainLayout from '@/layout/MainLayout.vue'
import Banner from '@/components/Banner.vue'
import Breadcrumb from '@/components/Breadcrumb.vue'
import Title1 from '@/components/Title1.vue'
import Title2 from '@/components/Title2.vue'
import USRComponentList from '@/components/USRComponentList.vue'
import { useUsrHelper } from '@/model'

export default defineComponent({
  name: 'News',
  components: {
    Banner,
    Breadcrumb,
    Title1,
    Title2,
    USRComponentList,
  },
  created() {
    this.$emit('update:layout', MainLayout)
  },
  setup () {
    const helper = useUsrHelper()
    const news = helper.news.value

    return {
      news: helper.news,
      newsTitle: helper.newsTitle,
      newsComponents: helper.newsComponents
    }
  }
})
