<template>
  <div class="usr-title-3">{{title}}</div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Title3',
  props: {
    title: {
      type: String,
      required: true,
    },
  },
  setup() {
  },
})
</script>

<style lang="scss">
.usr-title-3 {
  font: normal normal normal 25px/30px Arial;
  letter-spacing: 1.25px;
  color: #277B32;
}

@media screen and (max-width: 991px) {
  .usr-title-3 {
    font-size: 13px;
    letter-spacing: 0;
    line-height: 15.6px;
  }

  .usr-title-2 + .usr-title-3 {
    padding-top: 17px;
  }
}
</style>
