
import { defineComponent, reactive, watch, nextTick, onMounted } from 'vue'

import '@/assets/reset.css'
import 'vue3-carousel/dist/carousel.css'
import '@/assets/main.scss'
import '@/assets/usr.scss'
import { useRoute } from 'vue-router'
import { updateResource, loadCache } from '@/model'
import { WOW } from 'wowjs'

export default defineComponent({
  created() {
    console.log('Initialize app')

    loadCache()
    updateResource()
  },
  setup () {
    const route = useRoute()
    const state = reactive({
      layout: '',
    })

    // workaround, should update resource when add, edit and delete resource.
    watch(() => route.path, () => {
      updateResource()
      nextTick(() => {
        const wow = new WOW({
          live: false,
        })
        wow.init()
      })
    })
    onMounted(() => {
      nextTick(() => {
        new WOW({
          live: false,
        }).init()
      })
    })
    return {
      state,
    }
  }
})
