
import { defineComponent, PropType, reactive, onMounted, onUnmounted } from 'vue'
import { Carousel, Navigation, Slide } from 'vue3-carousel'
import { Carousel1Slide } from '@/model'

export default defineComponent({
  name: 'Carousel1',
  props: {
    slides: {
      type: Array as PropType<Array<Carousel1Slide>>,
      required: true,
    },
  },
  components: {
    Carousel,
    Slide,
    Navigation,
  },
  setup(props) {
    const settings = {
      itemsToShow: 1,
      snapAlign: 'start',
    }
    const breakpoints = {
      992: {
        itemsToShow: 2,
        snapAlign: 'start',
      },
    }
    const state = reactive({
      showNativation: true,
    })

    const handleResize = () => {
      if (window.innerWidth >= 992) {
        state.showNativation = props.slides.length > 2
      } else {
        state.showNativation = props.slides.length > 1
      }
    }

    onMounted(() => {
      window.addEventListener('resize', handleResize)
      handleResize()
    })

    onUnmounted(() => {
      window.removeEventListener('resize', handleResize)
    })

    return {
      settings,
      breakpoints,
      state
    }
  },
})
