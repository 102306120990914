<template>
  <El-Container>
    <El-Main>
      <El-Card class="box-card">
        <h1 style="font-size: var(--el-font-size-extra-large); text-align: center">登入</h1>
        <El-Form
          :model="state"
          ref="form"
          :rules="rules"
          label-width="80px"
          style="margin-top: 20px"
        >
          <El-Form-Item label="Email">
            <El-Input v-model="state.username" autocomplete="false"></El-Input>
          </El-Form-Item>
          <El-Form-Item label="Password" prop="password">
            <El-Input type="password" v-model="state.password" autocomplete="off"></El-Input>
          </El-Form-Item>
          <El-Row justify="end">
            <router-link class="el-link el-link--primary" to="/admin/forgetPassword">忘記密碼</router-link>
          </El-Row>
          <El-Row justify="center">
            <El-Button type="text" @click="login">登入</El-Button>
          </El-Row>
        </El-Form>
      </El-Card>
    </El-Main>
  </El-Container>
</template>

<script lang="ts">
import { defineComponent, reactive, ref } from 'vue'
import { Auth } from 'aws-amplify'
import router from '@/router'
import { ElMessage } from 'element-plus'
import { isAuthUserAdmin } from '@/utils'

export default defineComponent({
  name: 'LoginPage',
  components: {},
  created() {
    this.$emit('update:layout', '')
  },
  setup() {
    const state = reactive({
      username: '',
      password: '',
      passwordError: '',
    })

    const checkPassword = (rule: any, value: string, callback: (error?: Error) => void): void => {
      if (value.length < 8) {
        callback(new Error('密碼至少8碼'))
      } else {
        callback()
      }
    }

    const form = ref<HTMLFormElement>()
    const login = () => {
      console.log(form.value)

      form.value?.validate((valid: boolean) => {
        if (valid) {
          Auth.signIn(state.username, state.password)
            .then(() => {
              isAuthUserAdmin().then(isAdmin => {
                if (isAdmin) {
                  router.push('/admin')
                } else {
                  router.push('/admin/projects')
                }
              })
            })
            .catch(error => {
              console.error(error)
              ElMessage.error('Incorrect account or password')
            })
        } else {
          return false
        }
      })
    }

    return {
      form,
      state,
      rules: {
        password: [{ validator: checkPassword, trigger: 'blur' }],
      },
      login,
    }
  },
})
</script>

<style lang="scss" scoped>

.box-card {
  width: 500px;
  max-width: 100%;
  margin: 100px auto 0;
}
</style>
