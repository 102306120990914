
import { defineComponent, reactive, ref } from 'vue'
import { Auth } from 'aws-amplify'
import router from '@/router'
import { ElMessage } from 'element-plus'
import { isAuthUserAdmin } from '@/utils'

export default defineComponent({
  name: 'LoginPage',
  components: {},
  created() {
    this.$emit('update:layout', '')
  },
  setup() {
    const state = reactive({
      username: '',
      password: '',
      passwordError: '',
    })

    const checkPassword = (rule: any, value: string, callback: (error?: Error) => void): void => {
      if (value.length < 8) {
        callback(new Error('密碼至少8碼'))
      } else {
        callback()
      }
    }

    const form = ref<HTMLFormElement>()
    const login = () => {
      console.log(form.value)

      form.value?.validate((valid: boolean) => {
        if (valid) {
          Auth.signIn(state.username, state.password)
            .then(() => {
              isAuthUserAdmin().then(isAdmin => {
                if (isAdmin) {
                  router.push('/admin')
                } else {
                  router.push('/admin/projects')
                }
              })
            })
            .catch(error => {
              console.error(error)
              ElMessage.error('Incorrect account or password')
            })
        } else {
          return false
        }
      })
    }

    return {
      form,
      state,
      rules: {
        password: [{ validator: checkPassword, trigger: 'blur' }],
      },
      login,
    }
  },
})
