<template>
  <figure class="usr-image-caption">
    <img :src="url"
      class="wow fadeInUp"
      data-wow-duration="2s"
    >
    <figcaption v-if="text">{{text}}</figcaption>
  </figure>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'CaptionedImage',
  props: {
    url: {
      type: String,
      required: true,
    },
    text: {
      type: String,
    }
  },
  setup() {
  },
})
</script>

<style lang="scss">
.usr-image-caption {
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    width: 100%;
    object-fit: cover;
    object-position: center;
  }

  figcaption {
    margin-top: 18px;
    font: normal normal normal 18px/22px Arial;
    letter-spacing: 0.9px;
    text-align: center;
    color: #262727;
  }
}

@media screen and (max-width: 991px) {
  .usr-image-caption {
    figcaption {
      margin-top: 9px;
      font-size: 11px;
      letter-spacing: 0.55px;
      line-height: 13.2px;
    }
  }
}
</style>
