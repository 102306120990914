<template>
  <div id="project">
    <div class="banner">
      <div>
        <p class="banner-text">{{state.project.title}}</p>
      </div>
      <img :src="state.project.image" alt="" class="banner-image" />
    </div>
    <div class="container content">
      <Breadcrumb text1="團隊與場域" url1="/projects" :text2="state.project.title || ''" />
      <Title1 title="團隊與場域" subtitle="PROJECTS" />
      <USRComponentList :components="state.project.components" />
      <div class="media wow fadeInUp" data-wow-duration="2s">
        <a v-if="state.project.fbUrl" :href="state.project.fbUrl" target="_blank"><img src="@/assets/images/media-fb.png" alt=""></a>
        <a v-if="state.project.ytUrl" :href="state.project.ytUrl" target="_blank"><img src="@/assets/images/media-yt.png" alt=""></a>
        <a v-if="state.project.igUrl" :href="state.project.igUrl" target="_blank"><img src="@/assets/images/media-ig.png" alt=""></a>
        <a v-if="state.project.webUrl" :href="state.project.webUrl" target="_blank"><img src="@/assets/images/media-site.png" alt=""></a>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive } from 'vue'
import MainLayout from '@/layout/MainLayout.vue'
import USRComponentList from '@/components/USRComponentList.vue'
import Breadcrumb from '@/components/Breadcrumb.vue'
import Title1 from '@/components/Title1.vue'
import { useUsrHelper } from '@/model'

export default defineComponent({
  components: {
    Breadcrumb,
    Title1,
    USRComponentList,
  },
  created() {
    this.$emit('update:layout', MainLayout)
  },
  setup() {
    const helper = useUsrHelper()

    const state = reactive({
      project: helper.project,
    })

    return {
      state,
    }
  },
})
</script>

<style lang="scss">
.banner {
  display: flex;
  width: 100%;

  div {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 83px;
    width: 44.5%;
    background: #85c293;

    p {
      text-align: left;
      font: normal normal bold 45px/60px Arial;
      letter-spacing: 2.25px;
      color: #ffffff;
      text-shadow: 3px 3px 2px #1a131199;
    }
  }

  img {
    width: 55.5%;
    max-height: 500px;
    object-fit: cover;
    object-position: center;
  }
}

.media {
  a {
    img {
      width: 57px;
    }
  }
  a + a {
    margin-left: 20px;
  }
}

@media screen and (max-width: 991px) {
  .banner {
    flex-wrap: wrap;

    div {
      width: 100%;
      height: 250px;
    }

    img {
      width: calc(100% - 66px);
      height: 130px;
      margin: 33px auto 0 auto;
    }

    .banner-text {
      font: normal normal bold 26px/35px Arial;
      letter-spacing: 1.32px;
    }
  }
  .media {
    a {
      img {
        width: 23px;
      }
    }
    a + a {
      margin-left: 7px;
    }
  }

}

#project {
  .usr-list-2 + .usr-image-caption {
    margin-top: 119px;
  }

  @media screen and (max-width: 991px) {
    .usr-list-2 + .usr-image-caption {
      margin-top: 42px;
    }
  }
}
</style>
