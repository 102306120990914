
import { defineComponent, reactive } from 'vue'
import MainLayout from '@/layout/MainLayout.vue'
import Banner from '@/components/Banner.vue'
import Breadcrumb from '@/components/Breadcrumb.vue'
import Title1 from '@/components/Title1.vue'
import Text1 from '@/components/Text1.vue'
import CaptionedImage from '@/components/CaptionedImage.vue'
import Title2 from '@/components/Title2.vue'
import Table1 from '@/components/Table1.vue'
import Title4 from '@/components/Title4.vue'
import { Carousel, Navigation, Slide } from 'vue3-carousel'
import { useUsrHelper } from '@/model'

export default defineComponent({
  name: 'About',
  components: {
    Banner,
    Breadcrumb,
    Carousel,
    Slide,
    Title1,
    Text1,
    CaptionedImage,
    Title2,
    Table1,
    Title4,
    Navigation,
  },
  created() {
    this.$emit('update:layout', MainLayout)
  },
  setup () {
    const helper = useUsrHelper()

    const settings = {
      itemsToShow: 1,
      snapAlign: 'left',
    }
    const breakpoints = {
      992: {
        itemsToShow: 3,
        snapAlign: 'left',
      },
    }

    const state = reactive({
      about: helper.about
    })

    function setWrapAround(data: any) {
      if (data.length >= 4) {
        return true
      }
      if (data.length < 4) {
        return false
      }
    }

    return {
      state,
      settings,
      breakpoints,
      setWrapAround,
      slideStyle: (url: string) => `background-image: url('${url}')`,
    }
  }
})
