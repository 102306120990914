<template>
  <div id="history">
    <Banner :url="history.imgUrl" />
    <div class="container content">
      <Breadcrumb text1="實踐紀錄" url1="/history" text2="媒體報導" />
      <Title1 id="report" title="媒體報導" subtitle="MEDIA REPORTS" />
      <Carousel :settings="carouselSetting">
        <Slide v-for="(reports, index) in reportList" :key="index">
          <ul class="media-table">
            <li v-for="(report, index) in reports" :key="index">
              <a :href="report.url" target="_blank">
                <img src="@/assets/images/star.png" alt="">
                <div>
                  <span>{{moment(report.time).format('YYYY-MM-DD')}}</span>
                  <span>【{{report.type}}】</span>
                  <p>{{report.title}}</p>
                </div>
              </a>
            </li>
          </ul>
        </Slide>
        <template #addons>
          <Pagination />
        </template>
      </Carousel>

      <img class="illustration wow fadeInUp" data-wow-duration="2s" src="@/assets/images/history-illustration.png" alt="">
      <Title1 title="亮點成果" subtitle="PROJECT HIGHLIGHTS" id="highlight" />

      <Carousel :settings="carouselSetting">
        <Slide v-for="(highlights, index) in highlightList" :key="index">
          <ul class="highlight">
            <li v-for="highlight in highlights" :key="highlight.id">
              <a :href="'/highlights/' + highlight.id">
                <img :src="highlight.imgSrc" class="wow fadeInUp" data-wow-duration="2s" alt="">
                <div>
                  <h1 v-html="title(highlight.title)"></h1>
                  <p>{{highlight.text}}</p>
                </div>
              </a>
            </li>
          </ul>
        </Slide>
        <template #addons>
          <Pagination />
        </template>
      </Carousel>

      <Title1 id="files" title="檔案下載" subtitle="FILE DOWNLOAD" />
      <table>
        <thead>
          <tr>
            <th>/&nbsp;編號&nbsp;/</th>
            <th>/&nbsp;檔案名稱&nbsp;/</th>
            <th>/&nbsp;類型&nbsp;/</th>
            <th>/&nbsp;更新日期&nbsp;/</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(file, index) in history.files" :key="index">
            <td>{{index + 1}}</td>
            <td>{{file.title}}</td>
            <td><a :href="file.url" download><img src="@/assets/images/pdf-download.png" alt=""></a></td>
            <td>{{moment(file.time).format('YYYY-MM-DD')}}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue'
import { Carousel, Slide, Pagination } from 'vue3-carousel'
import MainLayout from '@/layout/MainLayout.vue'
import Banner from '@/components/Banner.vue'
import Breadcrumb from '@/components/Breadcrumb.vue'
import Title1 from '@/components/Title1.vue'
import moment from 'moment'
import { useUsrHelper, usrState } from '@/model'
import utils from '@/utils'

export default defineComponent({
  name: 'History',
  components: {
    Carousel,
    Slide,
    Banner,
    Pagination,
    Breadcrumb,
    Title1,
  },
  created() {
    this.$emit('update:layout', MainLayout)
  },
  setup () {
    const helper = useUsrHelper()
    const reportList = computed(() => utils.splitArrayList(helper.history.value.reports, 5))
    const highlightList = computed(() => utils.splitArrayList(usrState.highlights, 5))

    const carouselSetting = {
      itemsToShow: 1,
      snapAlign: 'start'
    }

    const title = (title: string) => `【${title}】`

    return {
      history: helper.history,
      reportList,
      highlightList,
      carouselSetting,
      moment,
      title,
    }
  }
})
</script>

<style lang="scss">
#history {
  .media-table {
    align-self: flex-start;
    width: 100%;
    margin-bottom: 30px;

    li {
      padding: 7px 30px;
      display: flex;

      a {
        display: flex;
        align-items: center;

        img {
          flex-shrink: 0;
        }

        div {
          margin-left: 11px;
          flex: 1;
          display: flex;
          align-items: center;

          span:nth-of-type(1) {
            flex-shrink: 0;
            font-size: 19px;
            font-family: Arial;
            letter-spacing: 0.94px;
          }

          span:nth-of-type(2) {
            flex-shrink: 0;
            margin-left: 23px;
            font-size: 19px;
            font-family: Arial;
            letter-spacing: 0.94px;
            color: #1F181C;
          }

          p {
            font-size: 20px;
            font-family: Arial;
            letter-spacing: 0px;
            color: #50474B;
            text-align: justify;
          }
        }
      }
    }

    li:nth-child(odd) {
      background-color: #f5f3f1;
    }
    li:nth-child(even) {
      background-color: white;
    }
  }

  .navigation {
    display: flex;
    justify-content: center;
    align-items: center;

    span {
      display: block;
      width: 30px;
      height: 8px;
      border-radius: 4px;
      background-color: #D9D7D2;
      cursor: pointer;

      &.active {
        background-color: #2F752A;
      }
    }

    span + span {
      margin-left: 13px;
    }
  }

  .illustration {
    align-self: flex-end;

    + * {
      margin-top: -46px;
    }
  }

  .highlight {
    align-self: flex-start;
    margin-bottom: 52px;
    text-align: justify;

    li {
      display: flex;

      a {
        display: flex;

        img {
          flex-shrink: 0;
          width: 293px;
          height: 219px;
          object-fit: cover;
          object-position: center;
        }

        > div {
          margin-left: 23px;
          padding-right: 18px;
          display: flex;
          flex-direction: column;
          flex: 1;

          h1 {
            font-size: 24px;
            line-height: 36px;
            font-weight: bold;
            font-family: Arial;
            letter-spacing: 0.6px;
            color: #2F752A;

            sup {
              vertical-align: super;
              font-weight: inherit;
            }
          }

          p {
            margin-top: 39px;
            font-size: 18px;
            line-height: 32px;
            font-family: Arial;
            letter-spacing: 0px;
            color: #0F0B0D;
          }
        }
      }
    }

    li + li {
      margin-top: 41px;
      border-top: 1px solid #c3bfb9;
      padding-top: 41px;
    }
  }

  table {
    width: 100%;

    thead {
      tr {
        th {
          background-color: #2F752A;
          font-size: 22px;
          font-family: Arial;
          letter-spacing: 2.2px;
          color: #FFFFFF;
          text-align: center;
          padding-top: 15px;
          padding-bottom: 20px;

          &:nth-child(1) {
            width: 200px;
          }

          &:nth-child(2) {
            width: auto;
          }

          &:nth-child(3) {
            width: 100px;
          }

          &:nth-child(4) {
            width: 200px;
          }
        }
      }
    }

    tbody {
      tr {
        td {
          vertical-align: middle;
          font-size: 22px;
          font-family: Arial;
          letter-spacing: 2.2px;
          color: #040404;

          &:nth-child(1) {
            text-align: center;
          }

          &:nth-child(2) {
            text-align: left;
          }

          &:nth-child(3) {
            text-align: center;
            padding-top: 11px;
            padding-bottom: 12px;

            a {
              display: flex;
              justify-content: center;

              img {
                height: 41px;
              }
            }
          }

          &:nth-child(4) {
            text-align: center;
          }
        }

        &:nth-child(odd) {
          background-color: #F5F3F1;
        }

        &:nth-child(even) {
          background-color: #DCD9D4;
        }
      }
    }
  }

  @media screen and (max-width: 991px) {
    .media-table {
      margin-bottom: 22px;

      li {
        padding: 12px 6.5px 7px;

        a {
          img {
            width: 33px;
          }

          div {
            margin-left: 3px;
            flex-wrap: wrap;

            span:nth-of-type(1) {
              font-size: 12px;
              letter-spacing: 0.6px;
            }

            span:nth-of-type(2) {
              margin-left: 0;
              font-size: 12px;
              letter-spacing: 0.6px;
            }

            p {
              width: 100%;
              flex: 1 1 100%;
              font-size: 12px;
              color: #6E6567;
            }
          }
        }
      }
    }

    .navigation {
      span {
        width: 23px;
        height: 6px;
      }

      span + span {
        margin-left: 10px;
      }
    }

    .illustration {
      margin-top: 53px;
      align-self: center;

      + * {
        margin-top: 53px;
      }
    }

    .highlight {
      margin-bottom: 19px;

      li {
        a {
          flex-direction: column;

          img {
            width: 100%;
            height: 206px;
          }

          > div {
            margin-top: 19px;
            margin-left: 0;
            padding-right: 0;

            h1 {
              font-size: 15px;
              line-height: 20px;
              letter-spacing: 0;
            }

            p {
              margin-top: 0;
              font-size: 12px;
              line-height: 21px;
            }
          }

        }
      }

      li + li {
        margin-top: 28px;
        padding-top: 28px;
      }
    }

    table {
      thead {
        tr {
          th {
            font-size: 12px;
            letter-spacing: 1.2px;
            padding-top: 5px;
            padding-bottom: 4px;

            &:nth-child(1) {
              width: 58px;
            }

            &:nth-child(2) {
              text-align: left;
            }

            &:nth-child(3) {
              width: 52px;
            }

            &:nth-child(4) {
              width: 90px;
            }
          }
        }
      }

      tbody {
        tr {
          td {
            font-size: 12px;
            letter-spacing: 0;

            &:nth-child(3) {
              a {
                img {
                  height: 17px;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
