
import { defineComponent } from 'vue'
import Banner from '@/components/Banner.vue'
import Breadcrumb from '@/components/Breadcrumb.vue'
import Title1 from '@/components/Title1.vue'
import Title2 from '@/components/Title2.vue'
import Title3 from '@/components/Title3.vue'
import Title4 from '@/components/Title4.vue'
import Text1 from '@/components/Text1.vue'
import List1 from '@/components/List1.vue'
import CaptionedImage from '@/components/CaptionedImage.vue'
import CaptionedPairImage from '@/components/CaptionedPairImage.vue'
import Row from '@/components/Row.vue'
import Link1 from '@/components/Link1.vue'
import Carousel1 from '@/components/Carousel1.vue'
import List2 from '@/components/List2.vue'
import Table1 from '@/components/Table1.vue'

export default defineComponent({
  name: 'USRComponent',
  props: {
    type: {
      type: String,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
  },
  components: {
    Banner,
    Breadcrumb,
    Title1,
    Title2,
    Title3,
    Title4,
    Text1,
    List1,
    CaptionedImage,
    CaptionedPairImage,
    Row,
    Link1,
    Carousel1,
    List2,
    Table1,
  },
  setup() {
  },
})
