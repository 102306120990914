
import { defineComponent, reactive } from 'vue'
import { Auth } from 'aws-amplify'
import { ElMessage, ElLoading } from 'element-plus'
import { Back } from '@element-plus/icons'
import { useRoute, useRouter } from 'vue-router'

export default defineComponent({
  name: 'ResetPasswordPage',
  components: {
    Back,
  },
  created() {
    this.$emit('update:layout', '')
  },
  setup() {
    const route = useRoute()
    const router = useRouter()
    const state = reactive({
      username: '',
      code: route.query.code as string,
      password: '',
    })

    const reset = () => {
      const loading = ElLoading.service({
        lock: true,
        text: 'Loading',
        background: 'rgba(0, 0, 0, 0.7)',
      })

      Auth.forgotPasswordSubmit(state.username, state.code, state.password)
        .then(() => {
          loading.close()
          ElMessage.success('密碼設定成功，請重新登入')
          router.push('/admin/login')
        })
        .catch(error => {
          loading.close()
          ElMessage.error('發生錯誤' + JSON.stringify(error))
        })
    }

    return {
      state,
      reset,
    }
  },
})
