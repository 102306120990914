<template>
  <El-Card class="content">
    <draggable
      tag="div"
      :list="components"
      handle=".handle"
      item-key="id"
    >
      <!-- Missing component type -->
      <template #item="{ element: component }">
        <El-Card class="box-card">
          <!-- Debug: {{component}} -->
          <El-Row justify="space-between" align="middle" style="font-size: var(--el-font-size-medium); margin-bottom: 10px">
            <El-Icon class="handle"><D-Caret /></El-Icon>
            {{component.type}}
            <El-Button
              circle
              @click="removeFromArray(components, component)"
            ><El-Icon><Close /></El-Icon></El-Button>
          </El-Row>

          <El-Form ref="form" :model="component" label-width="100px">
            <template v-if="component.type=='Banner'">
              <El-Form-Item label="Banner">
                <ImageUploader v-model="component.data.url" @upload="handleUpload" />
              </El-Form-Item>
            </template>
            <template v-if="component.type=='Breadcrumb'">
              <El-Form-Item label="url1">
                <El-Input v-model="component.data.url1"></El-Input>
              </El-Form-Item>
              <El-Form-Item label="text1">
                <El-Input v-model="component.data.text1"></El-Input>
              </El-Form-Item>
              <El-Form-Item label="text2">
                <El-Input v-model="component.data.text2"></El-Input>
              </El-Form-Item>
            </template>
            <template v-if="component.type=='Title1'">
              <El-Form-Item label="標題">
                <El-Input v-model="component.data.title"></El-Input>
              </El-Form-Item>
              <El-Form-Item label="子標題">
                <El-Input v-model="component.data.subtitle"></El-Input>
              </El-Form-Item>
            </template>
            <template v-if="component.type=='Title2'">
              <El-Form-Item label="標題">
                <El-Input v-model="component.data.title"></El-Input>
              </El-Form-Item>
            </template>
            <template v-if="component.type=='Title3'">
              <El-Form-Item label="標題">
                <El-Input v-model="component.data.title"></El-Input>
              </El-Form-Item>
            </template>
            <template v-if="component.type=='Title4'">
              <El-Form-Item label="標題">
                <El-Input v-model="component.data.title"></El-Input>
              </El-Form-Item>
            </template>
            <template v-if="component.type=='Text1'">
              <El-Form-Item label="文字">
                <El-Input
                  v-model="component.data.text"
                  autosize
                  type="textarea"
                ></El-Input>
              </El-Form-Item>
            </template>
            <template v-if="component.type=='List1'">
              <El-Form-Item label="list">
                <El-Card class="box-card" v-for="(item, index) in component.data.list" :key="index">
                  <El-Row justify="end" style="margin-bottom: 10px">
                    <El-Button
                      circle
                      @click="removeFromArray(component.data.list, item)"
                    ><El-Icon><Close /></El-Icon></El-Button>
                  </El-Row>
                  <El-Form ref="form" :model="item" label-width="80px">
                    <El-Form-Item label="項目">
                      <El-Input v-model="item.text"></El-Input>
                    </El-Form-Item>
                  </El-Form>
                </El-Card>
                <El-Row justify="center">
                  <El-Button
                    circle
                    @click="addToArray(component.data.list, list1ItemTemplate, component.data.list.length)"
                  ><El-Icon><Plus /></El-Icon></El-Button>
                </El-Row>
              </El-Form-Item>
            </template>
            <template v-if="component.type=='CaptionedImage'">
              <El-Form-Item label="圖片">
                <ImageUploader v-model="component.data.url" @upload="handleUpload" />
              </El-Form-Item>
              <El-Form-Item label="說明文字">
                <El-Input v-model="component.data.text"></El-Input>
              </El-Form-Item>
            </template>
            <template v-if="component.type=='CaptionedPairImage'">
              <El-Form-Item label="圖片 (左)">
                <ImageUploader v-model="component.data.url1" @upload="handleUpload" />
              </El-Form-Item>
              <El-Form-Item label="說明文字 (左)">
                <El-Input v-model="component.data.text1"></El-Input>
              </El-Form-Item>
              <El-Form-Item label="圖片 (右)">
                <ImageUploader v-model="component.data.url2" @upload="handleUpload" />
              </El-Form-Item>
              <El-Form-Item label="說明文字 (右)">
                <El-Input v-model="component.data.text2"></El-Input>
              </El-Form-Item>
            </template>
            <template v-if="component.type=='Row'">
              <El-Form-Item label="自訂內容">
                <!-- Debug Row: {{component}} -->
                <El-Card class="box-card" v-for="(rowComponent, index) in component.data.components" :key="index">
                  <El-Row justify="end" align="middle" style="font-size: var(--el-font-size-medium); margin-bottom: 10px">
                    <El-Button
                      circle
                      @click="removeFromArray(component.data.components, rowComponent)"
                    ><El-Icon><Close /></El-Icon></El-Button>
                  </El-Row>
                  <El-Form ref="form" :model="rowComponent" label-width="80px">
                    <template v-if="rowComponent.type=='Text1'">
                      <El-Form-Item label="文字">
                        <El-Input
                          v-model="rowComponent.data.text"
                          autosize
                          type="textarea"
                        ></El-Input>
                      </El-Form-Item>
                    </template>
                    <template v-if="rowComponent.type=='CaptionedImage'">
                      <El-Form-Item label="圖片">
                        <ImageUploader v-model="rowComponent.data.url" @upload="handleUpload" />
                      </El-Form-Item>
                      <El-Form-Item label="說明文字">
                        <El-Input v-model="rowComponent.data.text"></El-Input>
                      </El-Form-Item>
                    </template>
                  </El-Form>
                </El-Card>
                <El-Select placeholder="Add Component" @change="addToArray(component.data.components, getTemplate($event), component.data.components.length)">
                  <El-Option label="Text1" value="Text1" />
                  <El-Option label="CaptionedImage" value="CaptionedImage" />
                </El-Select>
              </El-Form-Item>
            </template>
            <template v-if="component.type=='Link1'">
              <El-Form-Item label="標題">
                <El-Input v-model="component.data.title"></El-Input>
              </El-Form-Item>
              <El-Form-Item label="網址">
                <El-Input v-model="component.data.url"></El-Input>
              </El-Form-Item>
            </template>
            <template v-if="component.type=='Carousel1'">
              <El-Form-Item label="Slides">
                <El-Card class="box-card" v-for="(slide, index) in component.data.slides" :key="index">
                  <El-Row justify="end" style="font-size: var(--el-font-size-medium); margin-bottom: 10px">
                    <El-Button
                      circle
                      @click="removeFromArray(component.data.slides, slide)"
                    ><El-Icon><Close /></El-Icon></El-Button>
                  </El-Row>
                  <El-Form ref="form" :model="slide" label-width="80px">
                    <El-Form-Item label="圖片">
                      <ImageUploader v-model="slide.imgUrl" @upload="handleUpload" />
                    </El-Form-Item>
                    <El-Form-Item label="標題">
                      <El-Input v-model="slide.title"></El-Input>
                    </El-Form-Item>
                    <El-Form-Item label="內容">
                      <El-Input v-model="slide.content"></El-Input>
                    </El-Form-Item>
                  </El-Form>
                </El-Card>
                <El-Row justify="center">
                  <El-Button
                    circle
                    @click="addToArray(component.data.slides, carousel1SlideTemplate, component.data.slides.length)"
                  ><El-Icon><Plus /></El-Icon></El-Button>
                </El-Row>
              </El-Form-Item>
            </template>
            <template v-if="component.type=='List2'">
              <El-Form-Item label="list">
                <El-Card class="box-card" v-for="(item, index) in component.data.list" :key="index">
                  <El-Row justify="end" style="font-size: var(--el-font-size-medium); margin-bottom: 10px">
                    <El-Button
                      circle
                      @click="removeFromArray(component.data.list, item)"
                    ><El-Icon><Close /></El-Icon></El-Button>
                  </El-Row>
                  <El-Form ref="form" :model="item" label-width="80px">

                    <El-Form-Item label="imgSrc">
                      <ImageUploader v-model="item.imgSrc" @upload="handleUpload" />
                    </El-Form-Item>
                    <El-Form-Item label="title">
                      <El-Input v-model="item.title"></El-Input>
                    </El-Form-Item>
                    <El-Form-Item label="text">
                      <El-Input v-model="item.text"></El-Input>
                    </El-Form-Item>
                  </El-Form>
                </El-Card>
                <El-Row justify="center">
                  <El-Button
                    circle
                    @click="addToArray(component.data.list, list2ItemTemplate, component.data.list.length)"
                  ><El-Icon><Plus /></El-Icon></El-Button>
                </El-Row>
              </El-Form-Item>
            </template>
            <template v-if="component.type=='Table1'">
              <El-Form-Item label="data">
                <El-Card class="box-card" v-for="(item, index) in component.data.data" :key="index">
                  <El-Row justify="end" style="font-size: var(--el-font-size-medium); margin-bottom: 10px">
                    <El-Button
                      circle
                      @click="removeFromArray(component.data.data, item)"
                    ><El-Icon><Close /></El-Icon></El-Button>
                  </El-Row>
                  <El-Form ref="form" :model="item" label-width="80px">
                    <El-Form-Item label="title">
                      <El-Input v-model="item.title"></El-Input>
                    </El-Form-Item>
                    <El-Form-Item label="year">
                      <El-Input v-model="item.year"></El-Input>
                    </El-Form-Item>
                    <El-Form-Item label="rows">
                      <El-Card class="box-card" v-for="(item2, index) in item.items" :key="index">
                        <El-Row justify="end" style="font-size: var(--el-font-size-medium); margin-bottom: 10px">
                          <El-Button
                            circle
                            @click="removeFromArray(item.items, item2)"
                          ><El-Icon><Close /></El-Icon></El-Button>
                        </El-Row>
                        <El-Form ref="form" :model="item2" label-width="80px">
                          <El-Form-Item label="type">
                            <El-Input v-model="item2.type"></El-Input>
                          </El-Form-Item>
                          <El-Form-Item label="name">
                            <El-Input v-model="item2.name"></El-Input>
                          </El-Form-Item>
                        </El-Form>
                      </El-Card>
                    </El-Form-Item>

                    <El-Row justify="center">
                      <El-Button
                        circle
                        @click="addToArray(item.items, table1ItemItemTemplate, item.items.length)"
                      ><El-Icon><Plus /></El-Icon></El-Button>
                    </El-Row>
                  </El-Form>
                </El-Card>
                <El-Row justify="center">
                  <El-Button
                    circle
                    @click="addToArray(component.data.data, table1ItemTemplate, component.data.data.length)"
                  ><El-Icon><Plus /></El-Icon></El-Button>
                </El-Row>
              </El-Form-Item>
            </template>
          </El-Form>
        </El-Card>
      </template>
    </draggable>
    <El-Row justify="center">
      <El-Select class="mt-10" placeholder="Add Component" @change="addToArray(components, getTemplate($event), components.length)">
        <El-Option
          v-for="type in supportTemplates"
          :key="type"
          :label="type"
          :value="type"
        />
      </El-Select>
    </El-Row>
  </El-Card>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from 'vue'
import { Close, Plus, DCaret } from '@element-plus/icons'
import { List1Item, Carousel1Slide, List2Item, Table1Item, Table1ItemItem, USRComponentType } from '@/model'
import { addToArray, removeFromArray } from '@/utils'
import { ElLoading } from 'element-plus'
import ImageUploader from '@/components/ImageUploader.vue'
import draggable from 'vuedraggable'
import { v4 as uuidv4 } from 'uuid'

export default defineComponent({
  name: 'USRComponentList',
  props: {
    components: {
      type: Array as PropType<Array<USRComponentType>>,
      required: true,
    },
    types: {
      type: Array as PropType<Array<string>>,
      required: false,
    }
  },
  components: {
    Close,
    Plus,
    DCaret,
    ImageUploader,
    draggable,
  },
  setup(props) {
    const list1ItemTemplate: List1Item = { text: '' }
    const carousel1SlideTemplate: Carousel1Slide = { imgUrl: '', title: '', content: '' }
    const list2ItemTemplate: List2Item = { imgSrc: '', title: '', text: '' }
    const table1ItemTemplate: Table1Item = { title: '', year: '', items: [] }
    const table1ItemItemTemplate: Table1ItemItem = { name: '', type: '' }

    const componentTemplates = [
      // {
      //   type: 'Banner',
      //   data: {
      //     url: '',
      //   }
      // },
      // {
      //   type: 'Breadcrumb',
      //   data: {
      //     url1: '',
      //     text1: '',
      //     text2: '',
      //   }
      // },
      {
        type: 'Title1',
        data: {
          title: '',
          subtitle: '',
        }
      },
      {
        type: 'Title2',
        data: {
          title: '',
        }
      },
      {
        type: 'Title3',
        data: {
          title: '',
        }
      },
      {
        type: 'Title4',
        data: {
          title: '',
        }
      },
      {
        type: 'Text1',
        data: {
          text: '',
        }
      },
      {
        type: 'CaptionedImage',
        data: {
          url: '',
          text: '',
        }
      },
      {
        type: 'CaptionedPairImage',
        data: {
          url1: '',
          text1: '',
          url2: '',
          text2: '',
        }
      },
      {
        type: 'List1',
        data: {
          list: [],
        }
      },
      {
        type: 'List2',
        data: {
          list: [],
        }
      },
      {
        type: 'Row',
        data: {
          components: [],
        }
      },
      {
        type: 'Link1',
        data: {
          title: '',
          url: '',
        }
      },
      {
        type: 'Carousel1',
        data: {
          slides: [],
        }
      },
      // {
      //   type: 'Table1',
      //   data: {
      //     data: [],
      //   }
      // }
    ]

    const getTemplate = (value: string) => {
      const foundComponent = componentTemplates.find(template => template.type === value)
      return {
        id: uuidv4(),
        ...foundComponent
      } as USRComponentType
    }

    const handleUpload = (promise: Promise<void>) => {
      const loading = ElLoading.service({
        lock: true,
        text: 'Loading',
        background: 'rgba(0, 0, 0, 0.7)',
      })
      promise
        .then(() => loading.close())
        .catch(() => loading.close())
    }

    return {
      list1ItemTemplate,
      carousel1SlideTemplate,
      list2ItemTemplate,
      table1ItemTemplate,
      table1ItemItemTemplate,
      supportTemplates: computed(() => props.types || componentTemplates.map(template => template.type)),
      getTemplate,
      addToArray,
      removeFromArray,
      handleUpload,
      list: [
        { name: 'John', text: '', id: 0 },
        { name: 'Joao', text: '', id: 1 },
        { name: 'Jean', text: '', id: 2 }
      ],
      dragging: false
    }
  },
})
</script>
<style lang="scss" scoped>
.button {
  margin-top: 35px;
}
.handle {
  cursor: pointer;
  float: left;
  padding-top: 8px;
  padding-bottom: 8px;
}
.close {
  float: right;
  padding-top: 8px;
  padding-bottom: 8px;
}
input {
  display: inline-block;
  width: 50%;
}
.text {
  margin: 20px;
}

</style>
