
import { defineComponent, reactive, watch } from 'vue'
import ManagementLayout from '@/layout/ManagementLayout.vue'
import { HistoryReport, HistoryFile, usrState, usrPreviewState, updateResource } from '@/model'
import { Close, Plus } from '@element-plus/icons'
import { addToArray, removeFromArray } from '@/utils'
import { ElLoading } from 'element-plus'
import api from '@/api'
import ImageUploader from '@/components/ImageUploader.vue'

export default defineComponent({
  name: 'ManageHistoryPage',
  components: {
    Close,
    Plus,
    ImageUploader,
  },
  created() {
    this.$emit('update:layout', ManagementLayout)
  },
  setup() {
    function getEditingData(): History {
      return JSON.parse(JSON.stringify(usrState.history))
    }

    const state = reactive({
      editing: false,
      editingData: getEditingData() as History,
    })

    watch(() => usrState.history, () => {
      console.log('updated!')
      state.editingData = getEditingData()
    })

    const cancel = () => {
      state.editingData = getEditingData()
    }

    const save = () => {
      const loading = ElLoading.service({
        lock: true,
        text: 'Loading',
        background: 'rgba(0, 0, 0, 0.7)',
      })

      api.updateHistory(state.editingData)
        .then(() => updateResource())
        .then(() => loading.close())
        .catch(() => loading.close())
    }

    const preview = () => {
      usrPreviewState.history = JSON.parse(JSON.stringify(state.editingData))
      localStorage.setItem('resourcesPreview', JSON.stringify(usrPreviewState))
      window.open('/historyPreview', '_blank')
    }

    const historyReportTemplate: HistoryReport = { time: new Date(), type: '', title: '', url: '' }
    const historyFileTemplate: HistoryFile = { title: '', url: '', time: new Date() }

    const handleUpload = (promise: Promise<void>) => {
      const loading = ElLoading.service({
        lock: true,
        text: 'Loading',
        background: 'rgba(0, 0, 0, 0.7)',
      })
      promise
        .then(() => loading.close())
        .catch(() => loading.close())
    }

    return {
      state,
      historyReportTemplate,
      historyFileTemplate,
      addToArray,
      removeFromArray,
      cancel,
      save,
      preview,
      handleUpload,
    }
  },
})
