
import { defineComponent, reactive, watch } from 'vue'
import ManagementLayout from '@/layout/ManagementLayout.vue'
import { Project, usrState, usrPreviewState, updateResource } from '@/model'
import { useRoute } from 'vue-router'
import USRComponentListEditor from '@/components/USRComponentListEditor.vue'
import { addToArray, removeFromArray } from '@/utils'
import { ElLoading } from 'element-plus'
import api from '@/api'
import ImageUploader from '@/components/ImageUploader.vue'

export default defineComponent({
  name: 'ManageProjectPage',
  components: {
    USRComponentListEditor,
    ImageUploader,
  },
  created() {
    this.$emit('update:layout', ManagementLayout)
  },
  setup() {
    const route = useRoute()
    const pId = route.params.id as string
    const isNew = pId === 'new'

    const dmTemplate = { imgSrc: '', url: '' }

    const editingData = getEditingData()

    function getEditingData() {
      return (isNew ? {
        image: '',
        title: '',
        text: '',
        fbUrl: '',
        ytUrl: '',
        igUrl: '',
        webUrl: '',
        managerEmail: '',
        components: [],
      } : usrState.projects.find(activity => activity.id === parseInt(pId, 10))) as Project
    }

    const state = reactive({
      editing: false,
      editingData,
    })

    watch(() => usrState.projects, () => {
      console.log('updated!')
      state.editingData = getEditingData()
    })

    const cancel = () => {
      state.editingData = getEditingData()
    }

    const save = () => {
      const loading = ElLoading.service({
        lock: true,
        text: 'Loading',
        background: 'rgba(0, 0, 0, 0.7)',
      })

      api.updateProject(state.editingData)
        .then(() => updateResource())
        .then(() => loading.close())
        .catch(() => loading.close())
    }

    const preview = () => {
      usrPreviewState.project = JSON.parse(JSON.stringify(state.editingData))
      localStorage.setItem('resourcesPreview', JSON.stringify(usrPreviewState))
      window.open('/projects/Preview', '_blank')
    }

    const handleUpload = (promise: Promise<void>) => {
      const loading = ElLoading.service({
        lock: true,
        text: 'Loading',
        background: 'rgba(0, 0, 0, 0.7)',
      })
      promise
        .then(() => loading.close())
        .catch(() => loading.close())
    }

    return {
      isNew,
      dmTemplate,
      state,
      addToArray,
      removeFromArray,
      cancel,
      save,
      preview,
      handleUpload,
    }
  },
})
