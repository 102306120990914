
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Title4',
  props: {
    title: {
      type: String,
      required: true,
    }
  },
  setup() {
  },
})
