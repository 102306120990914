<template>
  <El-Container>
    <El-Main>
      <El-Card class="box-card">
        <router-link to="/admin/login" class="back"><El-Icon><Back /></El-Icon></router-link>
        <h1 style="font-size: var(--el-font-size-extra-large); text-align: center">重設密碼</h1>
        <El-Form
          :model="state"
          label-width="80px"
          style="margin-top: 20px"
        >
          <El-Form-item label="Email">
            <El-Input v-model="state.username"></El-Input>
          </El-Form-item>
          <El-Form-item label="Password">
            <El-Input type="password" v-model="state.password"></El-Input>
          </El-Form-item>
          <El-Row justify="center" style="margin-top: 50px;">
            <El-Button type="text" @click="reset">重設</El-Button>
          </El-Row>
        </El-Form>
      </El-Card>
    </El-Main>
  </El-Container>
</template>

<script lang="ts">
import { defineComponent, reactive } from 'vue'
import { Auth } from 'aws-amplify'
import { ElMessage, ElLoading } from 'element-plus'
import { Back } from '@element-plus/icons'
import { useRoute, useRouter } from 'vue-router'

export default defineComponent({
  name: 'ResetPasswordPage',
  components: {
    Back,
  },
  created() {
    this.$emit('update:layout', '')
  },
  setup() {
    const route = useRoute()
    const router = useRouter()
    const state = reactive({
      username: '',
      code: route.query.code as string,
      password: '',
    })

    const reset = () => {
      const loading = ElLoading.service({
        lock: true,
        text: 'Loading',
        background: 'rgba(0, 0, 0, 0.7)',
      })

      Auth.forgotPasswordSubmit(state.username, state.code, state.password)
        .then(() => {
          loading.close()
          ElMessage.success('密碼設定成功，請重新登入')
          router.push('/admin/login')
        })
        .catch(error => {
          loading.close()
          ElMessage.error('發生錯誤' + JSON.stringify(error))
        })
    }

    return {
      state,
      reset,
    }
  },
})
</script>

<style lang="scss" scoped>
.box-card {
  width: 500px;
  max-width: 100%;
  margin: 100px auto 0;
  position: relative;

  .back {
    position: absolute;
    top: 15px;
    left: 15px;
  }
}
</style>
