<template>
  <div class="activity">
    <Banner :url="state.activity.imgUrl || '/img/activity/default_banner.jpg'" />
    <div class="container content">
      <Breadcrumb text1="首頁" url1="/" text2="近期活動" />

      <Title1 title="近期活動" subtitle="ACTIVITYS" />
      <Title4 :title="state.activity.title" />
      <USRComponentList :components="state.activity.components" />
      <div class="dm">
        <div class="item" :style="dmStyle(dm.imgSrc)" v-for="(dm, index) in state.activity.dmList" :key="index">
          <div class="mask">
            <a :href="dm.imgSrc" download>
              Download
              <img src="/img/activity/activity-download-icon.svg">
            </a>
          </div>
        </div>
      </div>
   </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive } from 'vue'
import MainLayout from '@/layout/MainLayout.vue'
import Banner from '@/components/Banner.vue'
import Breadcrumb from '@/components/Breadcrumb.vue'
import Title1 from '@/components/Title1.vue'
import Title4 from '@/components/Title4.vue'
import USRComponentList from '@/components/USRComponentList.vue'
import { useUsrHelper } from '@/model'

export default defineComponent({
  name: 'Activity',
  components: {
    Banner,
    Breadcrumb,
    Title1,
    Title4,
    USRComponentList,
  },
  created() {
    this.$emit('update:layout', MainLayout)
  },
  setup () {
    const helper = useUsrHelper()

    const state = reactive({
      activity: helper.activity,
    })

    return {
      state,
      dmStyle: (imgUrl: string) => ({ backgroundImage: `url(${imgUrl})` })
    }
  }
})
</script>

<style lang="scss">
.activity {
  .dm {
    display: flex;

    .item {
      flex: 1;
      max-width: 518px;
      height: 388px;
      background-size: contain;
      background-position: center;
      background-color: black;
      background-repeat: no-repeat;

      .mask {
        width: 100%;
        height: 100%;
        background-color: rgba(#E1DED9, 0.9);
        display: flex;
        justify-content: center;
        align-items: center;

        a {
          padding: 11px 38px;
          font-size: 20px;
          line-height: 23px;
          font-family: Arial;
          letter-spacing: 1.5px;
          color: #2F752A;
          box-shadow: 0px 0px 2px #7D7575BF;
          border-radius: 22px;
          background-color: #FFFFFF;

          img {
            height: 14px;
          }
        }
      }

      &:not(:hover) {
        .mask {
          display: none;
        }
      }
    }

    .item + .item {
      margin-left: 60px;
    }
  }
}

@media screen and (max-width: 991px) {
  .activity {
    .dm {
      display: flex;
      flex-direction: column;

      .item {
        flex: auto;
        width: 100%;
        height: 197px;

        .mask {
          a {
            padding: 11px 38px;
            font-size: 20px;
            line-height: 23px;
            font-family: Arial;
            letter-spacing: 1.5px;
            color: #2F752A;
            box-shadow: 0px 0px 2px #7D7575BF;
            border-radius: 22px;
            background-color: #FFFFFF;
          }
        }
      }

      .item + .item {
        margin-left: 0;
        margin-top: 30px;
      }
    }
  }
}
</style>

function useRoute() {
  throw new Error('Function not implemented.')
}

function reactive(arg0: { enews: any }) {
  throw new Error('Function not implemented.')
}

function computed(arg0: () => any) {
  throw new Error('Function not implemented.')
}
