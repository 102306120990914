
import { computed, defineComponent, PropType } from 'vue'
import { Close, Plus, DCaret } from '@element-plus/icons'
import { List1Item, Carousel1Slide, List2Item, Table1Item, Table1ItemItem, USRComponentType } from '@/model'
import { addToArray, removeFromArray } from '@/utils'
import { ElLoading } from 'element-plus'
import ImageUploader from '@/components/ImageUploader.vue'
import draggable from 'vuedraggable'
import { v4 as uuidv4 } from 'uuid'

export default defineComponent({
  name: 'USRComponentList',
  props: {
    components: {
      type: Array as PropType<Array<USRComponentType>>,
      required: true,
    },
    types: {
      type: Array as PropType<Array<string>>,
      required: false,
    }
  },
  components: {
    Close,
    Plus,
    DCaret,
    ImageUploader,
    draggable,
  },
  setup(props) {
    const list1ItemTemplate: List1Item = { text: '' }
    const carousel1SlideTemplate: Carousel1Slide = { imgUrl: '', title: '', content: '' }
    const list2ItemTemplate: List2Item = { imgSrc: '', title: '', text: '' }
    const table1ItemTemplate: Table1Item = { title: '', year: '', items: [] }
    const table1ItemItemTemplate: Table1ItemItem = { name: '', type: '' }

    const componentTemplates = [
      // {
      //   type: 'Banner',
      //   data: {
      //     url: '',
      //   }
      // },
      // {
      //   type: 'Breadcrumb',
      //   data: {
      //     url1: '',
      //     text1: '',
      //     text2: '',
      //   }
      // },
      {
        type: 'Title1',
        data: {
          title: '',
          subtitle: '',
        }
      },
      {
        type: 'Title2',
        data: {
          title: '',
        }
      },
      {
        type: 'Title3',
        data: {
          title: '',
        }
      },
      {
        type: 'Title4',
        data: {
          title: '',
        }
      },
      {
        type: 'Text1',
        data: {
          text: '',
        }
      },
      {
        type: 'CaptionedImage',
        data: {
          url: '',
          text: '',
        }
      },
      {
        type: 'CaptionedPairImage',
        data: {
          url1: '',
          text1: '',
          url2: '',
          text2: '',
        }
      },
      {
        type: 'List1',
        data: {
          list: [],
        }
      },
      {
        type: 'List2',
        data: {
          list: [],
        }
      },
      {
        type: 'Row',
        data: {
          components: [],
        }
      },
      {
        type: 'Link1',
        data: {
          title: '',
          url: '',
        }
      },
      {
        type: 'Carousel1',
        data: {
          slides: [],
        }
      },
      // {
      //   type: 'Table1',
      //   data: {
      //     data: [],
      //   }
      // }
    ]

    const getTemplate = (value: string) => {
      const foundComponent = componentTemplates.find(template => template.type === value)
      return {
        id: uuidv4(),
        ...foundComponent
      } as USRComponentType
    }

    const handleUpload = (promise: Promise<void>) => {
      const loading = ElLoading.service({
        lock: true,
        text: 'Loading',
        background: 'rgba(0, 0, 0, 0.7)',
      })
      promise
        .then(() => loading.close())
        .catch(() => loading.close())
    }

    return {
      list1ItemTemplate,
      carousel1SlideTemplate,
      list2ItemTemplate,
      table1ItemTemplate,
      table1ItemItemTemplate,
      supportTemplates: computed(() => props.types || componentTemplates.map(template => template.type)),
      getTemplate,
      addToArray,
      removeFromArray,
      handleUpload,
      list: [
        { name: 'John', text: '', id: 0 },
        { name: 'Joao', text: '', id: 1 },
        { name: 'Jean', text: '', id: 2 }
      ],
      dragging: false
    }
  },
})
