
import { computed, defineComponent, reactive, onMounted, onUnmounted, ref, watch } from 'vue'
import ScrollTop from '@/components/ScrollTop.vue'
import { useUsrHelper } from '@/model'
import { useRoute, useRouter } from 'vue-router'
export default defineComponent({
  name: 'MainLayout',
  components: {
    ScrollTop,
  },
  setup() {
    const helper = useUsrHelper()
    const router = useRouter()
    const route = useRoute()

    const closeMenu = () => {
      state.active = false
    }

    const state = reactive({
      active: false,
    })

    const homeClass = computed(() => {
      return { 'router-link-active': router.currentRoute.value.path.match(/homePreview|news|activities/g), }
    })
    const aboutClass = computed(() => {
      return { 'router-link-active': router.currentRoute.value.path.match(/about/g), }
    })
    const projectClass = computed(() => {
      return { 'router-link-active': router.currentRoute.value.path.match(/projects/g), }
    })
    const historyClass = computed(() => {
      return { 'router-link-active': router.currentRoute.value.path.match(/history|highlights/g), }
    })

    const header$ = ref()

    function handleClick(e: MouseEvent) {
      if (!header$.value?.contains(e.target)) {
        state.active = false
      }
    }

    watch(() => route.path, () => {
      window.scrollTo(0, 0)
    })

    onMounted(() => {
      document.addEventListener('click', handleClick)
    })

    onUnmounted(() => {
      document.removeEventListener('click', handleClick)
    })
    return {
      state,
      sessions: helper.projectSessions,
      header$,
      homeClass,
      aboutClass,
      projectClass,
      historyClass,
      closeMenu,
    }
  },
})
