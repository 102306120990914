<template>
  <div class="projects">
    <banner :url="projectHome.imgUrl" />
    <div class="container content">
      <ul class="sessions">
        <li
          v-for="projectSession in projectSessions"
          :key="projectSession.session.sessionNo"
          :class="{ active: projectSession.session.sessionNo === state.sessionNo }"
          @click="selectSession(projectSession.session.sessionNo)"
        >
          {{ projectSession.session.sessionName }}
        </li>
      </ul>
      <Breadcrumb text1="團隊與場域" url1="/projects" text2="各項計畫" />
      <Title1 title="團隊與場域" subtitle="PROJECTS" />
      <ul class="projects">
        <li
          v-for="project in projects" :key="project.id"
          class="wow fadeInUp"
          data-wow-duration="2s">
          <img :src="project.image" alt="">
          <div>
            <h1>【{{project.title}}】</h1>
            <p>{{project.text}}</p>
            <router-link :to="'/projects/' + project.id">VIEW MORE +</router-link>
          </div>
        </li>
      </ul>
   </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, reactive, watch } from 'vue'
import MainLayout from '@/layout/MainLayout.vue'
import Banner from '@/components/Banner.vue'
import Breadcrumb from '@/components/Breadcrumb.vue'
import Title1 from '@/components/Title1.vue'
import { usrState, useUsrHelper } from '@/model'
import { useRoute } from 'vue-router'

export default defineComponent({
  name: 'Projects',
  components: {
    Banner,
    Breadcrumb,
    Title1,
  },
  created() {
    this.$emit('update:layout', MainLayout)
  },
  setup () {
    const route = useRoute()
    const helper = useUsrHelper()

    const state = reactive({
      sessionNo: parseInt(route.query.session as string, 10) || 2,
    })

    const projects = computed(() => {
      const sessionNo = state.sessionNo
      return helper.projectSessions.value.find(projectSession => projectSession.session.sessionNo === sessionNo)?.projects || []
    })

    const selectSession = (sessionNo: number) => {
      state.sessionNo = sessionNo
    }

    watch(() => route.query.session, (sessionNo) => {
      selectSession(parseInt(sessionNo as string, 10))
    })

    return {
      state,
      projects,
      projectHome: helper.projectHome,
      projectSessions: helper.projectSessions,
      usrState,
      selectSession,
    }
  }
})
</script>

<style lang="scss">
.projects {
  ul {
    &.sessions {
      margin-top: 52px;
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      grid-column-gap: 44px;
      grid-row-gap: 25px;

      li {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 37px;
        font-family: Arial;
        font-weight: bold;
        font-size: 18px;
        line-height: 17px;
        text-align: center;
        color: #fff;
        border-radius: 18px;
        cursor: pointer;

        &:not(.active) {
          background: linear-gradient(#7b7c7b 0%, #909090 71.43%, #d3d2d2 100%);
          opacity: 0.8;
          filter: drop-shadow(0px 0px 3.65px rgba(181, 178, 171, 0.75));
        }

        &.active, &:hover {
          background: linear-gradient(#2c6d20 0%, #337321 7.67%, #88c12d 100%);
          box-shadow: 0px 0px 3.65px rgba(181, 178, 171, 0.75);
        }
      }
    }
    &.projects {
      li {
        display: flex;
        box-shadow: 0px 0px 5px #B5B2ABBF;

        img {
          align-self: stretch;
          flex-shrink: 0;
          width: 463px;
          min-height: 347px;
          object-fit: cover;
          object-position: center;
        }

        div {
          flex: 1;
          padding: 60px 36px 35px;
          display: flex;
          flex-direction: column;
          background-color: #F5F4F1;

          h1 {
            font-size: 24px;
            line-height: 36px;
            min-height: 70px;
            font-family: Arial;
            letter-spacing: 0.6px;
            color: #2F752A;
          }

          p {
            margin-top: 12px;
            font-size: 18px;
            line-height: 32px;
            font-family: Arial;
            letter-spacing: 0.18px;
            color: #0F0B0D;
          }

          a {
            margin-top: 23px;
            align-self: flex-end;
            padding: 7px 20px 8px;
            background: transparent linear-gradient(90deg, #FF750A 0%, #FD6C08 18%, #F94A04 100%) 0% 0% no-repeat padding-box;
            border-radius: 17px;
            font-size: 14px;
            font-weight: bold;
            line-height: 17px;
            font-family: Arial;
            letter-spacing: 1.08px;
            color: white;
          }
        }

        &:nth-child(even) {
          flex-direction: row-reverse;

          div {
            flex: 1;

            a {
              margin-right: 0;
            }
          }
        }
      }

      li + li {
        margin-top: 90px;
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .projects {
    ul {
      &.sessions {
        margin-top: 23px;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-column-gap: 9px;
        grid-row-gap: 18px;

        li {
          height: 31px;
          font-size: 10px;
          line-height: 12px;
          border-radius: 16px;
        }
      }

      &.projects {
        li {
          flex-direction: column;

          img {
            width: 100%;
            min-height: 230px;
          }

          div {
            padding: 25px 20px 23px;

            h1 {
              font-size: 16px;
              line-height: 22px;
              height: auto;
              letter-spacing: 0;
            }

            p {
              font-size: 13px;
              line-height: 23px;
              letter-spacing: 0;
            }

            a {
              margin-top: 23px;
              align-self: center;
              font-size: 13px;
              line-height: 15px;
              letter-spacing: 0;
              margin-right: 0;
            }
          }

          &:nth-child(even) {
            flex-direction: column;

            div {
              padding: 25px 20px 23px;
            }
          }
        }

        li + li {
          margin-top: 49px;
        }
      }
    }
  }
}
</style>
