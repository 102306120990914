<template>
  <div class="about">
    <banner :url="state.about.imgUrl" />
    <div class="container content">
      <Breadcrumb text1="關於我們" url1="/about" text2="NTUST USR" />
      <Title1 id="ntustUsr" title="TAIWAN TECH USR" subtitle="TAIWAN TECH USR" />

      <Text1 text="近年來於在地關懷、產業鏈結、永續發展上，臺科大已累積相當成就，並將「重視全人教育，培育優質人才」、「聚焦優勢領域，釋出研發能量」與「強化社會責任，協助城鄉發展」三項與大學社會責任相關的策略明確納入本校校務發展目標。同時參考行政院「區域均衡與永續發展」之施政理念、日本推動「地方創生」之策略制定目標及聯合國17項永續發展目標(SDGs)，建構出本校於「在地關懷與地方創生」、「人才培育與全人發展」、「環境強韌與永續」及「國際助力與資源共享」之多核心發展藍圖。" />
      <Text1 text="本校重視大學社會責任與中長程校務發展連結，以中長程校務發展目標、多元化推動經驗及社會實踐多核心發展藍圖等，再建構臺科大「在地城南，永續無限」USR生態系統，透過四層系統-師生、學校、在地周邊及外部環境之間相互影響，由內而外層層擴散社會責任影響力，期望有效促進社會實踐多元對話、跨域協作及社群認同，進而展現大學社會責任的影響力。" />
      <Text1 :text="'最內層(學校師生)：促進師生社會實踐認同感\n第二層(學校治理)：推動永續校園治理\n第三層(在地周邊)：規劃在地城南實踐計畫\n最外層(外部環境)：連結國際夥伴與研究能量'" />
      <CaptionedImage class="hide-mobile" url="/img/about/blueprint.png" />
      <CaptionedImage class="hide-pc" url="/img/about/blueprint_mobile.png" />
      <Text1 text="教育部「大學社會責任實踐計畫」，自推動以來，逐日受到教育界之重視，不論是國內或是國際間，對於大學社會責任該如何體現與實踐，也都發揮各自的專業能力與技術領域。本校自107年起USR團隊開始跨出國界、不分國內國外，深入印尼、越南及尼泊爾等國外偏鄉，協助在地改善灌溉、震後屋舍重建等，致力實踐於大學社會責任同時，也讓參與的學生獲得極為珍貴的實作經驗。" />
      <Title2 title="/本校大學社會責任實踐計畫歷年項目/" />
      <Table1 :data="state.about.historyProjects" />
      <Text1 text="除了透過計畫的參與，本校推動專業實作力、博雅素養力及國際橋接力的技職π型人才培育計畫，其中博雅素養力的培養，在社會實踐佔有重要的角色，本校過去透過通識教育中心於共同必修科目規劃「社會實踐」領域，已漸漸凝聚師生對社會實踐的意識，現建構城南學院「博雅教育」的社會創新八大概念，打造結合「在地創生」與「行動學習」為主軸的城南學院，協助「π型人」的養成，讓實作能力取向的科技人，能兼備包容多元文化與實踐社會創新的能力。" />
      <a class="link" href="https://cla.ntust.edu.tw/" target="_blank">
        <CaptionedImage class="hide-mobile" url="/img/about/academy.png" text="臺科大城南學院" />
        <CaptionedImage class="hide-pc" url="/img/about/academy_mobile.png" text="臺科大城南學院" />
      </a>
      <a class="link" href="https://sp.cge.ntust.edu.tw/" target="_blank">
        <CaptionedImage url="/img/about/course_process.jpeg" />
      </a>
      <Title1 title="團隊人員簡介" subtitle="OUR TEAM MEMBERS" />
      <Title2 title="/永續發展與校務研究中心/" />
      <Text1 text="隨著本校對大學社會責任之重視及中長程校務發展的規劃，以聯合國永續發展目標及順應大學永續治理教育趨勢，推動本校校務永續發展及實踐大學社會責任，中心主任由校長指定一位副校長兼任，及由項下之「永續發展與社會實踐推動組」，綜理規劃學校永續及大學社會責任之發展政策推動。" />
      <CaptionedImage class="hide-mobile" url="/img/about/organize.png" />
      <CaptionedImage class="hide-pc" url="/img/about/organize_mobile.png" />
      <Title2 id="team" title="/各計畫之團隊人員/" />
      <ul>
        <li v-for="(project, index) in state.about.projects" :key="index">
          <Title4 :title="project.name" />
          <Carousel
            :settings="settings"
            :breakpoints="breakpoints"
            :wrapAround="setWrapAround(project.members)"
          >
            <Slide v-for="(member, index) in project.members" :key="index">
              <img :src="member.url" alt="">
              <div class="mask">
                <span>【{{member.type}}】</span>
                <h1>{{member.name}}</h1>
                <p>{{member.job}}</p>
                <p>{{member.school}}<br>{{member.department}}</p>
              </div>
            </Slide>
            <template #addons>
              <Navigation />
            </template>
          </Carousel>
        </li>
      </ul>
   </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive } from 'vue'
import MainLayout from '@/layout/MainLayout.vue'
import Banner from '@/components/Banner.vue'
import Breadcrumb from '@/components/Breadcrumb.vue'
import Title1 from '@/components/Title1.vue'
import Text1 from '@/components/Text1.vue'
import CaptionedImage from '@/components/CaptionedImage.vue'
import Title2 from '@/components/Title2.vue'
import Table1 from '@/components/Table1.vue'
import Title4 from '@/components/Title4.vue'
import { Carousel, Navigation, Slide } from 'vue3-carousel'
import { useUsrHelper } from '@/model'

export default defineComponent({
  name: 'About',
  components: {
    Banner,
    Breadcrumb,
    Carousel,
    Slide,
    Title1,
    Text1,
    CaptionedImage,
    Title2,
    Table1,
    Title4,
    Navigation,
  },
  created() {
    this.$emit('update:layout', MainLayout)
  },
  setup () {
    const helper = useUsrHelper()

    const settings = {
      itemsToShow: 1,
      snapAlign: 'left',
    }
    const breakpoints = {
      992: {
        itemsToShow: 3,
        snapAlign: 'left',
      },
    }

    const state = reactive({
      about: helper.about
    })

    function setWrapAround(data: any) {
      if (data.length >= 4) {
        return true
      }
      if (data.length < 4) {
        return false
      }
    }

    return {
      state,
      settings,
      breakpoints,
      setWrapAround,
      slideStyle: (url: string) => `background-image: url('${url}')`,
    }
  }
})
</script>

<style lang="scss">
.about {
  .usr-image-caption {
    img {
      max-width: 896px;
    }
  }

  ul {
    > li {
      .carousel {
        width: calc(100% + 28px);
        margin-left: -14px;

        margin-top: 27px;

        .carousel__slide {
          height: 231px;
          display: flex;
          position: relative;
          padding-left: 14px;
          padding-right: 14px;

          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            object-position: center;
            background-color: black;
            background-color: #D8D8D8;
          }

          .mask {
            position: absolute;
            left: 14px;
            top: 0;
            right: 14px;
            bottom: 0;
            background-color: rgba(#E1DED9, 0.9);
            padding-top: 46px;
            display: flex;
            flex-direction: column;
            align-items: center;

            span {
              font-size: 20px;
              line-height: 26px;
              font-family: Arial;
              letter-spacing: 0.2px;
              color: #1F181C;
              text-align: center;
              display: block;
            }

            h1 {
              margin-top: 9.5px;
              display: flex;
              flex-direction: column;
              align-items: center;
              font-size: 30px;
              line-height: 50px;
              font-family: Arial;
              letter-spacing: 0.3px;
              color: #2F752A;

              &:after {
                margin-top: 3px;
                content: '';
                display: block;
                height: 3px;
                width: 30px;
                background-color: #2F752A;
              }
            }

            p:nth-of-type(1) {
              margin-top: 18px;
              font-size: 15px;
              line-height: 26px;
              font-family: Arial;
              letter-spacing: 0.15px;
              color: #1F181C;
              text-align: center;
            }

            p:nth-of-type(2) {
              font-size: 13px;
              line-height: 20px;
              font-family: Arial;
              letter-spacing: 0.13px;
              color: #1F181C;
              text-align: center;
            }
          }

          &:not(:hover) .mask {
            display: none;
          }
        }

        .carousel__prev {
          left: -70px;
        }
        .carousel__next {
          right: -70px;
        }

        .carousel__prev, .carousel__next {
          background-color: #DCDAD4;
          color: #9e9e9f;
          width: 45px;
          height: 45px;
          border-radius: 100%;

          .carousel__icon {
            width: 40px;
            height: 40px;
          }
        }
      }
    }

    > li + li {
      margin-top: 64px;
      border-top: 1px solid #C3BFB9;
      padding-top: 49px;
    }
  }
}

@media screen and (max-width: 991px) {
  .about {
    .usr-image-caption {
      padding-left: 20px;
      padding-right: 20px;
    }

    .team {
      height: auto;
    }

    ul {
      > li {
        .carousel {
          .carousel__slide {
            .mask {
              p {
                br {
                  display: none;
                }
              }
            }
          }

          .carousel__prev {
            left: -6px;
          }
          .carousel__next {
            right: -6px;
          }

          .carousel__prev,
          .carousel__next {
            width: 25px;
            height: 25px;

            .carousel__icon {
              width: 20px;
              height: 20px;
            }
          }
        }
      }
    }
  }
}
</style>
