
import { defineComponent, getCurrentInstance, reactive, watch } from 'vue'
import ManagementLayout from '@/layout/ManagementLayout.vue'
import { useRouter } from 'vue-router'
import { usrState, Project, updateResource, ProjectHome, usrPreviewState } from '@/model'
import { formatTime, isAuthUserAdmin } from '@/utils'
import { ElLoading } from 'element-plus'
import { SortDown, SortUp } from '@element-plus/icons'
import ImageUploader from '@/components/ImageUploader.vue'
import api from '@/api'
import { Auth } from 'aws-amplify'
import { CognitoUser } from 'amazon-cognito-identity-js'

export default defineComponent({
  name: 'ManageProjectListPage',
  components: {
    SortDown,
    SortUp,
    ImageUploader,
  },
  created() {
    this.$emit('update:layout', ManagementLayout)
  },
  setup() {
    const proxy = getCurrentInstance()?.proxy
    const router = useRouter()
    function getEditingData(): ProjectHome {
      return JSON.parse(JSON.stringify(usrState.projectHome))
    }

    const state = reactive({
      isAdmin: false,
      projects: [] as Project[],
      loading: false,
      editing: false,
      editingData: getEditingData() as ProjectHome,
    })

    Auth.currentAuthenticatedUser().then(async (user: CognitoUser) => {
      const isAdmin = await isAuthUserAdmin()
      state.isAdmin = isAdmin
      const username = user.getUsername()

      state.projects = isAdmin
        ? usrState.projects
        : usrState.projects.filter(project => project.managerEmail === username)
    })

    isAuthUserAdmin().then(isAdmin => {
      state.isAdmin = isAdmin
    })

    watch(() => usrState.projectHome, () => {
      console.log('updated!')
      state.editingData = getEditingData()
    })
    watch(() => usrState.projects, () => {
      console.log('updated!')
      state.projects = usrState.projects
    })

    const cancel = () => {
      state.editingData = getEditingData()
    }

    const save = () => {
      const loading = ElLoading.service({
        lock: true,
        text: 'Loading',
        background: 'rgba(0, 0, 0, 0.7)',
      })

      api.updateProjectHome(state.editingData)
        .then(() => updateResource())
        .then(() => loading.close())
        .catch(() => loading.close())
    }

    const preview = () => {
      usrPreviewState.projectHome = JSON.parse(JSON.stringify(state.editingData))
      localStorage.setItem('resourcesPreview', JSON.stringify(usrPreviewState))
      window.open('/projectsPreview', '_blank')
    }

    const handleUpload = (promise: Promise<void>) => {
      const loading = ElLoading.service({
        lock: true,
        text: 'Loading',
        background: 'rgba(0, 0, 0, 0.7)',
      })
      promise
        .then(() => loading.close())
        .catch(() => loading.close())
    }

    const handleEdit = (index: number, item: Project) => {
      router.push('/admin/projects/' + item.id)
    }

    const handleDelete = (index: number, project: Project) => {
      console.log('handleDeleteProject', { index, project })
      proxy?.$confirm(`確認刪除「${project.title}(${project.id})」？`)
        .then(async () => {
          const loading = ElLoading.service({
            lock: true,
            text: 'Loading',
            background: 'rgba(0, 0, 0, 0.7)',
          })

          api.deleteProject(project.id)
            .then(() => updateResource())
            .then(() => loading.close())
            .catch(() => loading.close())
        })
        .catch(() => { /* prevent exception */ })
    }

    const handleChangeOrder = (project: Project, newOrder: number) => {
      console.log('handleChangeOrder', { project, newOrder })
      proxy?.$confirm(`修改順序「${project.title}(${project.id})」？`)
        .then(async () => {
          const loading = ElLoading.service({
            lock: true,
            text: 'Loading',
            background: 'rgba(0, 0, 0, 0.7)',
          })

          api.updateProject({ ...project, order: newOrder })
            .then(() => updateResource())
            .then(() => loading.close())
            .catch(() => loading.close())
        })
        .catch(() => { /* prevent exception */ })
    }

    return {
      state,
      handleEdit,
      handleDelete,
      formatTime,
      cancel,
      save,
      preview,
      handleUpload,
      handleChangeOrder,
    }
  },
})
