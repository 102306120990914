<template>
  <ul class="usr-list-1">
    <li v-for="(item, index) in linkifyList" :key="index" v-html="item"></li>
  </ul>
</template>

<script lang="ts">
import { List1Item } from '@/model'
import { defineComponent, computed, PropType } from 'vue'

export default defineComponent({
  name: 'List1',
  props: {
    list: {
      type: Array as PropType<Array<List1Item>>,
      required: true,
    },
  },
  setup(props) {
    const linkifyList = computed(() => {
      const urlRegex = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/g
      return props.list.map((item) => {
        return item.text.replace(urlRegex, (a: string, url: string) => `<a href="${url}" target="_blank">${url}</a>`)
      })
    })

    return {
      linkifyList,
    }
  },
})
</script>

<style lang="scss">
.usr-list-1 {
  li {
    font-size: 21px;
    line-height: 36px;
    letter-spacing: 0.21px;
    color: #1F181C;

    &:before {
      content: ' ';
      display: inline-block;
      width: 17px;
      height: 17px;
      margin-right: 9px;
      background-size: contain;
      background-image: url('../assets/images/green-star.png');
      background-repeat: no-repeat;
    }

    a {
      text-decoration: underline
    }
  }
}

@media screen and (max-width: 991px) {
  .usr-list-1 {
    li {
      font-size: 12px;
      line-height: 21px;
      letter-spacing: 0.12px;
      &:before {
        width: 12px;
        height: 12px;
      }
    }
  }
}
</style>
