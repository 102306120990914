
import { defineComponent, reactive, watch } from 'vue'
import ManagementLayout from '@/layout/ManagementLayout.vue'
import { About, AboutProject, AboutProjectMember, HistoryProject, HistoryProjectItem, usrState, usrPreviewState, updateResource } from '@/model'
import { Close, Plus, DCaret } from '@element-plus/icons'
import { addToArray, isAuthUserAdmin, removeFromArray } from '@/utils'
import api from '@/api'
import { ElLoading } from 'element-plus'
import ImageUploader from '@/components/ImageUploader.vue'
import { v4 as uuidv4 } from 'uuid'
import draggable from 'vuedraggable'

export default defineComponent({
  name: 'ManageAboutPage',
  components: {
    Close,
    Plus,
    ImageUploader,
    DCaret,
    draggable,
  },
  created() {
    this.$emit('update:layout', ManagementLayout)
  },
  setup() {
    const editingData = getEditingData()

    function getEditingData(): About {
      return JSON.parse(JSON.stringify(usrState.about))
    }

    const state = reactive({
      isAdmin: false,
      editing: false,
      editingData,
    })

    isAuthUserAdmin().then(isAdmin => {
      state.isAdmin = isAdmin
    })

    watch(() => usrState.about, () => {
      console.log('updated!')
      state.editingData = getEditingData()
    })

    const historyProjectTemplate: HistoryProject = { title: '', year: '', items: [] }
    const historyProjectItemTemplate: HistoryProjectItem = { type: '', name: '' }
    const aboutProjectTemplate: AboutProject = { name: '', members: [] }
    const aboutProjectMemberTemplate: AboutProjectMember = {
      id: uuidv4(),
      url: '',
      type: '',
      name: '',
      job: '',
      school: '',
      department: '',
    }

    const cancel = () => {
      state.editingData = getEditingData()
    }

    const save = () => {
      const loading = ElLoading.service({
        lock: true,
        text: 'Loading',
        background: 'rgba(0, 0, 0, 0.7)',
      })

      api.updateAbout(state.editingData)
        .then(() => updateResource())
        .then(() => loading.close())
        .catch(() => loading.close())
    }

    const preview = () => {
      usrPreviewState.about = JSON.parse(JSON.stringify(state.editingData))
      localStorage.setItem('resourcesPreview', JSON.stringify(usrPreviewState))
      window.open('/aboutPreview', '_blank')
    }

    const handleUpload = (promise: Promise<void>) => {
      const loading = ElLoading.service({
        lock: true,
        text: 'Loading',
        background: 'rgba(0, 0, 0, 0.7)',
      })
      promise
        .then(() => loading.close())
        .catch(() => loading.close())
    }

    return {
      state,
      historyProjectTemplate,
      historyProjectItemTemplate,
      aboutProjectTemplate,
      aboutProjectMemberTemplate,
      addToArray,
      removeFromArray,
      cancel,
      save,
      preview,
      handleUpload,
    }
  },
})
