export const TITLE = process.env.VUE_APP_TITLE
export const API_HOST = process.env.VUE_APP_API_HOST
export const USER_POOL_ID = process.env.VUE_APP_USER_POOL_ID
export const CLIENT_ID = process.env.VUE_APP_CLIENT_ID

console.log({
  TITLE,
  API_HOST,
  USER_POOL_ID,
  CLIENT_ID,
})
