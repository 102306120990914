<template>
  <div id="news">
    <banner :url="news.imgUrl" />
    <div class="container content">
      <Breadcrumb text1="首頁" url1="/" text2="電子報" />
      <Title1 title="電子報" subtitle="E-NEWSLETTER" />
      <Title2 :title="newsTitle" />
      <USRComponentList :components="newsComponents" />
      <div class="media wow fadeInUp" data-wow-duration="2s">
        <a v-if="news.fbUrl" :href="news.fbUrl" target="_blank"><img src="@/assets/images/media-fb.png" alt=""></a>
        <a v-if="news.ytUrl" :href="news.ytUrl" target="_blank"><img src="@/assets/images/media-yt.png" alt=""></a>
        <a v-if="news.igUrl" :href="news.igUrl" target="_blank"><img src="@/assets/images/media-ig.png" alt=""></a>
        <a v-if="news.webUrl" :href="news.webUrl" target="_blank"><img src="@/assets/images/media-site.png" alt=""></a>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import MainLayout from '@/layout/MainLayout.vue'
import Banner from '@/components/Banner.vue'
import Breadcrumb from '@/components/Breadcrumb.vue'
import Title1 from '@/components/Title1.vue'
import Title2 from '@/components/Title2.vue'
import USRComponentList from '@/components/USRComponentList.vue'
import { useUsrHelper } from '@/model'

export default defineComponent({
  name: 'News',
  components: {
    Banner,
    Breadcrumb,
    Title1,
    Title2,
    USRComponentList,
  },
  created() {
    this.$emit('update:layout', MainLayout)
  },
  setup () {
    const helper = useUsrHelper()
    const news = helper.news.value

    return {
      news: helper.news,
      newsTitle: helper.newsTitle,
      newsComponents: helper.newsComponents
    }
  }
})
</script>

<style lang="scss">
#news {
  .usr-title-2 + .usr-title-3 {
    border-top: 1px solid #C1C1C1;
    padding-top: 30px;
  }

  .media {
    a {
      img {
        width: 57px;
      }
    }
    a + a {
      margin-left: 20px;
    }
  }
}

@media screen and (max-width: 991px) {
  #news {
    .media {
      a {
        img {
          width: 23px;
        }
      }
      a + a {
        margin-left: 7px;
      }
    }
  }
}
</style>
