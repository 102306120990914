
import { defineComponent } from 'vue'
import Text1 from '@/components/Text1.vue'
import CaptionedImage from '@/components/CaptionedImage.vue'

export default defineComponent({
  name: 'Row',
  props: {
    components: {
      type: Array,
      required: true,
    }
  },
  components: {
    Text1,
    CaptionedImage,
  },
  setup() {
  },
})
