
import { computed, defineComponent, reactive, watch } from 'vue'

import { useRoute } from 'vue-router'
import * as Icons from '@element-plus/icons'
import { Auth } from 'aws-amplify'
import router from '@/router'
import { isAuthUserAdmin } from '@/utils'

export default defineComponent({
  name: 'ManagementLayout',
  props: {
    path: String,
  },
  components: {
    ...Icons,
  },
  setup() {
    const route = useRoute()

    const state = reactive({
      isAdmin: false,
      username: '',
      activityClass: computed(() => ({ 'is-active': route.path.match(/admin\/activities/g) })),
      newsClass: computed(() => ({ 'is-active': route.path.match(/admin\/news/g) })),
      projectClass: computed(() => ({ 'is-active': route.path.match(/admin\/projects/g) })),
      highlightClass: computed(() => ({ 'is-active': route.path.match(/admin\/highlights/g) })),
    })

    isAuthUserAdmin().then(isAdmin => {
      state.isAdmin = isAdmin
    })

    const logout = () => {
      Auth.signOut().then(() => router.push('/admin/login'))
    }

    const checkAuth = () => {
      Auth.currentAuthenticatedUser()
        .then(data => {
          state.username = data.username
        })
        .catch(() => router.push('/'))
    }

    checkAuth()

    watch(() => route.path, checkAuth)

    return {
      state,
      logout,
    }
  },
})
