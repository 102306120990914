<template>
  <div class="content">
    <h1 :style="`font-size: var(--el-font-size-extra-large)`">實踐紀錄</h1>
    <!-- Debug: {{state.editingData}} -->
    <El-Form ref="form" :model="state.editingData" label-width="80px"
      v-loading="state.editing"
    >
      <El-Form-Item label="Banner">
        <ImageUploader v-model="state.editingData.imgUrl" @upload="handleUpload" />
      </El-Form-Item>
      <El-Form-Item label="媒體報導">
        <El-Card class="box-card" v-for="(item, index) in state.editingData.reports" :key="index">
          <El-Row justify="end" style="font-size: var(--el-font-size-medium); margin-bottom: 10px">
            <El-Button
              circle
              @click="removeFromArray(state.editingData.reports, item)"
            ><El-Icon><Close /></El-Icon></El-Button>
          </El-Row>
          <El-Form ref="form" :model="item" label-width="80px">
            <El-Form-Item label="日期">
              <El-Date-Picker v-model="item.time" type="date" placeholder="Pick a day" :default-value="new Date()" />
            </El-Form-Item>
            <El-Form-Item label="類型">
              <El-Input v-model="item.type"></El-Input>
            </El-Form-Item>
            <El-Form-Item label="標題">
              <El-Input v-model="item.title"></El-Input>
            </El-Form-Item>
            <El-Form-Item label="網址">
              <El-Input v-model="item.url"></El-Input>
            </El-Form-Item>
          </El-Form>
        </El-Card>
        <El-Row justify="center">
          <El-Button
            circle
            @click="addToArray(state.editingData.reports, historyReportTemplate, state.editingData.reports.length)"
          ><El-Icon><Plus /></El-Icon></El-Button>
        </El-Row>
      </El-Form-Item>

      <El-Form-Item label="檔案下載">
        <El-Card class="box-card" v-for="(item, index) in state.editingData.files" :key="index">
          <El-Row justify="end" style="font-size: var(--el-font-size-medium); margin-bottom: 10px">
            <El-Button
              circle
              @click="removeFromArray(state.editingData.files, item)"
            ><El-Icon><Close /></El-Icon></El-Button>
          </El-Row>
          <El-Form ref="form" :model="item" label-width="80px">
            <El-Form-Item label="檔案名稱">
              <El-Input v-model="item.title"></El-Input>
            </El-Form-Item>
            <El-Form-Item label="檔案網址">
              <El-Input v-model="item.url"></El-Input>
            </El-Form-Item>
            <El-Form-Item label="更新日期">
              <El-Date-Picker v-model="item.time" type="date" placeholder="Pick a day" :default-value="new Date()" />
            </El-Form-Item>
          </El-Form>
        </El-Card>
        <El-Row justify="center">
          <El-Button
            circle
            @click="addToArray(state.editingData.files, historyFileTemplate, state.editingData.files.length)"
          ><El-Icon><Plus /></El-Icon></El-Button>
        </El-Row>
      </El-Form-Item>
    </El-Form>
    <El-Row justify="center">
      <El-Button-Group>
        <El-Button type="info" @click="cancel">Cancel</El-Button>
        <El-Button type="primary" @click="save">Save</El-Button>
        <El-Button type="warning" @click="preview">Preview</El-Button>
      </El-Button-Group>
    </El-Row>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive, watch } from 'vue'
import ManagementLayout from '@/layout/ManagementLayout.vue'
import { HistoryReport, HistoryFile, usrState, usrPreviewState, updateResource } from '@/model'
import { Close, Plus } from '@element-plus/icons'
import { addToArray, removeFromArray } from '@/utils'
import { ElLoading } from 'element-plus'
import api from '@/api'
import ImageUploader from '@/components/ImageUploader.vue'

export default defineComponent({
  name: 'ManageHistoryPage',
  components: {
    Close,
    Plus,
    ImageUploader,
  },
  created() {
    this.$emit('update:layout', ManagementLayout)
  },
  setup() {
    function getEditingData(): History {
      return JSON.parse(JSON.stringify(usrState.history))
    }

    const state = reactive({
      editing: false,
      editingData: getEditingData() as History,
    })

    watch(() => usrState.history, () => {
      console.log('updated!')
      state.editingData = getEditingData()
    })

    const cancel = () => {
      state.editingData = getEditingData()
    }

    const save = () => {
      const loading = ElLoading.service({
        lock: true,
        text: 'Loading',
        background: 'rgba(0, 0, 0, 0.7)',
      })

      api.updateHistory(state.editingData)
        .then(() => updateResource())
        .then(() => loading.close())
        .catch(() => loading.close())
    }

    const preview = () => {
      usrPreviewState.history = JSON.parse(JSON.stringify(state.editingData))
      localStorage.setItem('resourcesPreview', JSON.stringify(usrPreviewState))
      window.open('/historyPreview', '_blank')
    }

    const historyReportTemplate: HistoryReport = { time: new Date(), type: '', title: '', url: '' }
    const historyFileTemplate: HistoryFile = { title: '', url: '', time: new Date() }

    const handleUpload = (promise: Promise<void>) => {
      const loading = ElLoading.service({
        lock: true,
        text: 'Loading',
        background: 'rgba(0, 0, 0, 0.7)',
      })
      promise
        .then(() => loading.close())
        .catch(() => loading.close())
    }

    return {
      state,
      historyReportTemplate,
      historyFileTemplate,
      addToArray,
      removeFromArray,
      cancel,
      save,
      preview,
      handleUpload,
    }
  },
})
</script>
<style lang="scss">
  .mt-10 {
    margin-top: 10px;
  }

  .el-card + * {
    margin-top: 10px
  }

  .el-form-item + * {
    margin-top: 10px;
  }
</style>
