
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ScrollTop',
  setup() {
    const scrollToTop = () => {
      window.scrollTo(0, 0)
    }
    return {
      scrollToTop,
    }
  },
})
