
import { defineComponent, reactive, watch } from 'vue'
import ManagementLayout from '@/layout/ManagementLayout.vue'
import { usrState, usrPreviewState, HomeNews, HomeVideo, Home, updateResource } from '@/model'
import { Close, Plus, DCaret } from '@element-plus/icons'
import { addToArray, isAuthUserAdmin, removeFromArray } from '@/utils'
import { ElLoading } from 'element-plus'
import api from '@/api'
import ImageUploader from '@/components/ImageUploader.vue'
import draggable from 'vuedraggable'

export default defineComponent({
  name: 'ManageHomePage',
  components: {
    Close,
    Plus,
    DCaret,
    ImageUploader,
    draggable,
  },
  created() {
    this.$emit('update:layout', ManagementLayout)
  },
  setup() {
    function getEditingData() {
      return JSON.parse(JSON.stringify(usrState.home)) as Home
    }

    const state = reactive({
      isAdmin: false,
      editing: false,
      editingData: getEditingData() as Home,
    })

    isAuthUserAdmin().then(isAdmin => {
      state.isAdmin = isAdmin
    })

    watch(() => usrState.home, () => {
      console.log('updated!')
      state.editingData = getEditingData()
    })

    const cancel = () => {
      state.editingData = getEditingData()
    }

    const save = () => {
      const loading = ElLoading.service({
        lock: true,
        text: 'Loading',
        background: 'rgba(0, 0, 0, 0.7)',
      })

      api.updateHome(state.editingData as Home) // workaround as Home
        .then(() => updateResource())
        .then(() => loading.close())
        .catch(() => loading.close())
    }

    const preview = () => {
      usrPreviewState.home = JSON.parse(JSON.stringify(state.editingData))
      localStorage.setItem('resourcesPreview', JSON.stringify(usrPreviewState))
      window.open('/homePreview', '_blank')
    }

    const newsTemplate: HomeNews = { time: new Date(), type: '', title: '', url: '' }
    const videoTemplate: HomeVideo = { ytUrl: '', title: '', text: '' }
    const bannerImageUrlTemplate = { imgSrc: '' }

    const handleUpload = (promise: Promise<void>) => {
      const loading = ElLoading.service({
        lock: true,
        text: 'Loading',
        background: 'rgba(0, 0, 0, 0.7)',
      })
      promise
        .then(() => loading.close())
        .catch(() => loading.close())
    }

    return {
      state,
      newsTemplate,
      videoTemplate,
      bannerImageUrlTemplate,
      addToArray,
      removeFromArray,
      cancel,
      save,
      preview,
      handleUpload,
    }
  },
})
