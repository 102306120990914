
import { computed, defineComponent, reactive, watch } from 'vue'
import MainLayout from '@/layout/MainLayout.vue'
import Banner from '@/components/Banner.vue'
import Breadcrumb from '@/components/Breadcrumb.vue'
import Title1 from '@/components/Title1.vue'
import { usrState, useUsrHelper } from '@/model'
import { useRoute } from 'vue-router'

export default defineComponent({
  name: 'Projects',
  components: {
    Banner,
    Breadcrumb,
    Title1,
  },
  created() {
    this.$emit('update:layout', MainLayout)
  },
  setup () {
    const route = useRoute()
    const helper = useUsrHelper()

    const state = reactive({
      sessionNo: parseInt(route.query.session as string, 10) || 2,
    })

    const projects = computed(() => {
      const sessionNo = state.sessionNo
      return helper.projectSessions.value.find(projectSession => projectSession.session.sessionNo === sessionNo)?.projects || []
    })

    const selectSession = (sessionNo: number) => {
      state.sessionNo = sessionNo
    }

    watch(() => route.query.session, (sessionNo) => {
      selectSession(parseInt(sessionNo as string, 10))
    })

    return {
      state,
      projects,
      projectHome: helper.projectHome,
      projectSessions: helper.projectSessions,
      usrState,
      selectSession,
    }
  }
})
