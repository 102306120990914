<template>
  <div class="content">
    <h1 :style="`font-size: var(--el-font-size-extra-large)`">{{isNew ? '建立計畫' : '編輯計畫'}}</h1>
    <!-- Debug: {{state.editingData}} -->
    <El-Form ref="form" :model="state.editingData" label-width="120px"
      v-loading="state.editing"
    >
      <El-Form-Item label="Id" v-if="state.editingData.id">
        <El-Input v-model="state.editingData.id" disabled></El-Input>
      </El-Form-Item>
      <El-Form-Item label="Banner">
        <ImageUploader v-model="state.editingData.image" @upload="handleUpload" />
      </El-Form-Item>
      <El-Form-Item label="計畫期數數字">
        <El-Input-Number v-model="state.editingData.sessionNo" :min="2" />
      </El-Form-Item>
      <El-Form-Item label="計畫期數名稱">
        <El-Input v-model="state.editingData.sessionName"></El-Input>
      </El-Form-Item>
      <El-Form-Item label="計畫名稱">
        <El-Input v-model="state.editingData.title"></El-Input>
      </El-Form-Item>
      <El-Form-Item label="計畫說明">
        <El-Input v-model="state.editingData.text"></El-Input>
      </El-Form-Item>
      <El-Form-Item label="負責人Email">
        <El-Input v-model="state.editingData.managerEmail"></El-Input>
      </El-Form-Item>
      <El-Form-Item label="Facebook網址">
        <El-Input v-model="state.editingData.fbUrl"></El-Input>
      </El-Form-Item>
      <El-Form-Item label="Youtube網址">
        <El-Input v-model="state.editingData.ytUrl"></El-Input>
      </El-Form-Item>
      <El-Form-Item label="Instagram網址">
        <El-Input v-model="state.editingData.igUrl"></El-Input>
      </El-Form-Item>
      <El-Form-Item label="網站">
        <El-Input v-model="state.editingData.webUrl"></El-Input>
      </El-Form-Item>
      <El-Form-Item label="自訂內容">
        <USRComponentListEditor :components="state.editingData.components" />
      </El-Form-Item>
    </El-Form>

    <El-Row justify="center">
      <El-Button-Group>
        <El-Button type="info" @click="cancel">Cancel</El-Button>
        <El-Button type="primary" @click="save">{{isNew ? 'Add' : 'Edit'}}</El-Button>
        <El-Button type="warning" @click="preview">Preview</El-Button>
      </El-Button-Group>
    </El-Row>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive, watch } from 'vue'
import ManagementLayout from '@/layout/ManagementLayout.vue'
import { Project, usrState, usrPreviewState, updateResource } from '@/model'
import { useRoute } from 'vue-router'
import USRComponentListEditor from '@/components/USRComponentListEditor.vue'
import { addToArray, removeFromArray } from '@/utils'
import { ElLoading } from 'element-plus'
import api from '@/api'
import ImageUploader from '@/components/ImageUploader.vue'

export default defineComponent({
  name: 'ManageProjectPage',
  components: {
    USRComponentListEditor,
    ImageUploader,
  },
  created() {
    this.$emit('update:layout', ManagementLayout)
  },
  setup() {
    const route = useRoute()
    const pId = route.params.id as string
    const isNew = pId === 'new'

    const dmTemplate = { imgSrc: '', url: '' }

    const editingData = getEditingData()

    function getEditingData() {
      return (isNew ? {
        image: '',
        title: '',
        text: '',
        fbUrl: '',
        ytUrl: '',
        igUrl: '',
        webUrl: '',
        managerEmail: '',
        components: [],
      } : usrState.projects.find(activity => activity.id === parseInt(pId, 10))) as Project
    }

    const state = reactive({
      editing: false,
      editingData,
    })

    watch(() => usrState.projects, () => {
      console.log('updated!')
      state.editingData = getEditingData()
    })

    const cancel = () => {
      state.editingData = getEditingData()
    }

    const save = () => {
      const loading = ElLoading.service({
        lock: true,
        text: 'Loading',
        background: 'rgba(0, 0, 0, 0.7)',
      })

      api.updateProject(state.editingData)
        .then(() => updateResource())
        .then(() => loading.close())
        .catch(() => loading.close())
    }

    const preview = () => {
      usrPreviewState.project = JSON.parse(JSON.stringify(state.editingData))
      localStorage.setItem('resourcesPreview', JSON.stringify(usrPreviewState))
      window.open('/projects/Preview', '_blank')
    }

    const handleUpload = (promise: Promise<void>) => {
      const loading = ElLoading.service({
        lock: true,
        text: 'Loading',
        background: 'rgba(0, 0, 0, 0.7)',
      })
      promise
        .then(() => loading.close())
        .catch(() => loading.close())
    }

    return {
      isNew,
      dmTemplate,
      state,
      addToArray,
      removeFromArray,
      cancel,
      save,
      preview,
      handleUpload,
    }
  },
})
</script>
<style lang="scss">
  .mt-10 {
    margin-top: 10px;
  }

  .el-card + * {
    margin-top: 10px
  }

  .el-form-item + * {
    margin-top: 10px;
  }
</style>
