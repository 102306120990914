<template>
  <img src="../assets/images/top@2x.png" alt="" class="top" @click="scrollToTop">
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ScrollTop',
  setup() {
    const scrollToTop = () => {
      window.scrollTo(0, 0)
    }
    return {
      scrollToTop,
    }
  },
})
</script>

<style lang="scss">
.top {
  position: fixed;
  right: 83px;
  bottom: 83px;
  width: 101px;
  cursor: pointer;
}

@media screen and (max-width: 991px) {
  .top {
    right: 20px;
    bottom: 20px;
    width: 50px;
  }
}
</style>
