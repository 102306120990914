<template>
  <div class="content">
    <h1 :style="`font-size: var(--el-font-size-extra-large)`">團隊與場域</h1>
    <El-Form ref="form" :model="state.editingData" label-width="80px"
      v-loading="state.editing"
      v-if="state.isAdmin"
    >
      <El-Form-Item label="Banner">
        <ImageUploader v-model="state.editingData.imgUrl" @upload="handleUpload" />
      </El-Form-Item>
      <El-Row justify="center">
        <El-Button-Group>
          <El-Button type="info" @click="cancel">Cancel</El-Button>
          <El-Button type="primary" @click="save">Save</El-Button>
          <El-Button type="warning" @click="preview">Preview</El-Button>
        </El-Button-Group>
      </El-Row>
    </El-Form>
    <El-Table
      :data="state.projects"
      style="width: 100%"
      :default-sort = "{prop: 'order', order: 'ascending'}"
      v-loading="state.loading"
    >
      <El-Table-Column prop="id" label="Id" width="60" />
      <El-Table-Column prop="image" label="Banner" width="120">
        <template #default="scope">
          <El-Image
            style="width: 100px; height: 100px"
            :src="scope.row.image"
            fit="cover"
          ></El-Image>
        </template>
      </El-Table-Column>
      <El-Table-Column prop="title" label="計畫名稱" />
      <El-Table-Column prop="time" label="時間" width="220" />
      <El-Table-Column prop="managerEmail" label="負責人Email" />
      <El-Table-Column prop="sessionNo" label="計畫編號" width="80"/>
      <El-Table-Column label="順序" width="80">
        <template #default="scope">
          <El-Row justify="space-between" align="middle">
            <El-Button class="flip" type="text" size="small" @click="handleChangeOrder(scope.row, scope.row.order - 1)" :disabled="scope.row.order==0"><El-Icon><SortUp /></El-Icon></El-Button>
            <span>{{scope.row.order}}</span>
            <El-Button class="flip" type="text" size="small" @click="handleChangeOrder(scope.row, scope.row.order + 1)" :disabled="state.projects.length-1==scope.row.order"><El-Icon><SortDown /></El-Icon></El-Button>
          </El-Row>
        </template>
      </El-Table-Column>
      <El-Table-Column fixed="right" label="" width="120" align="right">
        <template #header>
          <El-Link v-if="state.isAdmin" type="primary" href="/admin/projects/new">New</El-Link>
        </template>
        <template #default="scope">
          <El-Button type="text" size="small" @click="handleEdit(scope.$index, scope.row)">Edit</El-Button>
          <El-Button type="text" size="small" @click="handleDelete(scope.$index, scope.row)" v-if="state.isAdmin">Delete</El-Button>
        </template>
      </El-Table-Column>
    </El-Table>
  </div>
</template>

<script lang="ts">
import { defineComponent, getCurrentInstance, reactive, watch } from 'vue'
import ManagementLayout from '@/layout/ManagementLayout.vue'
import { useRouter } from 'vue-router'
import { usrState, Project, updateResource, ProjectHome, usrPreviewState } from '@/model'
import { formatTime, isAuthUserAdmin } from '@/utils'
import { ElLoading } from 'element-plus'
import { SortDown, SortUp } from '@element-plus/icons'
import ImageUploader from '@/components/ImageUploader.vue'
import api from '@/api'
import { Auth } from 'aws-amplify'
import { CognitoUser } from 'amazon-cognito-identity-js'

export default defineComponent({
  name: 'ManageProjectListPage',
  components: {
    SortDown,
    SortUp,
    ImageUploader,
  },
  created() {
    this.$emit('update:layout', ManagementLayout)
  },
  setup() {
    const proxy = getCurrentInstance()?.proxy
    const router = useRouter()
    function getEditingData(): ProjectHome {
      return JSON.parse(JSON.stringify(usrState.projectHome))
    }

    const state = reactive({
      isAdmin: false,
      projects: [] as Project[],
      loading: false,
      editing: false,
      editingData: getEditingData() as ProjectHome,
    })

    Auth.currentAuthenticatedUser().then(async (user: CognitoUser) => {
      const isAdmin = await isAuthUserAdmin()
      state.isAdmin = isAdmin
      const username = user.getUsername()

      state.projects = isAdmin
        ? usrState.projects
        : usrState.projects.filter(project => project.managerEmail === username)
    })

    isAuthUserAdmin().then(isAdmin => {
      state.isAdmin = isAdmin
    })

    watch(() => usrState.projectHome, () => {
      console.log('updated!')
      state.editingData = getEditingData()
    })
    watch(() => usrState.projects, () => {
      console.log('updated!')
      state.projects = usrState.projects
    })

    const cancel = () => {
      state.editingData = getEditingData()
    }

    const save = () => {
      const loading = ElLoading.service({
        lock: true,
        text: 'Loading',
        background: 'rgba(0, 0, 0, 0.7)',
      })

      api.updateProjectHome(state.editingData)
        .then(() => updateResource())
        .then(() => loading.close())
        .catch(() => loading.close())
    }

    const preview = () => {
      usrPreviewState.projectHome = JSON.parse(JSON.stringify(state.editingData))
      localStorage.setItem('resourcesPreview', JSON.stringify(usrPreviewState))
      window.open('/projectsPreview', '_blank')
    }

    const handleUpload = (promise: Promise<void>) => {
      const loading = ElLoading.service({
        lock: true,
        text: 'Loading',
        background: 'rgba(0, 0, 0, 0.7)',
      })
      promise
        .then(() => loading.close())
        .catch(() => loading.close())
    }

    const handleEdit = (index: number, item: Project) => {
      router.push('/admin/projects/' + item.id)
    }

    const handleDelete = (index: number, project: Project) => {
      console.log('handleDeleteProject', { index, project })
      proxy?.$confirm(`確認刪除「${project.title}(${project.id})」？`)
        .then(async () => {
          const loading = ElLoading.service({
            lock: true,
            text: 'Loading',
            background: 'rgba(0, 0, 0, 0.7)',
          })

          api.deleteProject(project.id)
            .then(() => updateResource())
            .then(() => loading.close())
            .catch(() => loading.close())
        })
        .catch(() => { /* prevent exception */ })
    }

    const handleChangeOrder = (project: Project, newOrder: number) => {
      console.log('handleChangeOrder', { project, newOrder })
      proxy?.$confirm(`修改順序「${project.title}(${project.id})」？`)
        .then(async () => {
          const loading = ElLoading.service({
            lock: true,
            text: 'Loading',
            background: 'rgba(0, 0, 0, 0.7)',
          })

          api.updateProject({ ...project, order: newOrder })
            .then(() => updateResource())
            .then(() => loading.close())
            .catch(() => loading.close())
        })
        .catch(() => { /* prevent exception */ })
    }

    return {
      state,
      handleEdit,
      handleDelete,
      formatTime,
      cancel,
      save,
      preview,
      handleUpload,
      handleChangeOrder,
    }
  },
})
</script>

<style lang="scss" scoped>
.flip {
  transform: scaleX(-1);
}
</style>
