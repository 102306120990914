<template>
  <USRComponent v-for="(component, index) in components" :type="component.type" :data="component.data" :key="index" />
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import USRComponent from '@/components/USRComponent.vue'

export default defineComponent({
  name: 'USRComponentList',
  props: {
    components: {
      type: Array,
      required: true,
    }
  },
  components: {
    USRComponent,
  },
  setup() {
  },
})
</script>
