
import { computed, defineComponent } from 'vue'
import Row from '@/components/Row.vue'

export default defineComponent({
  name: 'CaptionedPairImage',
  props: {
    url1: {
      type: String,
      required: true,
    },
    text1: {
      type: String,
    },
    url2: {
      type: String,
      required: true,
    },
    text2: {
      type: String,
    },
  },
  setup(props) {
    const components = computed(() => [
      {
        type: 'CaptionedImage',
        data: {
          url: props.url1,
          text: props.text1,
        },
      },
      {
        type: 'CaptionedImage',
        data: {
          url: props.url2,
          text: props.text2,
        },
      },
    ])

    return {
      components,
    }
  },
  components: {
    Row,
  },
})
