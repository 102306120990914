<template>
  <div id="home">
    <Carousel
      class="banner-carousel"
      :settings="bannerSettings"
      :wrapAround="true"
    >
      <Slide v-for="(bannerImage, i) in state.home.bannerImages" :key="i">
        <img class="hide-mobile" :src="bannerImage.imgSrc" alt="" />
        <img class="hide-pc" :src="bannerImage.imgSrcMobile" alt="" />
      </Slide>
      <template #addons>
        <Navigation />
      </template>
    </Carousel>
    <div class="container content">
      <Breadcrumb text1="首頁" url1="/" text2="最新公告" />
      <Title1 :title="state.home.newsTitle" :subtitle="state.home.newsTitleEn" id="news" />
      <Carousel
        class="usr-carousel-news wow fadeInUp"
        :settings="newsSettings"
        :wrapAround="true"
        data-wow-duration="2s"
      >
        <Slide v-for="(item, index) in state.newsList" :key="index">
          <div class="usr-carousel-news-container">
            <a :href="news.url" target="_blank" class="usr-carousel-news-item" v-for=" (news, i) in item" :key="i">
              <div class="left">
                <p class="day">{{ formatTime(news.time, 'DD') }}</p>
                <p class="month">{{ formatTime(news.time, 'MMM') }}</p>
                </div>
              <div class="right">
                <p class="title">【{{ news.type }}】</p>
                <p class="content">{{ news.title }}</p>
              </div>
            </a>
          </div>
        </Slide>
        <template #addons>
          <pagination />
        </template>
      </Carousel>

      <Title1 :title="state.home.activityTitle" :subtitle="state.home.activityTitleEn" id="activity" />
      <Carousel
        class="usr-carousel-activity"
        :settings="activitySettings"
        :breakpoints="activityBreakpoints"
        :wrapAround="true"
      >
        <Slide v-for="(item, i) in state.activities" :key="i">
          <router-link class="usr-carousel-activity-container" :to="{ name: 'Activity', params: { id: item.id }}">
            <img :src="activityImage(item)" alt="" />
            <div class="usr-carousel-activity-card">
              <p class="title">{{ item.title }}</p>
              <p class="time">{{ item.time }}</p>
              <p class="local">{{ item.local }}</p>
            </div>
          </router-link>
        </Slide>
        <template #addons>
          <Navigation />
        </template>
      </Carousel>

      <Title1 :title="state.home.videoTitle" :subtitle="state.home.videoTitleEn" id="video" />
      <Carousel
        class="usr-carousel-video"
        :settings="videoSettings"
        :breakpoints="videoBreakpoints"
        :wrapAround="true"
      >
        <Slide v-for="(item, i) in state.home.videos" :key="i">
          <a class="usr-carousel-video-container" :href="item.ytUrl" target="_blank">
            <iframe :src="ytIframe(item.ytUrl)" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"></iframe>
            <div class="usr-carousel-video-card">
              <p class="title">{{ item.title }}</p>
              <p class="content">{{ item.text }}</p>
            </div>
          </a>
        </Slide>
        <template #addons>
          <Navigation />
        </template>
      </Carousel>

      <Title1 :title="state.home.enewsTitle" :subtitle="state.home.enewsTitleEn" id="enews" />
      <Carousel
        class="e-news-carousel wow fadeInUp"
        :settings="enewsSettings"
        :wrapAround="true"
      >
        <Slide v-for="(enewsList, i) in state.enewsList" :key="i">
          <div class="e-news" v-for="(item, i) in enewsList" :key="i">
            <router-link class="periods hide-pc" :to="{ name: 'News', params: { id: item.id }}">
              {{item.periods}}
            </router-link>
            <router-link :to="{ name: 'News', params: { id: item.id }}">
              <p class="periods hide-mobile"> {{item.periods}}</p>
              <p class="title"> {{item.title}}</p>
            </router-link>
            <router-link :to="{ name: 'News', params: { id: item.id }, query: { lang: 'en'}}">
              <p class="periods hide-mobile"> {{item.periodsEn}}</p>
              <p class="title"> {{item.titleEn}}</p>
            </router-link>
          </div>
        </Slide>
        <template #addons>
          <pagination />
        </template>
      </Carousel>
    </div>
  </div>
</template>
<script lang="ts">
import { computed, defineComponent, reactive } from 'vue'
import { Carousel, Slide, Navigation, Pagination } from 'vue3-carousel'
import MainLayout from '@/layout/MainLayout.vue'
import { usrState, Activity, useUsrHelper } from '@/model'
import { splitArrayList, formatTime } from '@/utils'
import Title1 from '@/components/Title1.vue'
import Breadcrumb from '@/components/Breadcrumb.vue'

export default defineComponent({
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
    Breadcrumb,
    Title1,
  },
  created() {
    this.$emit('update:layout', MainLayout)
  },
  setup () {
    const helper = useUsrHelper()

    const bannerSettings = {
      itemsToShow: 1,
      autoplay: 9000,
      transition: 1000,
    }

    const newsSettings = {
      itemsToShow: 1,
      snapAlign: 'start'
    }

    const activitySettings = {
      itemsToShow: 1,
      snapAlign: 'center'
    }
    const activityBreakpoints = {
      992: {
        itemsToShow: 3,
        snapAlign: 'start',
      },
    }
    const videoSettings = {
      itemsToShow: 1,
      snapAlign: 'start',
    }
    const videoBreakpoints = {
      992: {
        itemsToShow: 4,
        snapAlign: 'start',
      },
    }
    const enewsSettings = {
      itemsToShow: 1,
      snapAlign: 'start',
    }

    const state = reactive({
      home: helper.home,
      newsList: computed(() => splitArrayList(helper.home.value.news, 5)),
      activities: computed(() => usrState.activities.map(activity => activity)),
      enewsList: splitArrayList(usrState.news, 5),
    })

    const ytIframe = (ytUrl: string) => {
      const params = new URLSearchParams(ytUrl.split('?')[1])
      const v = params.get('v')

      return `https://www.youtube.com/embed/${v}?controls=0`
    }

    const activityImage = (activity: Activity) => {
      if (!activity.dmList || activity.dmList.length === 0) {
        return '/img/activity/default_banner.jpg'
      }
      return activity.dmList[0].imgSrc
    }

    return {
      state,
      bannerSettings,
      newsSettings,
      activitySettings,
      activityBreakpoints,
      videoSettings,
      videoBreakpoints,
      enewsSettings,
      activityImage,
      ytIframe,
      formatTime,
    }
  }
})
</script>

<style lang="scss">
.carousel__pagination-button::after {
  width: 30px;
  height: 8px;
  background: #d9d7d2;
  border-radius: 4px;
}

.carousel__pagination-button--active::after {
  background: #2f752a;
}

.banner-carousel {
  width: 100%;

  .carousel__slide {
    display: flex;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  .carousel__prev, .carousel__next {
    width: 51px;
    height: 51px;
    background: rgba(#C9C9C9, 0.6);
    transform: translate(0, -50%);
    border-radius: 100%;

    svg {
      --vc-icn-width: 50px;
    }
  }

  .carousel__prev {
    left: 28px;
  }

  .carousel__next {
    right: 28px;
  }
}

.usr-carousel-news {
  width: 100%;

  .carousel__slide {
    background:#f5f3f1;
    align-items: flex-start;
  }

  .usr-carousel-news-container {
    padding: 16px 58px 8px;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;

    .usr-carousel-news-item {
      display: flex;
      width: 100%;
      padding-top: 14px;
      padding-bottom: 16px;

      .left {
        align-self: center;
        border: 1px solid #1f181c;
        width: 55px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .day {
          font-family: "Times New Roman", Times, serif;
          font-weight: bold;
          font-size: 32px;
        }

        .month {
          font-family: "Times New Roman", Times, serif;
          font-weight: bold;
          font-size: 16px;
        }
      }

      .right {
        margin-left: 28px;
        text-align: left;

        .title {
          font: normal normal bold 19px Arial;
          letter-spacing: 0.94px;
          color: #1f181c;
        }

        .content {
          font: normal normal normal 19px Arial;
          letter-spacing: 0.94px;
          color: #6e6567;
          text-align: justify;
        }
      }
    }

    .usr-carousel-news-item + .usr-carousel-news-item{
      border-top: 1px solid #d9d7d2;
    }
  }
}

.carousel__pagination {
  margin-top: 30px;
}

.usr-carousel-activity {
  width: 100%;

  .carousel__slide {
    padding: 4px 20px;
    align-items: stretch;
  }

  .carousel__prev,
  .carousel__next {
    box-sizing: content-box;
    background-color: #dcdad4;
    color: #9e9e9f;
    width: 45px;
    height: 45px;
    border-radius: 100%;

    .carousel__icon {
      width: 40px;
      height: 40px;
    }
  }

  .carousel__prev {
    left: auto;
    right: calc(100% - 8px);
  }

  .carousel__next {
    right: auto;
    left: calc(100% - 8px);
  }

  .usr-carousel-activity-container {
    box-shadow: 0px 0px 4px #b5b2abbf;

    img {
      display: block;
      width: 100%;
      height: 250px;
      object-fit: contain;
      object-position: center;
      background-color: black;
    }

    .usr-carousel-activity-card {
      padding: 20px;
      background: #ffffff;
      text-align: justify;

      .title {
        font: normal normal bold 20px/27px Arial;
        letter-spacing: 1px;
        color: #f67418;
      }

      .time {
        margin-top: 22px;
        font: normal normal normal 15px/25px Arial;
        letter-spacing: 0.75px;
        color: #1f191c;
      }

      .local {
        font: normal normal normal 15px/25px Arial;
        letter-spacing: 0.75px;
        color: #8a8281;
      }
    }

  }
}

.usr-carousel-video {
  width: 100%;

  .carousel__slide {
    padding: 4px 20px;
    height: 100%;
  }

  .carousel__prev,
  .carousel__next {
    box-sizing: content-box;
    background-color: #dcdad4;
    color: #6e6567;
    width: 45px;
    height: 45px;
    border-radius: 100%;

    .carousel__icon {
      width: 40px;
      height: 40px;
    }
  }

  .carousel__prev {
    left: auto;
    right: calc(100% - 10px);
  }

  .carousel__next {
    right: auto;
    left: calc(100% - 10px);
  }

  .usr-carousel-video-container {
    position: relative;
    width: 265px;

    iframe {
      height: 187px;
      width: 100%;
      pointer-events: none;
    }

    .usr-carousel-video-card {
      text-align: left;
      margin-top: 17px;

      .title {
        font: normal normal bold 18px/27px Arial;
        letter-spacing: 0.09px;
        color: #194316;
        border-left: 3px solid;
        padding-left: 12px;

      }

      .content {
        font: normal normal normal 14px/21px Arial;
        letter-spacing: 0px;
        color: #8A8280;
        padding-left: 15px;
      }
    }
  }
}

.e-news-carousel {
  .carousel__slide {
    align-self: flex-start;
    display: flex;
    flex-direction: column;
    align-items: stretch;

    .e-news {
      display: flex;

      .periods {
        flex-shrink: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 65px;
        height: 65px;
        border-radius: 50% 50% 0;
        background: transparent linear-gradient(90deg, #FF750A 0%, #FD6C08 18%, #F94A04 100%) 0% 0%;
        font: normal normal bold 19px/36px Arial;;
        letter-spacing: 0.95px;
        color: #ffffff;
      }

      a {
        width: calc(50% - 31px);
        display: flex;

        .title {
          width: calc(100% - 65px);
          line-height: 64px;
          margin-left: 13px;
          border-bottom: 1px solid #c3bfb9;
          font-family: Arial;
          font-size: 19px;
          letter-spacing: 0px;
          text-align: left;
          color: #1f181c;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }

      a:not(.periods) + a {
        margin-left: 62px;
      }
    }

    .e-news:nth-child(even) {
      .periods {
        background: transparent linear-gradient(90deg, #68B72D 0%, #367C28 100%) 0% 0% ;
      }
    }

    .e-news + .e-news {
      margin-top: 26px;
    }
  }
}

@media screen and (max-width: 992px) {
  .banner-carousel {
    .carousel__slide {
      height: 380px;
    }

    .carousel__prev, .carousel__next {
      width: 30px;
      height: 30px;

      svg {
        --vc-icn-width: 30px;
      }
    }

    .carousel__prev {
      left: 9px;
    }

    .carousel__next {
      right: 9px;
    }
  }

  .carousel__pagination-button::after {
    width: 23px;
    height: 6px;
  }

  .usr-carousel-news {
    .usr-carousel-news-container {
      padding: 5px 16px 5px;

      .usr-carousel-news-item {
        padding-top: 7px;
        padding-bottom: 8px;

        .left {
          flex-shrink: 0;
          border: 1px solid #1f181c;
          width: 30px;

          .day {
            font-size: 21px;
          }
          .month {
            font-size: 12px;
          }
        }

        .right {
          margin-left: 9px;

          .title {
            font: normal normal bold 12px Arial;
            letter-spacing: 0px;
          }

          .content {
            font: normal normal normal 12px Arial;
            letter-spacing: 0px;
          }
        }
      }
    }
  }

  .usr-carousel-activity {
    margin-left: 0px;
    width: 100%;
    padding: 0;

    .carousel__slide {
      padding-bottom: 4px;
    }

    .carousel__prev,
    .carousel__next {
      width: 30px;
      height: 30px;

      .carousel__icon {
        width: 28px;
        height: 28px;
      }
    }
    .carousel__prev {
      left: auto;
      right: calc(100% - 20px);
    }

    .carousel__next {
      left: calc(100% - 20px);
      right: auto;
    }

    .usr-carousel-activity-container {
      width: 265px;

      .usr-carousel-activity-card {
        padding: 20px;

        .title {
          font: normal normal bold 14px/19px Arial;
        }

        .time {
          margin-top: 22px;
          font: normal normal normal 12px/17px Arial;
        }

        .local {
          font: normal normal normal 12px/17px Arial;
        }
      }
    }
  }

  .usr-carousel-video {
    margin-left: 0px;
    height: 100%;

    .carousel__slide {
      padding: 0;
    }

    .carousel__prev,
    .carousel__next {
      width: 30px;
      height: 30px;

      .carousel__icon {
        width: 28px;
        height: 28px;
      }
    }
    .carousel__prev {
      left: auto;
      right: calc(100% - 20px);
    }

    .carousel__next {
      left: calc(100% - 20px);
      right: auto;
    }
    .usr-carousel-video-container {
      position: relative;
      width: 265px;

      iframe {
        width: 100%;
      }
    }
  }

  .e-news-carousel {
    .carousel__slide {
      .e-news {
        display: grid;
        grid-template-columns: 44px 1fr;
        grid-template-rows: 1fr 1fr;
        column-gap: 7px;

        .periods {
          grid-row: 1 / 3;
          width: 44px;
          height: 44px;
          font: normal normal bold 13px/24px Arial;
          letter-spacing: 0.64px;
        }

        a {
          display: block;
          width: auto;
          overflow: hidden;

          .title {
            display: block;
            align-items: center;
            font-size: 12px;
            line-height: 16px;
            width: 100%;
            margin-left: 0;
          }
        }

        a:not(.periods) + a {
          margin-left: 0;

          .title {
            border-bottom: none;
          }
        }
      }

      .e-news + .e-news {
        margin-top: 19px;
      }
    }
  }
}
</style>
