<template>
  <div class="content">
    <h1 :style="`font-size: var(--el-font-size-extra-large)`">關於我們內容管理</h1>
    <!-- Debug: {{state.editingData}} -->
    <El-Form ref="form" :model="state.editingData" label-width="120px"
      v-loading="state.editing"
    >
      <El-Form-Item label="Banner" v-if="state.isAdmin">
        <ImageUploader v-model="state.editingData.imgUrl" @upload="handleUpload" />
      </El-Form-Item>
      <El-Form-Item label="計畫歷年項目" v-if="state.isAdmin">
        <El-Card class="box-card" v-for="(item, index) in state.editingData.historyProjects" :key="index">
          <El-Row justify="end" style="font-size: var(--el-font-size-medium); margin-bottom: 10px">
            <El-Button
              circle
              @click="removeFromArray(state.editingData.historyProjects, item)"
            ><El-Icon><Close /></El-Icon></El-Button>
          </El-Row>
          <El-Form ref="form" :model="item" label-width="120px">
            <El-Form-Item label="年度(文字)">
              <El-Input v-model="item.title"></El-Input>
            </El-Form-Item>
            <El-Form-Item label="年度(數字)">
              <El-Input v-model="item.year"></El-Input>
            </El-Form-Item>
            <El-Form-Item label="計畫項目">
              <El-Card class="box-card" v-for="(item2, index) in item.items" :key="index">
                <El-Row justify="end" style="font-size: var(--el-font-size-medium); margin-bottom: 10px">
                  <El-Button
                    circle
                    @click="removeFromArray(item.items, item2)"
                  ><El-Icon><Close /></El-Icon></El-Button>
                </El-Row>
                <El-Form ref="form" :model="item2" label-width="80px">
                  <El-Form-Item label="類型">
                    <El-Input v-model="item2.type"></El-Input>
                  </El-Form-Item>
                  <El-Form-Item label="計畫名稱">
                    <El-Input v-model="item2.name"></El-Input>
                  </El-Form-Item>
                </El-Form>
              </El-Card>
            </El-Form-Item>

            <El-Row justify="center">
              <El-Button
                circle
                @click="addToArray(item.items, historyProjectItemTemplate, item.items.length)"
              ><El-Icon><Plus /></El-Icon></El-Button>
            </El-Row>
          </El-Form>
        </El-Card>
        <El-Row justify="center">
          <El-Button
            circle
            @click="addToArray(state.editingData.historyProjects, historyProjectTemplate, state.editingData.historyProjects.length)"
          ><El-Icon><Plus /></El-Icon></El-Button>
        </El-Row>
      </El-Form-Item>

      <El-Form-Item label="通識教育中心" v-if="state.isAdmin">
        <ImageUploader v-model="state.editingData.educationCenterImgUrl" @upload="handleUpload" />
      </El-Form-Item>
      <El-Form-Item label="通識教育中心網址" v-if="state.isAdmin">
        <El-Input v-model="state.editingData.educationCenterUrl"></El-Input>
      </El-Form-Item>

      <El-Form-Item label="社會實踐推動小組(電腦版)" v-if="state.isAdmin">
        <ImageUploader v-model="state.editingData.teamMemberImgUrl" @upload="handleUpload" />
      </El-Form-Item>
      <El-Form-Item label="社會實踐推動小組(手機版)" v-if="state.isAdmin">
        <ImageUploader v-model="state.editingData.teamMemberImgUrlMobile" @upload="handleUpload" />
      </El-Form-Item>

      <El-Form-Item label="計畫">
        <El-Card class="box-card" v-for="(item, index) in state.editingData.projects" :key="index">
          <El-Row justify="end" style="font-size: var(--el-font-size-medium); margin-bottom: 10px">
            <El-Button
              circle
              @click="removeFromArray(state.editingData.projects, item)"
            ><El-Icon><Close /></El-Icon></El-Button>
          </El-Row>
          <El-Form ref="form" :model="item" label-width="120px">
            <El-Form-Item label="計畫名稱">
              <El-Input v-model="item.name"></El-Input>
            </El-Form-Item>
            <El-Form-Item label="團隊人員">
              <draggable
                tag="div"
                :list="item.members"
                handle=".handle"
                item-key="id"
              >
                <template #item="{ element: member }">
                  <El-Card class="box-card">
                    <El-Row justify="space-between" align="middle" style="font-size: var(--el-font-size-medium); margin-bottom: 10px">
                      <El-Icon class="handle"><D-Caret /></El-Icon>
                      <El-Button
                        circle
                        @click="removeFromArray(item.members, member)"
                      ><El-Icon><Close /></El-Icon></El-Button>
                    </El-Row>
                    <El-Form ref="form" :model="member" label-width="80px">
                      <El-Form-Item label="照片">
                        <ImageUploader v-model="member.url" @upload="handleUpload" />
                      </El-Form-Item>
                      <El-Form-Item label="類型">
                        <El-Input v-model="member.type"></El-Input>
                      </El-Form-Item>
                      <El-Form-Item label="名稱">
                        <El-Input v-model="member.name"></El-Input>
                      </El-Form-Item>
                      <El-Form-Item label="職稱">
                        <El-Input v-model="member.job"></El-Input>
                      </El-Form-Item>
                      <El-Form-Item label="學校">
                        <El-Input v-model="member.school"></El-Input>
                      </El-Form-Item>
                      <El-Form-Item label="科系">
                        <El-Input v-model="member.department"></El-Input>
                      </El-Form-Item>
                    </El-Form>
                  </El-Card>
                </template>
              </draggable>
            </El-Form-Item>

            <El-Row justify="center">
              <El-Button
                circle
                @click="addToArray(item.members, aboutProjectMemberTemplate, item.members.length)"
              ><El-Icon><Plus /></El-Icon></El-Button>
            </El-Row>
          </El-Form>
        </El-Card>
        <El-Row justify="center">
          <El-Button
            circle
            @click="addToArray(state.editingData.projects, aboutProjectTemplate, state.editingData.projects.length)"
          ><El-Icon><Plus /></El-Icon></El-Button>
        </El-Row>
      </El-Form-Item>
    </El-Form>
    <El-Row justify="center">
      <El-Button-Group>
        <El-Button type="info" @click="cancel">Cancel</El-Button>
        <El-Button type="primary" @click="save">Save</El-Button>
        <El-Button type="warning" @click="preview">Preview</El-Button>
      </El-Button-Group>
    </El-Row>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive, watch } from 'vue'
import ManagementLayout from '@/layout/ManagementLayout.vue'
import { About, AboutProject, AboutProjectMember, HistoryProject, HistoryProjectItem, usrState, usrPreviewState, updateResource } from '@/model'
import { Close, Plus, DCaret } from '@element-plus/icons'
import { addToArray, isAuthUserAdmin, removeFromArray } from '@/utils'
import api from '@/api'
import { ElLoading } from 'element-plus'
import ImageUploader from '@/components/ImageUploader.vue'
import { v4 as uuidv4 } from 'uuid'
import draggable from 'vuedraggable'

export default defineComponent({
  name: 'ManageAboutPage',
  components: {
    Close,
    Plus,
    ImageUploader,
    DCaret,
    draggable,
  },
  created() {
    this.$emit('update:layout', ManagementLayout)
  },
  setup() {
    const editingData = getEditingData()

    function getEditingData(): About {
      return JSON.parse(JSON.stringify(usrState.about))
    }

    const state = reactive({
      isAdmin: false,
      editing: false,
      editingData,
    })

    isAuthUserAdmin().then(isAdmin => {
      state.isAdmin = isAdmin
    })

    watch(() => usrState.about, () => {
      console.log('updated!')
      state.editingData = getEditingData()
    })

    const historyProjectTemplate: HistoryProject = { title: '', year: '', items: [] }
    const historyProjectItemTemplate: HistoryProjectItem = { type: '', name: '' }
    const aboutProjectTemplate: AboutProject = { name: '', members: [] }
    const aboutProjectMemberTemplate: AboutProjectMember = {
      id: uuidv4(),
      url: '',
      type: '',
      name: '',
      job: '',
      school: '',
      department: '',
    }

    const cancel = () => {
      state.editingData = getEditingData()
    }

    const save = () => {
      const loading = ElLoading.service({
        lock: true,
        text: 'Loading',
        background: 'rgba(0, 0, 0, 0.7)',
      })

      api.updateAbout(state.editingData)
        .then(() => updateResource())
        .then(() => loading.close())
        .catch(() => loading.close())
    }

    const preview = () => {
      usrPreviewState.about = JSON.parse(JSON.stringify(state.editingData))
      localStorage.setItem('resourcesPreview', JSON.stringify(usrPreviewState))
      window.open('/aboutPreview', '_blank')
    }

    const handleUpload = (promise: Promise<void>) => {
      const loading = ElLoading.service({
        lock: true,
        text: 'Loading',
        background: 'rgba(0, 0, 0, 0.7)',
      })
      promise
        .then(() => loading.close())
        .catch(() => loading.close())
    }

    return {
      state,
      historyProjectTemplate,
      historyProjectItemTemplate,
      aboutProjectTemplate,
      aboutProjectMemberTemplate,
      addToArray,
      removeFromArray,
      cancel,
      save,
      preview,
      handleUpload,
    }
  },
})
</script>
<style lang="scss">
  .mt-10 {
    margin-top: 10px;
  }

  .el-card + * {
    margin-top: 10px
  }

  .el-form-item + * {
    margin-top: 10px;
  }
</style>
