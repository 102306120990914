import axios from 'axios'
import { API_HOST } from '@/config'
import { About, Activity, Highlight, Home, News, Project, ProjectHome, USRComponentType, USRState } from '@/model'
import { v4 as uuidv4 } from 'uuid'

export const uploadFile = async (file: File): Promise<string> => {
  const extensionName = file.name.split('.').at(-1)

  return axios.post(`${API_HOST}/getSignedUrl`, { extensionName }).then(async ({ data: { url, domainName } }) => {
    console.log({ domainName, url })
    return axios.put(url, file).then(() => url
      .split('?')[0]
      .replace(/(https:\/\/)([^/]*)(.*)/g, `$1${domainName}$3`)
    )
  })
}

type UniqueItem = { id: string }
export const preventItemIdEmpty = <T extends UniqueItem>({ id = uuidv4(), ...component }: T): T => ({ id, ...component }) as T
export const preventItemsIdEmpty = <T extends UniqueItem>(components: T[] = []): T[] => components.map(preventItemIdEmpty)

export const getResource = async (): Promise<USRState> => {
  return axios.get(`${API_HOST}/resources`).then(({ data: resource }: { data: USRState }) => ({
    // parse date, default components id
    ...resource,
    home: {
      ...resource.home,
      bannerImages: resource.home.bannerImages || [
        { imgSrc: '/img/home/usr-bn_插圖.jpg', imgSrcMobile: '/img/home/usr-bn_插圖.jpg' },
        { imgSrc: '/img/home/usr-bn_成果照片.jpg', imgSrcMobile: '/img/home/usr-bn_成果照片.jpg' }
      ],
      news: resource.home.news.map(({ time, ...news }) => ({ time: new Date(time), ...news })),
      videos: resource.home.videos,
    },
    activities: resource.activities.map(({ timestamp, components, ...activity }) => ({
      timestamp: new Date(timestamp),
      components: preventItemsIdEmpty(components),
      ...activity
    })),
    news: resource.news.map(({ time, components, componentsEn, ...news }) => ({
      time: new Date(time),
      components: preventItemsIdEmpty(components),
      componentsEn: preventItemsIdEmpty(componentsEn),
      ...news,
    })),
    about: {
      ...resource.about,
      educationCenterImgUrl: resource.about.educationCenterImgUrl || '/img/about/course@2x.png',
      educationCenterUrl: resource.about.educationCenterUrl || 'https://cla.ntust.edu.tw/p/412-1076-8604.php?Lang=zh',
      teamMemberImgUrl: resource.about.teamMemberImgUrl || '/img/about/team@2x.png',
      teamMemberImgUrlMobile: resource.about.teamMemberImgUrlMobile || '/img/about/team-m@2x.png',
      projects: resource.about.projects.map(({ members, ...project }) => ({
        ...project,
        members: preventItemsIdEmpty(members),
      }))
    },
    projects: resource.projects
      .map(project => ({
        ...project,
        order: project.order || 0,
        sessionNo: project.sessionNo || 2,
        sessionName: project.sessionName || '第二期 (109-111年)',
      }))
      .sort((projectA, projectB) => {
        if (projectA.sessionNo !== projectB.sessionNo) {
          return (projectA.sessionNo) - (projectB.sessionNo)
        }
        return projectA.order - projectB.order
      })
      .map(({ components, ...project }, index) => ({
        components: preventItemsIdEmpty(components),
        ...project,
        order: index,
      })),
    history: {
      ...resource.history,
      reports: resource.history.reports
        .map(({ time, ...report }) => ({ time: new Date(time), ...report }))
        .sort((reportA, reportB) => reportB.time.getTime() - reportA.time.getTime()),
      files: resource.history.files.map(({ time, ...file }) => ({ time: new Date(time), ...file })),
    },
    highlights: resource.highlights
      .sort((highlightA, highlightB) => (highlightA.order || 0) - (highlightB.order || 0))
      .map(({ time, components, ...highlight }, index) => ({
        time: new Date(time),
        components: preventItemsIdEmpty(components),
        ...highlight,
        order: index,
      })),
  }))
}

export const updateAbout = async (payload: About): Promise<any> => {
  return axios.post(`${API_HOST}/resources`, {
    resource: 'about',
    payload,
  })
}

export const updateNews = async (payload: News): Promise<any> => {
  return axios.post(`${API_HOST}/resources`, {
    resource: 'news',
    id: payload.id,
    payload,
  })
}

export const updateActivity = async (payload: Activity): Promise<any> => {
  return axios.post(`${API_HOST}/resources`, {
    resource: 'activities',
    id: payload.id,
    payload,
  })
}

export const updateProjectHome = async (payload: ProjectHome): Promise<any> => {
  return axios.post(`${API_HOST}/resources`, {
    resource: 'projectHome',
    payload,
  })
}

export const updateProject = async (payload: Project): Promise<any> => {
  return axios.post(`${API_HOST}/resources`, {
    resource: 'projects',
    id: payload.id,
    payload,
  })
}

export const updateHistory = async (payload: History): Promise<any> => {
  return axios.post(`${API_HOST}/resources`, {
    resource: 'history',
    payload,
  })
}

export const updateHighlight = async (payload: Highlight): Promise<any> => {
  return axios.post(`${API_HOST}/resources`, {
    resource: 'highlights',
    id: payload.id,
    payload,
  })
}

export const updateHome = async (payload: Home): Promise<any> => {
  return axios.post(`${API_HOST}/resources`, {
    resource: 'home',
    payload,
  })
}

export const deleteActivity = async (id: number): Promise<any> => {
  return axios.delete(`${API_HOST}/resources?resource=activities&id=${id}`)
}

export const deleteNews = async (id: number): Promise<any> => {
  return axios.delete(`${API_HOST}/resources?resource=news&id=${id}`)
}

export const deleteProject = async (id: number): Promise<any> => {
  return axios.delete(`${API_HOST}/resources?resource=projects&id=${id}`)
}

export const deleteHighlight = async (id: number): Promise<any> => {
  return axios.delete(`${API_HOST}/resources?resource=highlights&id=${id}`)
}

export default {
  uploadFile,
  getResource,
  updateAbout,
  updateNews,
  updateActivity,
  updateProjectHome,
  updateProject,
  updateHistory,
  updateHighlight,
  updateHome,
  deleteActivity,
  deleteNews,
  deleteProject,
  deleteHighlight,
}
