<template>
  <div class="usr-title-2">{{title}}</div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Title2',
  props: {
    title: {
      type: String,
      required: true,
    },
  },
  setup() {
  },
})
</script>

<style lang="scss">
.usr-title-2 {
  position: relative;
  letter-spacing: 1.65px;
  color: #603A19;
  font-size: 33px;
  font-weight: bold;
  line-height: 40px;
  font-family: Arial;

  &:before {
    content: '';
    width: 45px;
    height: 45px;
    position: absolute;
    right: calc(100% + 12px);
    background: url('../assets/images/title-icon.svg') no-repeat;
    background-size: 45px 45px;
  }
}

@media screen and (max-width: 991px) {
  .usr-title-2 {
    display: flex;
    align-items: center;
    letter-spacing: 0.7px;
    font-size: 14px;
    line-height: 16.8px;

    &:before {
      content: '';
      position: static;
      width: 17px;
      height: 17px;
      margin-right: 7px;
      background-size: 17px 17px;
    }
  }
}
</style>
