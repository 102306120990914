import api from '@/api'
import { v4 as uuidv4 } from 'uuid'
import { ComputedRef, computed, reactive } from 'vue'
import { useRoute } from 'vue-router'

export interface HomeNews {
  time: Date;
  type: string;
  title: string;
  url: string;
}

export interface HomeVideo {
  ytUrl: string;
  title: string;
  text: string;
}

export interface Home {
  imgUrl: string; // deprecated
  bannerImages: {
    imgSrc: string;
    imgSrcMobile: string;
  }[];
  newsTitle: string;
  newsTitleEn: string;
  news: HomeNews[];
  activityTitle: string;
  activityTitleEn: string;
  videoTitle: string;
  videoTitleEn: string;
  videos: HomeVideo[];
  enewsTitle: string;
  enewsTitleEn: string;
}

export interface BaseUSRComponent {
  id: string; // uuid
}

export interface BannerData extends BaseUSRComponent {
  type: 'Banner',
  data: {
    url: string;
  }
}

export interface BreadcrumbData extends BaseUSRComponent {
  type: 'Breadcrumb',
  data: {
    url1: string;
    text1: string;
    text2?: string;
  }
}

export interface Title1Data extends BaseUSRComponent {
  type: 'Title1',
  data: {
    title: string;
    subtitle: string;
  }
}

export interface Title2Data extends BaseUSRComponent {
  type: 'Title2',
  data: {
    title: string;
  }
}

export interface Title3Data extends BaseUSRComponent {
  type: 'Title3',
  data: {
    title: string;
  }
}

export interface Title4Data extends BaseUSRComponent {
  type: 'Title4',
  data: {
    title: string;
  }
}

export interface Text1Data extends BaseUSRComponent {
  type: 'Text1',
  data: {
    text: string;
  }
}

export interface Text2Data extends BaseUSRComponent {
  type: 'Text2';
  data: {
    text: string;
  }
}

export interface List1Item {
  text: string;
}

export interface List1Data extends BaseUSRComponent {
  type: 'List1',
  data: {
    list: List1Item[];
  }
}

export interface CaptionedImageData extends BaseUSRComponent {
  type: 'CaptionedImage',
  data: {
    url: string;
    text?: string;
  }
}

export interface CaptionedPairImageData extends BaseUSRComponent {
  type: 'CaptionedPairImage',
  data: {
    url1: string;
    text1?: string;
    url2: string;
    text2?: string;
  }
}

export interface RowData extends BaseUSRComponent {
  type: 'Row',
  data: {
    components: (Text1Data | CaptionedImageData)[];
  }
}

export interface Link1Data extends BaseUSRComponent {
  type: 'Link1',
  data: {
    title: string;
    url: string;
  }
}

export interface Carousel1Slide {
  imgUrl: string;
  title: string;
  content: string;
}

export interface Carousel1Data extends BaseUSRComponent {
  type: 'Carousel1',
  data: {
    slides: Carousel1Slide[];
  }
}

export interface List2Item {
  imgSrc: string;
  title: string;
  text: string;
}

export interface List2Data extends BaseUSRComponent {
  type: 'List2',
  data: {
    list: List2Item[];
  }
}

export interface Table1ItemItem {
  type: string;
  name: string;
}

export interface Table1Item {
  title: string;
  year: string;
  items: Table1ItemItem[]
}

export interface Table1Data extends BaseUSRComponent {
  type: 'Table1',
  data: {
    data: Table1Item[];
  }
}

export type USRComponentType = BannerData
| BreadcrumbData
| Title1Data
| Title2Data
| Title3Data
| Title4Data
| Text1Data
| Text2Data
| List1Data
| CaptionedImageData
| CaptionedPairImageData
| RowData
| Link1Data
| Carousel1Data
| List2Data
| Table1Data

export interface Activity {
  id: number;
  timestamp: Date;
  imgUrl: string;
  title: string;
  local: string;
  time: string;
  dmList: {
    imgSrc: string;
  }[];
  components: USRComponentType[];
}

export interface News {
  id: number;
  time: Date;
  imgUrl: string;
  periods: string;
  periodsEn: string;
  title: string;
  titleEn: string;
  components: USRComponentType[];
  componentsEn: USRComponentType[];
  fbUrl: string;
  ytUrl: string;
  igUrl: string;
  webUrl: string;
}

export interface HistoryProjectItem {
  type: string;
  name: string;
}

export interface HistoryProject {
  title: string,
  year: string,
  items: HistoryProjectItem[];
}

export interface AboutProjectMember {
  id: string;
  url: string;
  type: string;
  name: string;
  job: string;
  school: string;
  department: string;
}

export interface AboutProject {
  name: string;
  members: AboutProjectMember[];
}

export interface About {
  imgUrl: string
  historyProjects: HistoryProject[];
  educationCenterImgUrl: string;
  educationCenterUrl: string;
  teamMemberImgUrl: string;
  teamMemberImgUrlMobile: string;
  projects: AboutProject[];
}

export interface ProjectHome {
  imgUrl: string;
}

export interface Session {
  sessionNo: number;
  sessionName: string
}

export interface Project extends Session {
  id: number;
  image: string
  title: string;
  text: string;
  fbUrl: string;
  ytUrl: string;
  igUrl: string;
  webUrl: string;
  managerEmail: string;
  components: USRComponentType[];
  order: number;
}

export interface HistoryReport {
  time: Date;
  type: string;
  title: string;
  url: string;
}

export interface HistoryFile {
  title: string;
  url: string;
  time: Date;
}

export interface History {
  imgUrl: string;
  reports: HistoryReport[];
  files: HistoryFile[];
}

export interface Highlight {
  id: number;
  time: Date;
  imgSrc: string;
  title: string;
  text: string;
  components: USRComponentType[];
  order: number;
}

export interface USRState {
  home: Home;
  activities: Activity[];
  news: News[];
  about: About;
  projectHome: ProjectHome;
  projects: Project[];
  history: History;
  highlights: Highlight[]
}

export interface USRPreviewState {
  home: Home;
  activity: Activity;
  news: News;
  about: About;
  projectHome: ProjectHome;
  project: Project;
  history: History;
  highlight: Highlight;
}

export const usrState = reactive({
  home: {
    imgUrl: '/img/home/banner@2x.png',
    bannerImages: [
      { imgSrc: '/img/home/usr-bn_插圖.jpg', imgSrcMobile: '/img/home/usr-bn_插圖.jpg' },
      { imgSrc: '/img/home/usr-bn_成果照片.jpg', imgSrcMobile: '/img/home/usr-bn_成果照片.jpg' }
    ],
    newsTitle: '最新公告',
    newsTitleEn: 'WHAT’S NEWS',
    activityTitle: '近期活動',
    activityTitleEn: 'ACTIVITYS',
    news: [ // sort in backend
      {
        time: new Date(2021, 10, 8),
        type: '公告',
        title: '110年USR-hub「陶瓷文化培力•鶯歌地方創生」人才培力活動:玲瓏吹砂透光瓷工作坊',
        url: 'https://www.google.com',
      },
      {
        time: new Date(2021, 10, 5),
        type: '公告',
        title: '110年USR-hub「陶瓷文化培力•鶯歌地方創生」人才培力活動:玲瓏吹砂透光瓷工作坊',
        url: 'https://www.google.com',
      },
      {
        time: new Date(2021, 10, 4),
        type: '公告',
        title: '110年USR-hub「陶瓷文化培力•鶯歌地方創生」人才培力活動:玲瓏吹砂透光瓷工作坊',
        url: 'https://www.google.com',
      },
      {
        time: new Date(2021, 10, 1),
        type: '公告',
        title: '110年USR-hub「陶瓷文化培力•鶯歌地方創生」人才培力活動:玲瓏吹砂透光瓷工作坊',
        url: 'https://www.google.com',
      },
      {
        time: new Date(2021, 9, 11),
        type: '公告',
        title: '110年USR-hub「陶瓷文化培力•鶯歌地方創生」人才培力活動:玲瓏吹砂透光瓷工作坊',
        url: 'https://www.google.com',
      },
      {
        time: new Date(2021, 8, 6),
        type: '公告',
        title: '110年USR-hub「陶瓷文化培力•鶯歌地方創生」人才培力活動:玲瓏吹砂透光瓷工作坊',
        url: 'https://www.google.com',
      },
      {
        time: new Date(2021, 5, 8),
        type: '公告',
        title: '110年USR-hub「陶瓷文化培力•鶯歌地方創生」人才培力活動:玲瓏吹砂透光瓷工作坊',
        url: 'https://www.google.com',
      },
      {
        time: new Date(2021, 5, 6),
        type: '公告',
        title: '110年USR-hub「陶瓷文化培力•鶯歌地方創生」人才培力活動:玲瓏吹砂透光瓷工作坊',
        url: 'https://www.google.com',
      },
    ],
    videoTitle: '影音資訊',
    videoTitleEn: 'INFORMATION VIDEOS',
    videos: [
      {
        ytUrl: 'https://www.youtube.com/watch?v=h16ZVe6K-XE',
        title: '1共好2.0：走在我的身邊，成為我的夥伴',
        text: '共好2.0 小林夜祭'
      },
      {
        ytUrl: 'https://www.youtube.com/watch?v=h16ZVe6K-XE',
        title: '2共好2.0：走在我的身邊，成為我的夥伴',
        text: '共好2.0 小林夜祭'
      },
      {
        ytUrl: 'https://www.youtube.com/watch?v=h16ZVe6K-XE',
        title: '3共好2.0：走在我的身邊，成為我的夥伴',
        text: '共好2.0 小林夜祭'
      },
      {
        ytUrl: 'https://www.youtube.com/watch?v=h16ZVe6K-XE',
        title: '4共好2.0：走在我的身邊，成為我的夥伴',
        text: '共好2.0 小林夜祭'
      },
      {
        ytUrl: 'https://www.youtube.com/watch?v=h16ZVe6K-XE',
        title: '5共好2.0：走在我的身邊，成為我的夥伴',
        text: '共好2.0 小林夜祭'
      },
      {
        ytUrl: 'https://www.youtube.com/watch?v=h16ZVe6K-XE',
        title: '5共好2.0：走在我的身邊，成為我的夥伴',
        text: '共好2.0 小林夜祭'
      },
    ],
    enewsTitle: '電子報',
    enewsTitleEn: 'E-NEWSLETTER',
  },
  activities: [ // sort in backend
    {
      id: 1,
      timestamp: new Date(),
      imgUrl: '/img/home/Group8164@2x.png',
      title: '議題交流系列活動議題交流系列 活動議題交流系列活動議題交流 系列活動議題交流系列活動活動',
      local: '臺灣科技大學國際大樓IB-308教室教室室',
      time: '2020.08.31(二)12:20-13:10 ',
      dmList: [
        {
          imgSrc: '/img/activity/activity-download@2x.png',
        },
        {
          imgSrc: '/img/activity/activity-download@2x.png',
        },
      ],
      components: [
        { type: 'Text1', data: { text: '為能落實大學社會責任計畫，藉由跨校跨計畫的社群交流，增進校園對創新教學投入社會實踐及地方創生的推動能量，本年度與USR推動中心共同主辦110年SIG議題交流系列活動-第一場次【大學x部落 - 啟動永續原動力】，此次SIG議題交流活動，將聚焦於大學專業與原住民部落的合作，協力推動原住民族永續發展，活動將邀請執行原鄉議題的大學團隊，針對課程的設計與場域夥伴的合作模式，進行經驗分享，促進學習與對話，形成大學與地方互動的有機學習生態！' } },
        { type: 'Title2', data: { title: '/活動資訊/' } },
        { type: 'List1', data: { list: [{ text: '指導單位：教育部' }, { text: '主辦單位：國立臺灣科技大學' }, { text: '活動時間：2020.08.31(二)12:20-13:10' }, { text: '活動地點：臺灣科技大學國際大樓IB-308教室' }, { text: '參加對象：全國大專校院 聯絡窗口：OOO小姐' }] } },
        { type: 'Title2', data: { title: '/報名方式/' } },
        { type: 'List1', data: { list: [{ text: '即日起至08/31 (二) 17:00截止' }, { text: '報名網址：https://reurl.cc/MAd8e4' }] } },
        { type: 'Title2', data: { title: '/活動DM/' } },
      ] as USRComponentType[],
    },
    {
      id: 2,
      timestamp: new Date(),
      imgUrl: '/img/home/Group8164@2x.png',
      title: '議題交流系列活動議題交流系列 活動議題交流系列活動議題交流 系列活動議題交流系列活動活動',
      local: '臺灣科技大學國際大樓IB-308教室教室室',
      time: '2020.08.31(二)12:20-13:10 ',
      dmList: [
        {
          imgSrc: '/img/activity/activity-download@2x.png',
        },
      ],
      components: [
        { type: 'Text1', data: { text: '為能落實大學社會責任計畫，藉由跨校跨計畫的社群交流，增進校園對創新教學投入社會實踐及地方創生的推動能量，本年度與USR推動中心共同主辦110年SIG議題交流系列活動-第一場次【大學x部落 - 啟動永續原動力】，此次SIG議題交流活動，將聚焦於大學專業與原住民部落的合作，協力推動原住民族永續發展，活動將邀請執行原鄉議題的大學團隊，針對課程的設計與場域夥伴的合作模式，進行經驗分享，促進學習與對話，形成大學與地方互動的有機學習生態！' } },
        { type: 'Title2', data: { title: '/活動資訊/' } },
        { type: 'List1', data: { list: [{ text: '指導單位：教育部' }, { text: '主辦單位：國立臺灣科技大學' }, { text: '活動時間：2020.08.31(二)12:20-13:10' }, { text: '活動地點：臺灣科技大學國際大樓IB-308教室' }, { text: '參加對象：全國大專校院 聯絡窗口：OOO小姐' }] } },
        { type: 'Title2', data: { title: '/報名方式/' } },
        { type: 'List1', data: { list: [{ text: '即日起至08/31 (二) 17:00截止' }, { text: '報名網址：https://reurl.cc/MAd8e4' }] } },
        { type: 'Title2', data: { title: '/活動DM/' } },
      ] as USRComponentType[],
    },
    {
      id: 3,
      timestamp: new Date(),
      imgUrl: '/img/home/Group8164@2x.png',
      title: '議題交流系列活動議題交流系列 活動議題交流系列活動議題交流 系列活動議題交流系列活動活動',
      local: '臺灣科技大學國際大樓IB-308教室教室室',
      time: '2020.08.31(二)12:20-13:10 ',
      dmList: [
        {
          imgSrc: '/img/activity/activity-download@2x.png',
        },
        {
          imgSrc: '/img/activity/activity-download@2x.png',
        },
      ],
      components: [
        { type: 'Text1', data: { text: '為能落實大學社會責任計畫，藉由跨校跨計畫的社群交流，增進校園對創新教學投入社會實踐及地方創生的推動能量，本年度與USR推動中心共同主辦110年SIG議題交流系列活動-第一場次【大學x部落 - 啟動永續原動力】，此次SIG議題交流活動，將聚焦於大學專業與原住民部落的合作，協力推動原住民族永續發展，活動將邀請執行原鄉議題的大學團隊，針對課程的設計與場域夥伴的合作模式，進行經驗分享，促進學習與對話，形成大學與地方互動的有機學習生態！' } },
        { type: 'Title2', data: { title: '/活動資訊/' } },
        { type: 'List1', data: { list: [{ text: '指導單位：教育部' }, { text: '主辦單位：國立臺灣科技大學' }, { text: '活動時間：2020.08.31(二)12:20-13:10' }, { text: '活動地點：臺灣科技大學國際大樓IB-308教室' }, { text: '參加對象：全國大專校院 聯絡窗口：OOO小姐' }] } },
        { type: 'Title2', data: { title: '/報名方式/' } },
        { type: 'List1', data: { list: [{ text: '即日起至08/31 (二) 17:00截止' }, { text: '報名網址：https://reurl.cc/MAd8e4' }] } },
        { type: 'Title2', data: { title: '/活動DM/' } },
      ] as USRComponentType[],
    },
    {
      id: 4,
      timestamp: new Date(),
      imgUrl: '/img/home/Group8164@2x.png',
      title: '議題交流系列活動議題交流系列 活動議題交流系列活動議題交流 系列活動議題交流系列活動活動',
      local: '臺灣科技大學國際大樓IB-308教室教室室',
      time: '2020.08.31(二)12:20-13:10 ',
      dmList: [
        {
          imgSrc: '/img/activity/activity-download@2x.png',
        },
        {
          imgSrc: '/img/activity/activity-download@2x.png',
        },
      ],
      components: [
        { type: 'Text1', data: { text: '為能落實大學社會責任計畫，藉由跨校跨計畫的社群交流，增進校園對創新教學投入社會實踐及地方創生的推動能量，本年度與USR推動中心共同主辦110年SIG議題交流系列活動-第一場次【大學x部落 - 啟動永續原動力】，此次SIG議題交流活動，將聚焦於大學專業與原住民部落的合作，協力推動原住民族永續發展，活動將邀請執行原鄉議題的大學團隊，針對課程的設計與場域夥伴的合作模式，進行經驗分享，促進學習與對話，形成大學與地方互動的有機學習生態！' } },
        { type: 'Title2', data: { title: '/活動資訊/' } },
        { type: 'List1', data: { list: [{ text: '指導單位：教育部' }, { text: '主辦單位：國立臺灣科技大學' }, { text: '活動時間：2020.08.31(二)12:20-13:10' }, { text: '活動地點：臺灣科技大學國際大樓IB-308教室' }, { text: '參加對象：全國大專校院 聯絡窗口：OOO小姐' }] } },
        { type: 'Title2', data: { title: '/報名方式/' } },
        { type: 'List1', data: { list: [{ text: '即日起至08/31 (二) 17:00截止' }, { text: '報名網址：https://reurl.cc/MAd8e4' }] } },
        { type: 'Title2', data: { title: '/活動DM/' } },
      ] as USRComponentType[],
    },
  ],
  news: [ // sort in backend
    {
      id: 8,
      time: new Date(),
      imgUrl: '/img/image1@2x.png',
      periods: '第八期',
      periodsEn: 'No.8',
      title: '共好2.0 : 走在我的身邊，成為我的夥伴',
      titleEn: 'NTUST X Project LETS Go!',
      components: [
        { id: uuidv4(), type: 'Title3', data: { title: '培育富有溫度、具備關懷與溝通力的綠色行動工程師' } },
        { id: uuidv4(), type: 'Text1', data: { text: '不知道你有沒有想過，需要多少的力量才足以改變你眼中的世界呢？答案或許有點出乎你的意料，因為你其實只需要幾個志同道合的朋友，抑或只要你願意挺身而出，便會有一群和你有著一樣信念的人們響應你的號召，而世界也會因此而發生改變，正所謂「德不孤，必有鄰」，行動工程師的團隊便是在這樣的因緣際會下產生的。正當我們常因閱讀或聆聽到「無國界醫生」的故事而感動時，本校劉志成副校長（時任工程學院院長）的一個「無國界工程師」點子，幾位學生與幾位教師，便開始讓世界的某個角落，慢慢開始發生改變。今天，就讓我們來一探究竟這群「行動工程師」的故事吧！' } },
        { id: uuidv4(), type: 'Text1', data: { text: '臺科大「行動工程師培育計畫」在早期尚未獲教育補助時，便已開始執行，至今已培育逾百名之計畫學員，臺科大在這國際連結之USR計畫中，依循聯合國永續發展第11項指標：「促使城市與人類居住聚落具包容、安全、韌性及永續性」，由參與之國際師生團隊透過實作改善社區基礎設施，培育新一代優質工程師，進而達到「居住永續安全、學校永續教育、社區永續發展」目標。而今年主要包括兩項規劃重點：首先是延續前期「行動工程師」的計畫精神，繼續深耕印尼及越南等新南向國家的偏鄉，改善當地的飲用水水質、環境衛生、教學資源及加強環境教育等。另一項重點則是本土偏鄉社區的災害預防與創生活化，這部分包括：邊坡移動災害預警設備建置、防災與環境教育宣導、社區文化資產（瞭望台、古道）修復與創生、生態池與魚菜共生系統建構等。臺科大素來以精湛的工程技術及極高的國際化程度聞名，行動工程師培育計畫希望學生透過這項的學習模式，訓練出善於傾聽、誠於對話、富有溫度與國際觀的工程師。' } },
        { id: uuidv4(), type: 'CaptionedImage', data: { url: '/img/image2@2x.png', text: '行動工程師之師生於印尼偏鄉社區設立灌溉系統' } },
        { id: uuidv4(), type: 'Title3', data: { title: '貼近當地需求，一起發現與解決問題，建立深厚夥伴朋友情誼' } },
        { id: uuidv4(), type: 'Text1', data: { text: '參與計畫的學生在過程中學習如何包容異文化、貼近不同文化生活、培養世界觀，也更加了解臺灣這片土地，最重要的是能將大學中所學到的知識和專業能力應用在生活中，甚至是到國外與他人交流，讓大學生學習到書本上學不到的實務應用經驗，希望能夠藉由大學生所學賦予社區更多不同的面貌。透過與部落人士共同舉辦歡迎開幕式，讓部落居民更加認識這群大學生工程師，與在地居民一同為部落努力並且對部落產生認同感，不預設自己是來服務部落，而是來認識不同生活背景的部落，更學習把場域部落當成自己的家園，讓學生在協助解決當地環境問題的過程中，能更貼近在地需求。透過多次與居民訪談、不斷的與居民溝通了解他們的需求，與夥伴們一同找到解法，也因此這群行動工程師與場域夥伴不只是單純的夥伴關係，更像是一起努力的朋友，除完成與場域居民討論後的相關施作，更留下學員與居民間寶貴的互動情誼。每次到部落，居民夥伴們總是熱情的迎接我們，部落小孩開心歡迎這些大哥哥大姊姊的到來，在離別的時候更是依依不捨，希望可以再次回到部落。' } },
        {
          id: uuidv4(),
          type: 'Row',
          data: {
            components: [
              { id: uuidv4(), type: 'CaptionedImage', data: { url: '/img/image2@2x.png', text: '行動工程師之師生於印尼偏鄉社區設立灌溉系統' } },
              { id: uuidv4(), type: 'CaptionedImage', data: { url: '/img/image2@2x.png', text: '行動工程師之師生於印尼偏鄉社區設立灌溉系統' } },
            ]
          }
        },
        { id: uuidv4(), type: 'Title3', data: { title: '自動化坡地監測設備，提升部落環境安全與居民自主防災體認' } },
        {
          id: uuidv4(),
          type: 'Row',
          data: {
            components: [
              { id: uuidv4(), type: 'Text1', data: { text: '行動工程師之師生訪談宜蘭四季國小校長及部落耆老後，發現部落對於山地環境的災害特徵變化，如擋土設施位移、地表開裂、地下水位上升等，皆感到憂心。而四季部落位於通往著名的觀光景點嘉羅湖四季林道上，一整年遊客不斷，部落也希望能藉由打造地方特色市集提供遊客更多認識部落的機會，因此環境安全顯得更加重要。行動工程師希望藉由設置自動化坡地監測設備，進行雨量、地下水位、地表變化等監測，將數據上傳至防災資料雲端平台，一方面做為提升部落居民自主防災的體認，二方面可以作為環境教育課程教材，以落實永續防災的概念，同時使學童及居民了解邊坡災害威脅，並提高災害徵兆的辨識能力。' } },
              { id: uuidv4(), type: 'CaptionedImage', data: { url: '/img/image2@2x.png', text: '行動工程師之師生於印尼偏鄉社區設立灌溉系統' } },
            ]
          }
        },
        { id: uuidv4(), type: 'Title3', data: { title: '舉辦互動式防災小學堂，進行防災深耕，帶動全校「豐」學習' } },
        { id: uuidv4(), type: 'Text1', data: { text: '為提升部落自主防災能力，行動工程師以「防災教育」與「校園永續」兩大核心為主軸，藉由知識整合、互助合作與尊重之防災營隊，舉辦防災小學堂活動，傳遞部落孩童相關知識，安排「手作自製簡易雨量量測筒」活動，透過遊戲 DIY 體驗量測原理，讓孩童自製雨水量測設備，並在家中簡易量測雨量及雨水酸鹼質檢測，認識酸雨與大氣環境。並且舉辦防災系列講座，透過互動課程傳遞防災相關知識予居民，並組織自主防災任務編組，進行防災演練，達到平時巡檢、災前疏散、災中應變以及災後復原的自救能力，增強防災深度廣度，有效助於部落實施自主防災。' } },
        { id: uuidv4(), type: 'Title3', data: { title: '滿足部落用水需求，加值永續衛生健康' } },
        {
          id: uuidv4(),
          type: 'Row',
          data: {
            components: [
              { id: uuidv4(), type: 'CaptionedImage', data: { url: '/img/image2@2x.png', text: '教導四季國小學生製作簡易濾水系統，學童一起「豐」學習' } },
              { id: uuidv4(), type: 'Text1', data: { text: '四季部落生活用水主要來自於自然湧出之山泉水、未經淨化之河水、以及近年新增的淨化河水（即部落所謂的自來水）。在簡易自來水系統建置後，居民逐漸仰賴，但卻造成淨化廠容量不足之窘境。因此部落內部提出了新設淨水廠的計畫，然而部落用地嚴重不足，且淨水池之載重對於山地部落，有致災的疑慮。所以行動工程師希望運用在海外建置水資源系統之實務經驗，配合於部落的探查成果，檢視水資源利用現況，在避免新設淨化廠的前提下，滿足部落之用水需求。' } },
            ]
          }
        },
        { id: uuidv4(), type: 'Title3', data: { title: '抓住青春的尾巴，勇敢的揮灑一次！' } },
        { id: uuidv4(), type: 'Text1', data: { text: '學；也有從大四到碩士畢業前投入計畫，想看看自己幾年來所學如何結合到實作中；更有畢業後仍不遺餘力回來傳承的校友，除了到新場域貢獻以往施作經驗，也享受著再次跟大家一起捲起袖子動手做並打開心與世界對話。行動工程師結合並展現臺科大學生應用所學動手做之能力，更在與人的相互關懷與互動中，了解傾聽對話，增加與人互動之溫度。前後梯次學員，雖來自不同年級與學院，卻藉參與此培育計畫有著共同的難忘回憶，學員在不同場域施作後，相互的傳承與交流，讓彼此間有著密不可分的默契，更呼應本計畫動手做外，對施作場域與團隊本身傾聽與對話的精神。行動工程師不僅有形的改變了施作場域，也使參與學員們與世界互動的心有了不一樣的感受。適逢今年疫情期間，各期學員們仍如同朋友般，關心著参與過的海內、外國家與各場域居民，維護著彼此間的情誼。' } },
        { id: uuidv4(), type: 'Text1', data: { text: '或許你也曾經跟這群富有執行力的行動工程師一樣，有著一個想為這個社會做些什麼的念頭，但是被課業、社團、亦或是愛情給耽誤了。如果當夜深人靜、午夜夢迴時還是有著那份悸動與一絲絲遺憾，或許該是時候為自己勇敢的任性一次了！也許會很辛苦，也或許會很感動，但可以確定的是為了不讓這份悸動再次落空，這次一定要積極的張開雙手，奮力地緊緊握住青春的尾巴，揮灑你的人生！' } },
        { id: uuidv4(), type: 'Link1', data: { url: 'https://www.2020usrexpo.org/column-detail.php?i=114', title: '大學社會責任實踐 行動工程師培育計畫GO！EIA' } },
      ],
      componentsEn: [],
      fbUrl: '',
      ytUrl: '',
      igUrl: '',
      webUrl: '',
    },
    {
      id: 7,
      time: new Date(),
      imgUrl: '/img/image1@2x.png',
      periods: '第七期',
      title: '從社區更新活化出發，提升公館城南聚落防災韌性',
      periodsEn: 'No.7',
      titleEn: 'The Resilience Enhancement of Disaster Prevention of the ...',
      components: [
        { id: uuidv4(), type: 'Title3', data: { title: '培育富有溫度、具備關懷與溝通力的綠色行動工程師' } },
        { id: uuidv4(), type: 'Text1', data: { text: '不知道你有沒有想過，需要多少的力量才足以改變你眼中的世界呢？答案或許有點出乎你的意料，因為你其實只需要幾個志同道合的朋友，抑或只要你願意挺身而出，便會有一群和你有著一樣信念的人們響應你的號召，而世界也會因此而發生改變，正所謂「德不孤，必有鄰」，行動工程師的團隊便是在這樣的因緣際會下產生的。正當我們常因閱讀或聆聽到「無國界醫生」的故事而感動時，本校劉志成副校長（時任工程學院院長）的一個「無國界工程師」點子，幾位學生與幾位教師，便開始讓世界的某個角落，慢慢開始發生改變。今天，就讓我們來一探究竟這群「行動工程師」的故事吧！' } },
        { id: uuidv4(), type: 'Text1', data: { text: '臺科大「行動工程師培育計畫」在早期尚未獲教育補助時，便已開始執行，至今已培育逾百名之計畫學員，臺科大在這國際連結之USR計畫中，依循聯合國永續發展第11項指標：「促使城市與人類居住聚落具包容、安全、韌性及永續性」，由參與之國際師生團隊透過實作改善社區基礎設施，培育新一代優質工程師，進而達到「居住永續安全、學校永續教育、社區永續發展」目標。而今年主要包括兩項規劃重點：首先是延續前期「行動工程師」的計畫精神，繼續深耕印尼及越南等新南向國家的偏鄉，改善當地的飲用水水質、環境衛生、教學資源及加強環境教育等。另一項重點則是本土偏鄉社區的災害預防與創生活化，這部分包括：邊坡移動災害預警設備建置、防災與環境教育宣導、社區文化資產（瞭望台、古道）修復與創生、生態池與魚菜共生系統建構等。臺科大素來以精湛的工程技術及極高的國際化程度聞名，行動工程師培育計畫希望學生透過這項的學習模式，訓練出善於傾聽、誠於對話、富有溫度與國際觀的工程師。' } },
        { id: uuidv4(), type: 'CaptionedImage', data: { url: '/img/image2@2x.png', text: '行動工程師之師生於印尼偏鄉社區設立灌溉系統' } },
        { id: uuidv4(), type: 'Title3', data: { title: '貼近當地需求，一起發現與解決問題，建立深厚夥伴朋友情誼' } },
        { id: uuidv4(), type: 'Text1', data: { text: '參與計畫的學生在過程中學習如何包容異文化、貼近不同文化生活、培養世界觀，也更加了解臺灣這片土地，最重要的是能將大學中所學到的知識和專業能力應用在生活中，甚至是到國外與他人交流，讓大學生學習到書本上學不到的實務應用經驗，希望能夠藉由大學生所學賦予社區更多不同的面貌。透過與部落人士共同舉辦歡迎開幕式，讓部落居民更加認識這群大學生工程師，與在地居民一同為部落努力並且對部落產生認同感，不預設自己是來服務部落，而是來認識不同生活背景的部落，更學習把場域部落當成自己的家園，讓學生在協助解決當地環境問題的過程中，能更貼近在地需求。透過多次與居民訪談、不斷的與居民溝通了解他們的需求，與夥伴們一同找到解法，也因此這群行動工程師與場域夥伴不只是單純的夥伴關係，更像是一起努力的朋友，除完成與場域居民討論後的相關施作，更留下學員與居民間寶貴的互動情誼。每次到部落，居民夥伴們總是熱情的迎接我們，部落小孩開心歡迎這些大哥哥大姊姊的到來，在離別的時候更是依依不捨，希望可以再次回到部落。' } },
        {
          id: uuidv4(),
          type: 'Row',
          data: {
            components: [
              { id: uuidv4(), type: 'CaptionedImage', data: { url: '/img/image2@2x.png', text: '行動工程師之師生於印尼偏鄉社區設立灌溉系統' } },
              { id: uuidv4(), type: 'CaptionedImage', data: { url: '/img/image2@2x.png', text: '行動工程師之師生於印尼偏鄉社區設立灌溉系統' } },
            ]
          }
        },
        { id: uuidv4(), type: 'Title3', data: { title: '自動化坡地監測設備，提升部落環境安全與居民自主防災體認' } },
        {
          id: uuidv4(),
          type: 'Row',
          data: {
            components: [
              { id: uuidv4(), type: 'Text1', data: { text: '行動工程師之師生訪談宜蘭四季國小校長及部落耆老後，發現部落對於山地環境的災害特徵變化，如擋土設施位移、地表開裂、地下水位上升等，皆感到憂心。而四季部落位於通往著名的觀光景點嘉羅湖四季林道上，一整年遊客不斷，部落也希望能藉由打造地方特色市集提供遊客更多認識部落的機會，因此環境安全顯得更加重要。行動工程師希望藉由設置自動化坡地監測設備，進行雨量、地下水位、地表變化等監測，將數據上傳至防災資料雲端平台，一方面做為提升部落居民自主防災的體認，二方面可以作為環境教育課程教材，以落實永續防災的概念，同時使學童及居民了解邊坡災害威脅，並提高災害徵兆的辨識能力。' } },
              { id: uuidv4(), type: 'CaptionedImage', data: { url: '/img/image2@2x.png', text: '行動工程師之師生於印尼偏鄉社區設立灌溉系統' } },
            ]
          }
        },
        { id: uuidv4(), type: 'Title3', data: { title: '舉辦互動式防災小學堂，進行防災深耕，帶動全校「豐」學習' } },
        { id: uuidv4(), type: 'Text1', data: { text: '為提升部落自主防災能力，行動工程師以「防災教育」與「校園永續」兩大核心為主軸，藉由知識整合、互助合作與尊重之防災營隊，舉辦防災小學堂活動，傳遞部落孩童相關知識，安排「手作自製簡易雨量量測筒」活動，透過遊戲 DIY 體驗量測原理，讓孩童自製雨水量測設備，並在家中簡易量測雨量及雨水酸鹼質檢測，認識酸雨與大氣環境。並且舉辦防災系列講座，透過互動課程傳遞防災相關知識予居民，並組織自主防災任務編組，進行防災演練，達到平時巡檢、災前疏散、災中應變以及災後復原的自救能力，增強防災深度廣度，有效助於部落實施自主防災。' } },
        { id: uuidv4(), type: 'Title3', data: { title: '滿足部落用水需求，加值永續衛生健康' } },
        {
          id: uuidv4(),
          type: 'Row',
          data: {
            components: [
              { id: uuidv4(), type: 'CaptionedImage', data: { url: '/img/image2@2x.png', text: '教導四季國小學生製作簡易濾水系統，學童一起「豐」學習' } },
              { id: uuidv4(), type: 'Text1', data: { text: '四季部落生活用水主要來自於自然湧出之山泉水、未經淨化之河水、以及近年新增的淨化河水（即部落所謂的自來水）。在簡易自來水系統建置後，居民逐漸仰賴，但卻造成淨化廠容量不足之窘境。因此部落內部提出了新設淨水廠的計畫，然而部落用地嚴重不足，且淨水池之載重對於山地部落，有致災的疑慮。所以行動工程師希望運用在海外建置水資源系統之實務經驗，配合於部落的探查成果，檢視水資源利用現況，在避免新設淨化廠的前提下，滿足部落之用水需求。' } },
            ]
          }
        },
        { id: uuidv4(), type: 'Title3', data: { title: '抓住青春的尾巴，勇敢的揮灑一次！' } },
        { id: uuidv4(), type: 'Text1', data: { text: '學；也有從大四到碩士畢業前投入計畫，想看看自己幾年來所學如何結合到實作中；更有畢業後仍不遺餘力回來傳承的校友，除了到新場域貢獻以往施作經驗，也享受著再次跟大家一起捲起袖子動手做並打開心與世界對話。行動工程師結合並展現臺科大學生應用所學動手做之能力，更在與人的相互關懷與互動中，了解傾聽對話，增加與人互動之溫度。前後梯次學員，雖來自不同年級與學院，卻藉參與此培育計畫有著共同的難忘回憶，學員在不同場域施作後，相互的傳承與交流，讓彼此間有著密不可分的默契，更呼應本計畫動手做外，對施作場域與團隊本身傾聽與對話的精神。行動工程師不僅有形的改變了施作場域，也使參與學員們與世界互動的心有了不一樣的感受。適逢今年疫情期間，各期學員們仍如同朋友般，關心著参與過的海內、外國家與各場域居民，維護著彼此間的情誼。' } },
        { id: uuidv4(), type: 'Text1', data: { text: '或許你也曾經跟這群富有執行力的行動工程師一樣，有著一個想為這個社會做些什麼的念頭，但是被課業、社團、亦或是愛情給耽誤了。如果當夜深人靜、午夜夢迴時還是有著那份悸動與一絲絲遺憾，或許該是時候為自己勇敢的任性一次了！也許會很辛苦，也或許會很感動，但可以確定的是為了不讓這份悸動再次落空，這次一定要積極的張開雙手，奮力地緊緊握住青春的尾巴，揮灑你的人生！' } },
        { id: uuidv4(), type: 'Link1', data: { url: 'https://www.2020usrexpo.org/column-detail.php?i=114', title: '大學社會責任實踐 行動工程師培育計畫GO！EIA' } },
      ],
      componentsEn: [],
      fbUrl: '',
      ytUrl: '',
      igUrl: '',
      webUrl: '',
    },
    {
      id: 6,
      time: new Date(),
      imgUrl: '/img/image1@2x.png',
      periods: '第六期',
      title: '青銀藝起學，攜手打造社區特色課程',
      periodsEn: 'No.6',
      titleEn: 'Younger and older generations engage hand-in-hand in ...',
      components: [
        { id: uuidv4(), type: 'Title3', data: { title: '培育富有溫度、具備關懷與溝通力的綠色行動工程師' } },
        { id: uuidv4(), type: 'Text1', data: { text: '不知道你有沒有想過，需要多少的力量才足以改變你眼中的世界呢？答案或許有點出乎你的意料，因為你其實只需要幾個志同道合的朋友，抑或只要你願意挺身而出，便會有一群和你有著一樣信念的人們響應你的號召，而世界也會因此而發生改變，正所謂「德不孤，必有鄰」，行動工程師的團隊便是在這樣的因緣際會下產生的。正當我們常因閱讀或聆聽到「無國界醫生」的故事而感動時，本校劉志成副校長（時任工程學院院長）的一個「無國界工程師」點子，幾位學生與幾位教師，便開始讓世界的某個角落，慢慢開始發生改變。今天，就讓我們來一探究竟這群「行動工程師」的故事吧！' } },
        { id: uuidv4(), type: 'Text1', data: { text: '臺科大「行動工程師培育計畫」在早期尚未獲教育補助時，便已開始執行，至今已培育逾百名之計畫學員，臺科大在這國際連結之USR計畫中，依循聯合國永續發展第11項指標：「促使城市與人類居住聚落具包容、安全、韌性及永續性」，由參與之國際師生團隊透過實作改善社區基礎設施，培育新一代優質工程師，進而達到「居住永續安全、學校永續教育、社區永續發展」目標。而今年主要包括兩項規劃重點：首先是延續前期「行動工程師」的計畫精神，繼續深耕印尼及越南等新南向國家的偏鄉，改善當地的飲用水水質、環境衛生、教學資源及加強環境教育等。另一項重點則是本土偏鄉社區的災害預防與創生活化，這部分包括：邊坡移動災害預警設備建置、防災與環境教育宣導、社區文化資產（瞭望台、古道）修復與創生、生態池與魚菜共生系統建構等。臺科大素來以精湛的工程技術及極高的國際化程度聞名，行動工程師培育計畫希望學生透過這項的學習模式，訓練出善於傾聽、誠於對話、富有溫度與國際觀的工程師。' } },
        { id: uuidv4(), type: 'CaptionedImage', data: { url: '/img/image2@2x.png', text: '行動工程師之師生於印尼偏鄉社區設立灌溉系統' } },
        { id: uuidv4(), type: 'Title3', data: { title: '貼近當地需求，一起發現與解決問題，建立深厚夥伴朋友情誼' } },
        { id: uuidv4(), type: 'Text1', data: { text: '參與計畫的學生在過程中學習如何包容異文化、貼近不同文化生活、培養世界觀，也更加了解臺灣這片土地，最重要的是能將大學中所學到的知識和專業能力應用在生活中，甚至是到國外與他人交流，讓大學生學習到書本上學不到的實務應用經驗，希望能夠藉由大學生所學賦予社區更多不同的面貌。透過與部落人士共同舉辦歡迎開幕式，讓部落居民更加認識這群大學生工程師，與在地居民一同為部落努力並且對部落產生認同感，不預設自己是來服務部落，而是來認識不同生活背景的部落，更學習把場域部落當成自己的家園，讓學生在協助解決當地環境問題的過程中，能更貼近在地需求。透過多次與居民訪談、不斷的與居民溝通了解他們的需求，與夥伴們一同找到解法，也因此這群行動工程師與場域夥伴不只是單純的夥伴關係，更像是一起努力的朋友，除完成與場域居民討論後的相關施作，更留下學員與居民間寶貴的互動情誼。每次到部落，居民夥伴們總是熱情的迎接我們，部落小孩開心歡迎這些大哥哥大姊姊的到來，在離別的時候更是依依不捨，希望可以再次回到部落。' } },
        {
          type: 'Row',
          data: {
            components: [
              { id: uuidv4(), type: 'CaptionedImage', data: { url: '/img/image2@2x.png', text: '行動工程師之師生於印尼偏鄉社區設立灌溉系統' } },
              { id: uuidv4(), type: 'CaptionedImage', data: { url: '/img/image2@2x.png', text: '行動工程師之師生於印尼偏鄉社區設立灌溉系統' } },
            ]
          }
        },
        { id: uuidv4(), type: 'Title3', data: { title: '自動化坡地監測設備，提升部落環境安全與居民自主防災體認' } },
        {
          type: 'Row',
          data: {
            components: [
              { id: uuidv4(), type: 'Text1', data: { text: '行動工程師之師生訪談宜蘭四季國小校長及部落耆老後，發現部落對於山地環境的災害特徵變化，如擋土設施位移、地表開裂、地下水位上升等，皆感到憂心。而四季部落位於通往著名的觀光景點嘉羅湖四季林道上，一整年遊客不斷，部落也希望能藉由打造地方特色市集提供遊客更多認識部落的機會，因此環境安全顯得更加重要。行動工程師希望藉由設置自動化坡地監測設備，進行雨量、地下水位、地表變化等監測，將數據上傳至防災資料雲端平台，一方面做為提升部落居民自主防災的體認，二方面可以作為環境教育課程教材，以落實永續防災的概念，同時使學童及居民了解邊坡災害威脅，並提高災害徵兆的辨識能力。' } },
              { id: uuidv4(), type: 'CaptionedImage', data: { url: '/img/image2@2x.png', text: '行動工程師之師生於印尼偏鄉社區設立灌溉系統' } },
            ]
          }
        },
        { id: uuidv4(), type: 'Title3', data: { title: '舉辦互動式防災小學堂，進行防災深耕，帶動全校「豐」學習' } },
        { id: uuidv4(), type: 'Text1', data: { text: '為提升部落自主防災能力，行動工程師以「防災教育」與「校園永續」兩大核心為主軸，藉由知識整合、互助合作與尊重之防災營隊，舉辦防災小學堂活動，傳遞部落孩童相關知識，安排「手作自製簡易雨量量測筒」活動，透過遊戲 DIY 體驗量測原理，讓孩童自製雨水量測設備，並在家中簡易量測雨量及雨水酸鹼質檢測，認識酸雨與大氣環境。並且舉辦防災系列講座，透過互動課程傳遞防災相關知識予居民，並組織自主防災任務編組，進行防災演練，達到平時巡檢、災前疏散、災中應變以及災後復原的自救能力，增強防災深度廣度，有效助於部落實施自主防災。' } },
        { id: uuidv4(), type: 'Title3', data: { title: '滿足部落用水需求，加值永續衛生健康' } },
        {
          type: 'Row',
          data: {
            components: [
              { id: uuidv4(), type: 'CaptionedImage', data: { url: '/img/image2@2x.png', text: '教導四季國小學生製作簡易濾水系統，學童一起「豐」學習' } },
              { id: uuidv4(), type: 'Text1', data: { text: '四季部落生活用水主要來自於自然湧出之山泉水、未經淨化之河水、以及近年新增的淨化河水（即部落所謂的自來水）。在簡易自來水系統建置後，居民逐漸仰賴，但卻造成淨化廠容量不足之窘境。因此部落內部提出了新設淨水廠的計畫，然而部落用地嚴重不足，且淨水池之載重對於山地部落，有致災的疑慮。所以行動工程師希望運用在海外建置水資源系統之實務經驗，配合於部落的探查成果，檢視水資源利用現況，在避免新設淨化廠的前提下，滿足部落之用水需求。' } },
            ]
          }
        },
        { id: uuidv4(), type: 'Title3', data: { title: '抓住青春的尾巴，勇敢的揮灑一次！' } },
        { id: uuidv4(), type: 'Text1', data: { text: '學；也有從大四到碩士畢業前投入計畫，想看看自己幾年來所學如何結合到實作中；更有畢業後仍不遺餘力回來傳承的校友，除了到新場域貢獻以往施作經驗，也享受著再次跟大家一起捲起袖子動手做並打開心與世界對話。行動工程師結合並展現臺科大學生應用所學動手做之能力，更在與人的相互關懷與互動中，了解傾聽對話，增加與人互動之溫度。前後梯次學員，雖來自不同年級與學院，卻藉參與此培育計畫有著共同的難忘回憶，學員在不同場域施作後，相互的傳承與交流，讓彼此間有著密不可分的默契，更呼應本計畫動手做外，對施作場域與團隊本身傾聽與對話的精神。行動工程師不僅有形的改變了施作場域，也使參與學員們與世界互動的心有了不一樣的感受。適逢今年疫情期間，各期學員們仍如同朋友般，關心著参與過的海內、外國家與各場域居民，維護著彼此間的情誼。' } },
        { id: uuidv4(), type: 'Text1', data: { text: '或許你也曾經跟這群富有執行力的行動工程師一樣，有著一個想為這個社會做些什麼的念頭，但是被課業、社團、亦或是愛情給耽誤了。如果當夜深人靜、午夜夢迴時還是有著那份悸動與一絲絲遺憾，或許該是時候為自己勇敢的任性一次了！也許會很辛苦，也或許會很感動，但可以確定的是為了不讓這份悸動再次落空，這次一定要積極的張開雙手，奮力地緊緊握住青春的尾巴，揮灑你的人生！' } },
        { id: uuidv4(), type: 'Link1', data: { url: 'https://www.2020usrexpo.org/column-detail.php?i=114', title: '大學社會責任實踐 行動工程師培育計畫GO！EIA' } },
      ],
      componentsEn: [],
      fbUrl: '',
      ytUrl: '',
      igUrl: '',
      webUrl: '',
    },
    {
      id: 5,
      time: new Date(),
      imgUrl: '/img/image1@2x.png',
      periods: '第五期',
      title: '青銀藝起學，攜手打造社區特色課程',
      periodsEn: 'No.5',
      titleEn: 'Younger and older generations engage hand-in-hand in ...',
      components: [
        { id: uuidv4(), type: 'Title3', data: { title: '培育富有溫度、具備關懷與溝通力的綠色行動工程師' } },
        { id: uuidv4(), type: 'Text1', data: { text: '不知道你有沒有想過，需要多少的力量才足以改變你眼中的世界呢？答案或許有點出乎你的意料，因為你其實只需要幾個志同道合的朋友，抑或只要你願意挺身而出，便會有一群和你有著一樣信念的人們響應你的號召，而世界也會因此而發生改變，正所謂「德不孤，必有鄰」，行動工程師的團隊便是在這樣的因緣際會下產生的。正當我們常因閱讀或聆聽到「無國界醫生」的故事而感動時，本校劉志成副校長（時任工程學院院長）的一個「無國界工程師」點子，幾位學生與幾位教師，便開始讓世界的某個角落，慢慢開始發生改變。今天，就讓我們來一探究竟這群「行動工程師」的故事吧！' } },
        { id: uuidv4(), type: 'Text1', data: { text: '臺科大「行動工程師培育計畫」在早期尚未獲教育補助時，便已開始執行，至今已培育逾百名之計畫學員，臺科大在這國際連結之USR計畫中，依循聯合國永續發展第11項指標：「促使城市與人類居住聚落具包容、安全、韌性及永續性」，由參與之國際師生團隊透過實作改善社區基礎設施，培育新一代優質工程師，進而達到「居住永續安全、學校永續教育、社區永續發展」目標。而今年主要包括兩項規劃重點：首先是延續前期「行動工程師」的計畫精神，繼續深耕印尼及越南等新南向國家的偏鄉，改善當地的飲用水水質、環境衛生、教學資源及加強環境教育等。另一項重點則是本土偏鄉社區的災害預防與創生活化，這部分包括：邊坡移動災害預警設備建置、防災與環境教育宣導、社區文化資產（瞭望台、古道）修復與創生、生態池與魚菜共生系統建構等。臺科大素來以精湛的工程技術及極高的國際化程度聞名，行動工程師培育計畫希望學生透過這項的學習模式，訓練出善於傾聽、誠於對話、富有溫度與國際觀的工程師。' } },
        { id: uuidv4(), type: 'CaptionedImage', data: { url: '/img/image2@2x.png', text: '行動工程師之師生於印尼偏鄉社區設立灌溉系統' } },
        { id: uuidv4(), type: 'Title3', data: { title: '貼近當地需求，一起發現與解決問題，建立深厚夥伴朋友情誼' } },
        { id: uuidv4(), type: 'Text1', data: { text: '參與計畫的學生在過程中學習如何包容異文化、貼近不同文化生活、培養世界觀，也更加了解臺灣這片土地，最重要的是能將大學中所學到的知識和專業能力應用在生活中，甚至是到國外與他人交流，讓大學生學習到書本上學不到的實務應用經驗，希望能夠藉由大學生所學賦予社區更多不同的面貌。透過與部落人士共同舉辦歡迎開幕式，讓部落居民更加認識這群大學生工程師，與在地居民一同為部落努力並且對部落產生認同感，不預設自己是來服務部落，而是來認識不同生活背景的部落，更學習把場域部落當成自己的家園，讓學生在協助解決當地環境問題的過程中，能更貼近在地需求。透過多次與居民訪談、不斷的與居民溝通了解他們的需求，與夥伴們一同找到解法，也因此這群行動工程師與場域夥伴不只是單純的夥伴關係，更像是一起努力的朋友，除完成與場域居民討論後的相關施作，更留下學員與居民間寶貴的互動情誼。每次到部落，居民夥伴們總是熱情的迎接我們，部落小孩開心歡迎這些大哥哥大姊姊的到來，在離別的時候更是依依不捨，希望可以再次回到部落。' } },
        {
          type: 'Row',
          data: {
            components: [
              { id: uuidv4(), type: 'CaptionedImage', data: { url: '/img/image2@2x.png', text: '行動工程師之師生於印尼偏鄉社區設立灌溉系統' } },
              { id: uuidv4(), type: 'CaptionedImage', data: { url: '/img/image2@2x.png', text: '行動工程師之師生於印尼偏鄉社區設立灌溉系統' } },
            ]
          }
        },
        { id: uuidv4(), type: 'Title3', data: { title: '自動化坡地監測設備，提升部落環境安全與居民自主防災體認' } },
        {
          type: 'Row',
          data: {
            components: [
              { id: uuidv4(), type: 'Text1', data: { text: '行動工程師之師生訪談宜蘭四季國小校長及部落耆老後，發現部落對於山地環境的災害特徵變化，如擋土設施位移、地表開裂、地下水位上升等，皆感到憂心。而四季部落位於通往著名的觀光景點嘉羅湖四季林道上，一整年遊客不斷，部落也希望能藉由打造地方特色市集提供遊客更多認識部落的機會，因此環境安全顯得更加重要。行動工程師希望藉由設置自動化坡地監測設備，進行雨量、地下水位、地表變化等監測，將數據上傳至防災資料雲端平台，一方面做為提升部落居民自主防災的體認，二方面可以作為環境教育課程教材，以落實永續防災的概念，同時使學童及居民了解邊坡災害威脅，並提高災害徵兆的辨識能力。' } },
              { id: uuidv4(), type: 'CaptionedImage', data: { url: '/img/image2@2x.png', text: '行動工程師之師生於印尼偏鄉社區設立灌溉系統' } },
            ]
          }
        },
        { id: uuidv4(), type: 'Title3', data: { title: '舉辦互動式防災小學堂，進行防災深耕，帶動全校「豐」學習' } },
        { id: uuidv4(), type: 'Text1', data: { text: '為提升部落自主防災能力，行動工程師以「防災教育」與「校園永續」兩大核心為主軸，藉由知識整合、互助合作與尊重之防災營隊，舉辦防災小學堂活動，傳遞部落孩童相關知識，安排「手作自製簡易雨量量測筒」活動，透過遊戲 DIY 體驗量測原理，讓孩童自製雨水量測設備，並在家中簡易量測雨量及雨水酸鹼質檢測，認識酸雨與大氣環境。並且舉辦防災系列講座，透過互動課程傳遞防災相關知識予居民，並組織自主防災任務編組，進行防災演練，達到平時巡檢、災前疏散、災中應變以及災後復原的自救能力，增強防災深度廣度，有效助於部落實施自主防災。' } },
        { id: uuidv4(), type: 'Title3', data: { title: '滿足部落用水需求，加值永續衛生健康' } },
        {
          type: 'Row',
          data: {
            components: [
              { id: uuidv4(), type: 'CaptionedImage', data: { url: '/img/image2@2x.png', text: '教導四季國小學生製作簡易濾水系統，學童一起「豐」學習' } },
              { id: uuidv4(), type: 'Text1', data: { text: '四季部落生活用水主要來自於自然湧出之山泉水、未經淨化之河水、以及近年新增的淨化河水（即部落所謂的自來水）。在簡易自來水系統建置後，居民逐漸仰賴，但卻造成淨化廠容量不足之窘境。因此部落內部提出了新設淨水廠的計畫，然而部落用地嚴重不足，且淨水池之載重對於山地部落，有致災的疑慮。所以行動工程師希望運用在海外建置水資源系統之實務經驗，配合於部落的探查成果，檢視水資源利用現況，在避免新設淨化廠的前提下，滿足部落之用水需求。' } },
            ]
          }
        },
        { id: uuidv4(), type: 'Title3', data: { title: '抓住青春的尾巴，勇敢的揮灑一次！' } },
        { id: uuidv4(), type: 'Text1', data: { text: '學；也有從大四到碩士畢業前投入計畫，想看看自己幾年來所學如何結合到實作中；更有畢業後仍不遺餘力回來傳承的校友，除了到新場域貢獻以往施作經驗，也享受著再次跟大家一起捲起袖子動手做並打開心與世界對話。行動工程師結合並展現臺科大學生應用所學動手做之能力，更在與人的相互關懷與互動中，了解傾聽對話，增加與人互動之溫度。前後梯次學員，雖來自不同年級與學院，卻藉參與此培育計畫有著共同的難忘回憶，學員在不同場域施作後，相互的傳承與交流，讓彼此間有著密不可分的默契，更呼應本計畫動手做外，對施作場域與團隊本身傾聽與對話的精神。行動工程師不僅有形的改變了施作場域，也使參與學員們與世界互動的心有了不一樣的感受。適逢今年疫情期間，各期學員們仍如同朋友般，關心著参與過的海內、外國家與各場域居民，維護著彼此間的情誼。' } },
        { id: uuidv4(), type: 'Text1', data: { text: '或許你也曾經跟這群富有執行力的行動工程師一樣，有著一個想為這個社會做些什麼的念頭，但是被課業、社團、亦或是愛情給耽誤了。如果當夜深人靜、午夜夢迴時還是有著那份悸動與一絲絲遺憾，或許該是時候為自己勇敢的任性一次了！也許會很辛苦，也或許會很感動，但可以確定的是為了不讓這份悸動再次落空，這次一定要積極的張開雙手，奮力地緊緊握住青春的尾巴，揮灑你的人生！' } },
        { id: uuidv4(), type: 'Link1', data: { url: 'https://www.2020usrexpo.org/column-detail.php?i=114', title: '大學社會責任實踐 行動工程師培育計畫GO！EIA' } },
      ],
      componentsEn: [],
      fbUrl: '',
      ytUrl: '',
      igUrl: '',
      webUrl: '',
    },
    {
      id: 4,
      time: new Date(),
      imgUrl: '/img/image1@2x.png',
      periods: '第四期',
      title: '從社區更新活化出發，提升公館城南聚落防災韌性',
      periodsEn: 'No.4',
      titleEn: 'The Resilience Enhancement of Disaster Prevention of the ...',
      components: [
        { id: uuidv4(), type: 'Title3', data: { title: '培育富有溫度、具備關懷與溝通力的綠色行動工程師' } },
        { id: uuidv4(), type: 'Text1', data: { text: '不知道你有沒有想過，需要多少的力量才足以改變你眼中的世界呢？答案或許有點出乎你的意料，因為你其實只需要幾個志同道合的朋友，抑或只要你願意挺身而出，便會有一群和你有著一樣信念的人們響應你的號召，而世界也會因此而發生改變，正所謂「德不孤，必有鄰」，行動工程師的團隊便是在這樣的因緣際會下產生的。正當我們常因閱讀或聆聽到「無國界醫生」的故事而感動時，本校劉志成副校長（時任工程學院院長）的一個「無國界工程師」點子，幾位學生與幾位教師，便開始讓世界的某個角落，慢慢開始發生改變。今天，就讓我們來一探究竟這群「行動工程師」的故事吧！' } },
        { id: uuidv4(), type: 'Text1', data: { text: '臺科大「行動工程師培育計畫」在早期尚未獲教育補助時，便已開始執行，至今已培育逾百名之計畫學員，臺科大在這國際連結之USR計畫中，依循聯合國永續發展第11項指標：「促使城市與人類居住聚落具包容、安全、韌性及永續性」，由參與之國際師生團隊透過實作改善社區基礎設施，培育新一代優質工程師，進而達到「居住永續安全、學校永續教育、社區永續發展」目標。而今年主要包括兩項規劃重點：首先是延續前期「行動工程師」的計畫精神，繼續深耕印尼及越南等新南向國家的偏鄉，改善當地的飲用水水質、環境衛生、教學資源及加強環境教育等。另一項重點則是本土偏鄉社區的災害預防與創生活化，這部分包括：邊坡移動災害預警設備建置、防災與環境教育宣導、社區文化資產（瞭望台、古道）修復與創生、生態池與魚菜共生系統建構等。臺科大素來以精湛的工程技術及極高的國際化程度聞名，行動工程師培育計畫希望學生透過這項的學習模式，訓練出善於傾聽、誠於對話、富有溫度與國際觀的工程師。' } },
        { id: uuidv4(), type: 'CaptionedImage', data: { url: '/img/image2@2x.png', text: '行動工程師之師生於印尼偏鄉社區設立灌溉系統' } },
        { id: uuidv4(), type: 'Title3', data: { title: '貼近當地需求，一起發現與解決問題，建立深厚夥伴朋友情誼' } },
        { id: uuidv4(), type: 'Text1', data: { text: '參與計畫的學生在過程中學習如何包容異文化、貼近不同文化生活、培養世界觀，也更加了解臺灣這片土地，最重要的是能將大學中所學到的知識和專業能力應用在生活中，甚至是到國外與他人交流，讓大學生學習到書本上學不到的實務應用經驗，希望能夠藉由大學生所學賦予社區更多不同的面貌。透過與部落人士共同舉辦歡迎開幕式，讓部落居民更加認識這群大學生工程師，與在地居民一同為部落努力並且對部落產生認同感，不預設自己是來服務部落，而是來認識不同生活背景的部落，更學習把場域部落當成自己的家園，讓學生在協助解決當地環境問題的過程中，能更貼近在地需求。透過多次與居民訪談、不斷的與居民溝通了解他們的需求，與夥伴們一同找到解法，也因此這群行動工程師與場域夥伴不只是單純的夥伴關係，更像是一起努力的朋友，除完成與場域居民討論後的相關施作，更留下學員與居民間寶貴的互動情誼。每次到部落，居民夥伴們總是熱情的迎接我們，部落小孩開心歡迎這些大哥哥大姊姊的到來，在離別的時候更是依依不捨，希望可以再次回到部落。' } },
        {
          type: 'Row',
          data: {
            components: [
              { id: uuidv4(), type: 'CaptionedImage', data: { url: '/img/image2@2x.png', text: '行動工程師之師生於印尼偏鄉社區設立灌溉系統' } },
              { id: uuidv4(), type: 'CaptionedImage', data: { url: '/img/image2@2x.png', text: '行動工程師之師生於印尼偏鄉社區設立灌溉系統' } },
            ]
          }
        },
        { id: uuidv4(), type: 'Title3', data: { title: '自動化坡地監測設備，提升部落環境安全與居民自主防災體認' } },
        {
          type: 'Row',
          data: {
            components: [
              { id: uuidv4(), type: 'Text1', data: { text: '行動工程師之師生訪談宜蘭四季國小校長及部落耆老後，發現部落對於山地環境的災害特徵變化，如擋土設施位移、地表開裂、地下水位上升等，皆感到憂心。而四季部落位於通往著名的觀光景點嘉羅湖四季林道上，一整年遊客不斷，部落也希望能藉由打造地方特色市集提供遊客更多認識部落的機會，因此環境安全顯得更加重要。行動工程師希望藉由設置自動化坡地監測設備，進行雨量、地下水位、地表變化等監測，將數據上傳至防災資料雲端平台，一方面做為提升部落居民自主防災的體認，二方面可以作為環境教育課程教材，以落實永續防災的概念，同時使學童及居民了解邊坡災害威脅，並提高災害徵兆的辨識能力。' } },
              { id: uuidv4(), type: 'CaptionedImage', data: { url: '/img/image2@2x.png', text: '行動工程師之師生於印尼偏鄉社區設立灌溉系統' } },
            ]
          }
        },
        { id: uuidv4(), type: 'Title3', data: { title: '舉辦互動式防災小學堂，進行防災深耕，帶動全校「豐」學習' } },
        { id: uuidv4(), type: 'Text1', data: { text: '為提升部落自主防災能力，行動工程師以「防災教育」與「校園永續」兩大核心為主軸，藉由知識整合、互助合作與尊重之防災營隊，舉辦防災小學堂活動，傳遞部落孩童相關知識，安排「手作自製簡易雨量量測筒」活動，透過遊戲 DIY 體驗量測原理，讓孩童自製雨水量測設備，並在家中簡易量測雨量及雨水酸鹼質檢測，認識酸雨與大氣環境。並且舉辦防災系列講座，透過互動課程傳遞防災相關知識予居民，並組織自主防災任務編組，進行防災演練，達到平時巡檢、災前疏散、災中應變以及災後復原的自救能力，增強防災深度廣度，有效助於部落實施自主防災。' } },
        { id: uuidv4(), type: 'Title3', data: { title: '滿足部落用水需求，加值永續衛生健康' } },
        {
          type: 'Row',
          data: {
            components: [
              { id: uuidv4(), type: 'CaptionedImage', data: { url: '/img/image2@2x.png', text: '教導四季國小學生製作簡易濾水系統，學童一起「豐」學習' } },
              { id: uuidv4(), type: 'Text1', data: { text: '四季部落生活用水主要來自於自然湧出之山泉水、未經淨化之河水、以及近年新增的淨化河水（即部落所謂的自來水）。在簡易自來水系統建置後，居民逐漸仰賴，但卻造成淨化廠容量不足之窘境。因此部落內部提出了新設淨水廠的計畫，然而部落用地嚴重不足，且淨水池之載重對於山地部落，有致災的疑慮。所以行動工程師希望運用在海外建置水資源系統之實務經驗，配合於部落的探查成果，檢視水資源利用現況，在避免新設淨化廠的前提下，滿足部落之用水需求。' } },
            ]
          }
        },
        { id: uuidv4(), type: 'Title3', data: { title: '抓住青春的尾巴，勇敢的揮灑一次！' } },
        { id: uuidv4(), type: 'Text1', data: { text: '學；也有從大四到碩士畢業前投入計畫，想看看自己幾年來所學如何結合到實作中；更有畢業後仍不遺餘力回來傳承的校友，除了到新場域貢獻以往施作經驗，也享受著再次跟大家一起捲起袖子動手做並打開心與世界對話。行動工程師結合並展現臺科大學生應用所學動手做之能力，更在與人的相互關懷與互動中，了解傾聽對話，增加與人互動之溫度。前後梯次學員，雖來自不同年級與學院，卻藉參與此培育計畫有著共同的難忘回憶，學員在不同場域施作後，相互的傳承與交流，讓彼此間有著密不可分的默契，更呼應本計畫動手做外，對施作場域與團隊本身傾聽與對話的精神。行動工程師不僅有形的改變了施作場域，也使參與學員們與世界互動的心有了不一樣的感受。適逢今年疫情期間，各期學員們仍如同朋友般，關心著参與過的海內、外國家與各場域居民，維護著彼此間的情誼。' } },
        { id: uuidv4(), type: 'Text1', data: { text: '或許你也曾經跟這群富有執行力的行動工程師一樣，有著一個想為這個社會做些什麼的念頭，但是被課業、社團、亦或是愛情給耽誤了。如果當夜深人靜、午夜夢迴時還是有著那份悸動與一絲絲遺憾，或許該是時候為自己勇敢的任性一次了！也許會很辛苦，也或許會很感動，但可以確定的是為了不讓這份悸動再次落空，這次一定要積極的張開雙手，奮力地緊緊握住青春的尾巴，揮灑你的人生！' } },
        { id: uuidv4(), type: 'Link1', data: { url: 'https://www.2020usrexpo.org/column-detail.php?i=114', title: '大學社會責任實踐 行動工程師培育計畫GO！EIA' } },
      ],
      componentsEn: [],
      fbUrl: '',
      ytUrl: '',
      igUrl: '',
      webUrl: '',
    },
    {
      id: 3,
      time: new Date(),
      imgUrl: '/img/image1@2x.png',
      periods: '第三期',
      title: '共好2.0 : 走在我的身邊，成為我的夥伴',
      periodsEn: 'No.3',
      titleEn: 'NTUST X Project LETS Go!',
      components: [
        { id: uuidv4(), type: 'Title3', data: { title: '培育富有溫度、具備關懷與溝通力的綠色行動工程師' } },
        { id: uuidv4(), type: 'Text1', data: { text: '不知道你有沒有想過，需要多少的力量才足以改變你眼中的世界呢？答案或許有點出乎你的意料，因為你其實只需要幾個志同道合的朋友，抑或只要你願意挺身而出，便會有一群和你有著一樣信念的人們響應你的號召，而世界也會因此而發生改變，正所謂「德不孤，必有鄰」，行動工程師的團隊便是在這樣的因緣際會下產生的。正當我們常因閱讀或聆聽到「無國界醫生」的故事而感動時，本校劉志成副校長（時任工程學院院長）的一個「無國界工程師」點子，幾位學生與幾位教師，便開始讓世界的某個角落，慢慢開始發生改變。今天，就讓我們來一探究竟這群「行動工程師」的故事吧！' } },
        { id: uuidv4(), type: 'Text1', data: { text: '臺科大「行動工程師培育計畫」在早期尚未獲教育補助時，便已開始執行，至今已培育逾百名之計畫學員，臺科大在這國際連結之USR計畫中，依循聯合國永續發展第11項指標：「促使城市與人類居住聚落具包容、安全、韌性及永續性」，由參與之國際師生團隊透過實作改善社區基礎設施，培育新一代優質工程師，進而達到「居住永續安全、學校永續教育、社區永續發展」目標。而今年主要包括兩項規劃重點：首先是延續前期「行動工程師」的計畫精神，繼續深耕印尼及越南等新南向國家的偏鄉，改善當地的飲用水水質、環境衛生、教學資源及加強環境教育等。另一項重點則是本土偏鄉社區的災害預防與創生活化，這部分包括：邊坡移動災害預警設備建置、防災與環境教育宣導、社區文化資產（瞭望台、古道）修復與創生、生態池與魚菜共生系統建構等。臺科大素來以精湛的工程技術及極高的國際化程度聞名，行動工程師培育計畫希望學生透過這項的學習模式，訓練出善於傾聽、誠於對話、富有溫度與國際觀的工程師。' } },
        { id: uuidv4(), type: 'CaptionedImage', data: { url: '/img/image2@2x.png', text: '行動工程師之師生於印尼偏鄉社區設立灌溉系統' } },
        { id: uuidv4(), type: 'Title3', data: { title: '貼近當地需求，一起發現與解決問題，建立深厚夥伴朋友情誼' } },
        { id: uuidv4(), type: 'Text1', data: { text: '參與計畫的學生在過程中學習如何包容異文化、貼近不同文化生活、培養世界觀，也更加了解臺灣這片土地，最重要的是能將大學中所學到的知識和專業能力應用在生活中，甚至是到國外與他人交流，讓大學生學習到書本上學不到的實務應用經驗，希望能夠藉由大學生所學賦予社區更多不同的面貌。透過與部落人士共同舉辦歡迎開幕式，讓部落居民更加認識這群大學生工程師，與在地居民一同為部落努力並且對部落產生認同感，不預設自己是來服務部落，而是來認識不同生活背景的部落，更學習把場域部落當成自己的家園，讓學生在協助解決當地環境問題的過程中，能更貼近在地需求。透過多次與居民訪談、不斷的與居民溝通了解他們的需求，與夥伴們一同找到解法，也因此這群行動工程師與場域夥伴不只是單純的夥伴關係，更像是一起努力的朋友，除完成與場域居民討論後的相關施作，更留下學員與居民間寶貴的互動情誼。每次到部落，居民夥伴們總是熱情的迎接我們，部落小孩開心歡迎這些大哥哥大姊姊的到來，在離別的時候更是依依不捨，希望可以再次回到部落。' } },
        {
          type: 'Row',
          data: {
            components: [
              { id: uuidv4(), type: 'CaptionedImage', data: { url: '/img/image2@2x.png', text: '行動工程師之師生於印尼偏鄉社區設立灌溉系統' } },
              { id: uuidv4(), type: 'CaptionedImage', data: { url: '/img/image2@2x.png', text: '行動工程師之師生於印尼偏鄉社區設立灌溉系統' } },
            ]
          }
        },
        { id: uuidv4(), type: 'Title3', data: { title: '自動化坡地監測設備，提升部落環境安全與居民自主防災體認' } },
        {
          type: 'Row',
          data: {
            components: [
              { id: uuidv4(), type: 'Text1', data: { text: '行動工程師之師生訪談宜蘭四季國小校長及部落耆老後，發現部落對於山地環境的災害特徵變化，如擋土設施位移、地表開裂、地下水位上升等，皆感到憂心。而四季部落位於通往著名的觀光景點嘉羅湖四季林道上，一整年遊客不斷，部落也希望能藉由打造地方特色市集提供遊客更多認識部落的機會，因此環境安全顯得更加重要。行動工程師希望藉由設置自動化坡地監測設備，進行雨量、地下水位、地表變化等監測，將數據上傳至防災資料雲端平台，一方面做為提升部落居民自主防災的體認，二方面可以作為環境教育課程教材，以落實永續防災的概念，同時使學童及居民了解邊坡災害威脅，並提高災害徵兆的辨識能力。' } },
              { id: uuidv4(), type: 'CaptionedImage', data: { url: '/img/image2@2x.png', text: '行動工程師之師生於印尼偏鄉社區設立灌溉系統' } },
            ]
          }
        },
        { id: uuidv4(), type: 'Title3', data: { title: '舉辦互動式防災小學堂，進行防災深耕，帶動全校「豐」學習' } },
        { id: uuidv4(), type: 'Text1', data: { text: '為提升部落自主防災能力，行動工程師以「防災教育」與「校園永續」兩大核心為主軸，藉由知識整合、互助合作與尊重之防災營隊，舉辦防災小學堂活動，傳遞部落孩童相關知識，安排「手作自製簡易雨量量測筒」活動，透過遊戲 DIY 體驗量測原理，讓孩童自製雨水量測設備，並在家中簡易量測雨量及雨水酸鹼質檢測，認識酸雨與大氣環境。並且舉辦防災系列講座，透過互動課程傳遞防災相關知識予居民，並組織自主防災任務編組，進行防災演練，達到平時巡檢、災前疏散、災中應變以及災後復原的自救能力，增強防災深度廣度，有效助於部落實施自主防災。' } },
        { id: uuidv4(), type: 'Title3', data: { title: '滿足部落用水需求，加值永續衛生健康' } },
        {
          type: 'Row',
          data: {
            components: [
              { id: uuidv4(), type: 'CaptionedImage', data: { url: '/img/image2@2x.png', text: '教導四季國小學生製作簡易濾水系統，學童一起「豐」學習' } },
              { id: uuidv4(), type: 'Text1', data: { text: '四季部落生活用水主要來自於自然湧出之山泉水、未經淨化之河水、以及近年新增的淨化河水（即部落所謂的自來水）。在簡易自來水系統建置後，居民逐漸仰賴，但卻造成淨化廠容量不足之窘境。因此部落內部提出了新設淨水廠的計畫，然而部落用地嚴重不足，且淨水池之載重對於山地部落，有致災的疑慮。所以行動工程師希望運用在海外建置水資源系統之實務經驗，配合於部落的探查成果，檢視水資源利用現況，在避免新設淨化廠的前提下，滿足部落之用水需求。' } },
            ]
          }
        },
        { id: uuidv4(), type: 'Title3', data: { title: '抓住青春的尾巴，勇敢的揮灑一次！' } },
        { id: uuidv4(), type: 'Text1', data: { text: '學；也有從大四到碩士畢業前投入計畫，想看看自己幾年來所學如何結合到實作中；更有畢業後仍不遺餘力回來傳承的校友，除了到新場域貢獻以往施作經驗，也享受著再次跟大家一起捲起袖子動手做並打開心與世界對話。行動工程師結合並展現臺科大學生應用所學動手做之能力，更在與人的相互關懷與互動中，了解傾聽對話，增加與人互動之溫度。前後梯次學員，雖來自不同年級與學院，卻藉參與此培育計畫有著共同的難忘回憶，學員在不同場域施作後，相互的傳承與交流，讓彼此間有著密不可分的默契，更呼應本計畫動手做外，對施作場域與團隊本身傾聽與對話的精神。行動工程師不僅有形的改變了施作場域，也使參與學員們與世界互動的心有了不一樣的感受。適逢今年疫情期間，各期學員們仍如同朋友般，關心著参與過的海內、外國家與各場域居民，維護著彼此間的情誼。' } },
        { id: uuidv4(), type: 'Text1', data: { text: '或許你也曾經跟這群富有執行力的行動工程師一樣，有著一個想為這個社會做些什麼的念頭，但是被課業、社團、亦或是愛情給耽誤了。如果當夜深人靜、午夜夢迴時還是有著那份悸動與一絲絲遺憾，或許該是時候為自己勇敢的任性一次了！也許會很辛苦，也或許會很感動，但可以確定的是為了不讓這份悸動再次落空，這次一定要積極的張開雙手，奮力地緊緊握住青春的尾巴，揮灑你的人生！' } },
        { id: uuidv4(), type: 'Link1', data: { url: 'https://www.2020usrexpo.org/column-detail.php?i=114', title: '大學社會責任實踐 行動工程師培育計畫GO！EIA' } },
      ],
      componentsEn: [],
      fbUrl: '',
      ytUrl: '',
      igUrl: '',
      webUrl: '',
    },
    {
      id: 2,
      time: new Date(),
      imgUrl: '/img/image1@2x.png',
      periods: '第二期',
      title: '城鄉牽手，以場域移動力創造新價值',
      periodsEn: 'No.2',
      titleEn: 'Urban-Rural Collaboration : Mobility for …',
      components: [
        { id: uuidv4(), type: 'Title3', data: { title: '培育富有溫度、具備關懷與溝通力的綠色行動工程師' } },
        { id: uuidv4(), type: 'Text1', data: { text: '不知道你有沒有想過，需要多少的力量才足以改變你眼中的世界呢？答案或許有點出乎你的意料，因為你其實只需要幾個志同道合的朋友，抑或只要你願意挺身而出，便會有一群和你有著一樣信念的人們響應你的號召，而世界也會因此而發生改變，正所謂「德不孤，必有鄰」，行動工程師的團隊便是在這樣的因緣際會下產生的。正當我們常因閱讀或聆聽到「無國界醫生」的故事而感動時，本校劉志成副校長（時任工程學院院長）的一個「無國界工程師」點子，幾位學生與幾位教師，便開始讓世界的某個角落，慢慢開始發生改變。今天，就讓我們來一探究竟這群「行動工程師」的故事吧！' } },
        { id: uuidv4(), type: 'Text1', data: { text: '臺科大「行動工程師培育計畫」在早期尚未獲教育補助時，便已開始執行，至今已培育逾百名之計畫學員，臺科大在這國際連結之USR計畫中，依循聯合國永續發展第11項指標：「促使城市與人類居住聚落具包容、安全、韌性及永續性」，由參與之國際師生團隊透過實作改善社區基礎設施，培育新一代優質工程師，進而達到「居住永續安全、學校永續教育、社區永續發展」目標。而今年主要包括兩項規劃重點：首先是延續前期「行動工程師」的計畫精神，繼續深耕印尼及越南等新南向國家的偏鄉，改善當地的飲用水水質、環境衛生、教學資源及加強環境教育等。另一項重點則是本土偏鄉社區的災害預防與創生活化，這部分包括：邊坡移動災害預警設備建置、防災與環境教育宣導、社區文化資產（瞭望台、古道）修復與創生、生態池與魚菜共生系統建構等。臺科大素來以精湛的工程技術及極高的國際化程度聞名，行動工程師培育計畫希望學生透過這項的學習模式，訓練出善於傾聽、誠於對話、富有溫度與國際觀的工程師。' } },
        { id: uuidv4(), type: 'CaptionedImage', data: { url: '/img/image2@2x.png', text: '行動工程師之師生於印尼偏鄉社區設立灌溉系統' } },
        { id: uuidv4(), type: 'Title3', data: { title: '貼近當地需求，一起發現與解決問題，建立深厚夥伴朋友情誼' } },
        { id: uuidv4(), type: 'Text1', data: { text: '參與計畫的學生在過程中學習如何包容異文化、貼近不同文化生活、培養世界觀，也更加了解臺灣這片土地，最重要的是能將大學中所學到的知識和專業能力應用在生活中，甚至是到國外與他人交流，讓大學生學習到書本上學不到的實務應用經驗，希望能夠藉由大學生所學賦予社區更多不同的面貌。透過與部落人士共同舉辦歡迎開幕式，讓部落居民更加認識這群大學生工程師，與在地居民一同為部落努力並且對部落產生認同感，不預設自己是來服務部落，而是來認識不同生活背景的部落，更學習把場域部落當成自己的家園，讓學生在協助解決當地環境問題的過程中，能更貼近在地需求。透過多次與居民訪談、不斷的與居民溝通了解他們的需求，與夥伴們一同找到解法，也因此這群行動工程師與場域夥伴不只是單純的夥伴關係，更像是一起努力的朋友，除完成與場域居民討論後的相關施作，更留下學員與居民間寶貴的互動情誼。每次到部落，居民夥伴們總是熱情的迎接我們，部落小孩開心歡迎這些大哥哥大姊姊的到來，在離別的時候更是依依不捨，希望可以再次回到部落。' } },
        {
          type: 'Row',
          data: {
            components: [
              { id: uuidv4(), type: 'CaptionedImage', data: { url: '/img/image2@2x.png', text: '行動工程師之師生於印尼偏鄉社區設立灌溉系統' } },
              { id: uuidv4(), type: 'CaptionedImage', data: { url: '/img/image2@2x.png', text: '行動工程師之師生於印尼偏鄉社區設立灌溉系統' } },
            ]
          }
        },
        { id: uuidv4(), type: 'Title3', data: { title: '自動化坡地監測設備，提升部落環境安全與居民自主防災體認' } },
        {
          type: 'Row',
          data: {
            components: [
              { id: uuidv4(), type: 'Text1', data: { text: '行動工程師之師生訪談宜蘭四季國小校長及部落耆老後，發現部落對於山地環境的災害特徵變化，如擋土設施位移、地表開裂、地下水位上升等，皆感到憂心。而四季部落位於通往著名的觀光景點嘉羅湖四季林道上，一整年遊客不斷，部落也希望能藉由打造地方特色市集提供遊客更多認識部落的機會，因此環境安全顯得更加重要。行動工程師希望藉由設置自動化坡地監測設備，進行雨量、地下水位、地表變化等監測，將數據上傳至防災資料雲端平台，一方面做為提升部落居民自主防災的體認，二方面可以作為環境教育課程教材，以落實永續防災的概念，同時使學童及居民了解邊坡災害威脅，並提高災害徵兆的辨識能力。' } },
              { id: uuidv4(), type: 'CaptionedImage', data: { url: '/img/image2@2x.png', text: '行動工程師之師生於印尼偏鄉社區設立灌溉系統' } },
            ]
          }
        },
        { id: uuidv4(), type: 'Title3', data: { title: '舉辦互動式防災小學堂，進行防災深耕，帶動全校「豐」學習' } },
        { id: uuidv4(), type: 'Text1', data: { text: '為提升部落自主防災能力，行動工程師以「防災教育」與「校園永續」兩大核心為主軸，藉由知識整合、互助合作與尊重之防災營隊，舉辦防災小學堂活動，傳遞部落孩童相關知識，安排「手作自製簡易雨量量測筒」活動，透過遊戲 DIY 體驗量測原理，讓孩童自製雨水量測設備，並在家中簡易量測雨量及雨水酸鹼質檢測，認識酸雨與大氣環境。並且舉辦防災系列講座，透過互動課程傳遞防災相關知識予居民，並組織自主防災任務編組，進行防災演練，達到平時巡檢、災前疏散、災中應變以及災後復原的自救能力，增強防災深度廣度，有效助於部落實施自主防災。' } },
        { id: uuidv4(), type: 'Title3', data: { title: '滿足部落用水需求，加值永續衛生健康' } },
        {
          type: 'Row',
          data: {
            components: [
              { id: uuidv4(), type: 'CaptionedImage', data: { url: '/img/image2@2x.png', text: '教導四季國小學生製作簡易濾水系統，學童一起「豐」學習' } },
              { id: uuidv4(), type: 'Text1', data: { text: '四季部落生活用水主要來自於自然湧出之山泉水、未經淨化之河水、以及近年新增的淨化河水（即部落所謂的自來水）。在簡易自來水系統建置後，居民逐漸仰賴，但卻造成淨化廠容量不足之窘境。因此部落內部提出了新設淨水廠的計畫，然而部落用地嚴重不足，且淨水池之載重對於山地部落，有致災的疑慮。所以行動工程師希望運用在海外建置水資源系統之實務經驗，配合於部落的探查成果，檢視水資源利用現況，在避免新設淨化廠的前提下，滿足部落之用水需求。' } },
            ]
          }
        },
        { id: uuidv4(), type: 'Title3', data: { title: '抓住青春的尾巴，勇敢的揮灑一次！' } },
        { id: uuidv4(), type: 'Text1', data: { text: '學；也有從大四到碩士畢業前投入計畫，想看看自己幾年來所學如何結合到實作中；更有畢業後仍不遺餘力回來傳承的校友，除了到新場域貢獻以往施作經驗，也享受著再次跟大家一起捲起袖子動手做並打開心與世界對話。行動工程師結合並展現臺科大學生應用所學動手做之能力，更在與人的相互關懷與互動中，了解傾聽對話，增加與人互動之溫度。前後梯次學員，雖來自不同年級與學院，卻藉參與此培育計畫有著共同的難忘回憶，學員在不同場域施作後，相互的傳承與交流，讓彼此間有著密不可分的默契，更呼應本計畫動手做外，對施作場域與團隊本身傾聽與對話的精神。行動工程師不僅有形的改變了施作場域，也使參與學員們與世界互動的心有了不一樣的感受。適逢今年疫情期間，各期學員們仍如同朋友般，關心著参與過的海內、外國家與各場域居民，維護著彼此間的情誼。' } },
        { id: uuidv4(), type: 'Text1', data: { text: '或許你也曾經跟這群富有執行力的行動工程師一樣，有著一個想為這個社會做些什麼的念頭，但是被課業、社團、亦或是愛情給耽誤了。如果當夜深人靜、午夜夢迴時還是有著那份悸動與一絲絲遺憾，或許該是時候為自己勇敢的任性一次了！也許會很辛苦，也或許會很感動，但可以確定的是為了不讓這份悸動再次落空，這次一定要積極的張開雙手，奮力地緊緊握住青春的尾巴，揮灑你的人生！' } },
        { id: uuidv4(), type: 'Link1', data: { url: 'https://www.2020usrexpo.org/column-detail.php?i=114', title: '大學社會責任實踐 行動工程師培育計畫GO！EIA' } },
      ],
      componentsEn: [],
      fbUrl: '',
      ytUrl: '',
      igUrl: '',
      webUrl: '',
    },
    {
      id: 1,
      time: new Date(),
      imgUrl: '/img/image1@2x.png',
      periods: '第一期',
      title: '行動工程師前往偏鄉協助地方基礎建設',
      periodsEn: 'No.1',
      titleEn: 'Engineers in Action travel to rural areas to ...',
      components: [
        { id: uuidv4(), type: 'Title3', data: { title: '培育富有溫度、具備關懷與溝通力的綠色行動工程師' } },
        { id: uuidv4(), type: 'Text1', data: { text: '不知道你有沒有想過，需要多少的力量才足以改變你眼中的世界呢？答案或許有點出乎你的意料，因為你其實只需要幾個志同道合的朋友，抑或只要你願意挺身而出，便會有一群和你有著一樣信念的人們響應你的號召，而世界也會因此而發生改變，正所謂「德不孤，必有鄰」，行動工程師的團隊便是在這樣的因緣際會下產生的。正當我們常因閱讀或聆聽到「無國界醫生」的故事而感動時，本校劉志成副校長（時任工程學院院長）的一個「無國界工程師」點子，幾位學生與幾位教師，便開始讓世界的某個角落，慢慢開始發生改變。今天，就讓我們來一探究竟這群「行動工程師」的故事吧！' } },
        { id: uuidv4(), type: 'Text1', data: { text: '臺科大「行動工程師培育計畫」在早期尚未獲教育補助時，便已開始執行，至今已培育逾百名之計畫學員，臺科大在這國際連結之USR計畫中，依循聯合國永續發展第11項指標：「促使城市與人類居住聚落具包容、安全、韌性及永續性」，由參與之國際師生團隊透過實作改善社區基礎設施，培育新一代優質工程師，進而達到「居住永續安全、學校永續教育、社區永續發展」目標。而今年主要包括兩項規劃重點：首先是延續前期「行動工程師」的計畫精神，繼續深耕印尼及越南等新南向國家的偏鄉，改善當地的飲用水水質、環境衛生、教學資源及加強環境教育等。另一項重點則是本土偏鄉社區的災害預防與創生活化，這部分包括：邊坡移動災害預警設備建置、防災與環境教育宣導、社區文化資產（瞭望台、古道）修復與創生、生態池與魚菜共生系統建構等。臺科大素來以精湛的工程技術及極高的國際化程度聞名，行動工程師培育計畫希望學生透過這項的學習模式，訓練出善於傾聽、誠於對話、富有溫度與國際觀的工程師。' } },
        { id: uuidv4(), type: 'CaptionedImage', data: { url: '/img/image2@2x.png', text: '行動工程師之師生於印尼偏鄉社區設立灌溉系統' } },
        { id: uuidv4(), type: 'Title3', data: { title: '貼近當地需求，一起發現與解決問題，建立深厚夥伴朋友情誼' } },
        { id: uuidv4(), type: 'Text1', data: { text: '參與計畫的學生在過程中學習如何包容異文化、貼近不同文化生活、培養世界觀，也更加了解臺灣這片土地，最重要的是能將大學中所學到的知識和專業能力應用在生活中，甚至是到國外與他人交流，讓大學生學習到書本上學不到的實務應用經驗，希望能夠藉由大學生所學賦予社區更多不同的面貌。透過與部落人士共同舉辦歡迎開幕式，讓部落居民更加認識這群大學生工程師，與在地居民一同為部落努力並且對部落產生認同感，不預設自己是來服務部落，而是來認識不同生活背景的部落，更學習把場域部落當成自己的家園，讓學生在協助解決當地環境問題的過程中，能更貼近在地需求。透過多次與居民訪談、不斷的與居民溝通了解他們的需求，與夥伴們一同找到解法，也因此這群行動工程師與場域夥伴不只是單純的夥伴關係，更像是一起努力的朋友，除完成與場域居民討論後的相關施作，更留下學員與居民間寶貴的互動情誼。每次到部落，居民夥伴們總是熱情的迎接我們，部落小孩開心歡迎這些大哥哥大姊姊的到來，在離別的時候更是依依不捨，希望可以再次回到部落。' } },
        {
          type: 'Row',
          data: {
            components: [
              { id: uuidv4(), type: 'CaptionedImage', data: { url: '/img/image2@2x.png', text: '行動工程師之師生於印尼偏鄉社區設立灌溉系統' } },
              { id: uuidv4(), type: 'CaptionedImage', data: { url: '/img/image2@2x.png', text: '行動工程師之師生於印尼偏鄉社區設立灌溉系統' } },
            ]
          }
        },
        { id: uuidv4(), type: 'Title3', data: { title: '自動化坡地監測設備，提升部落環境安全與居民自主防災體認' } },
        {
          type: 'Row',
          data: {
            components: [
              { id: uuidv4(), type: 'Text1', data: { text: '行動工程師之師生訪談宜蘭四季國小校長及部落耆老後，發現部落對於山地環境的災害特徵變化，如擋土設施位移、地表開裂、地下水位上升等，皆感到憂心。而四季部落位於通往著名的觀光景點嘉羅湖四季林道上，一整年遊客不斷，部落也希望能藉由打造地方特色市集提供遊客更多認識部落的機會，因此環境安全顯得更加重要。行動工程師希望藉由設置自動化坡地監測設備，進行雨量、地下水位、地表變化等監測，將數據上傳至防災資料雲端平台，一方面做為提升部落居民自主防災的體認，二方面可以作為環境教育課程教材，以落實永續防災的概念，同時使學童及居民了解邊坡災害威脅，並提高災害徵兆的辨識能力。' } },
              { id: uuidv4(), type: 'CaptionedImage', data: { url: '/img/image2@2x.png', text: '行動工程師之師生於印尼偏鄉社區設立灌溉系統' } },
            ]
          }
        },
        { id: uuidv4(), type: 'Title3', data: { title: '舉辦互動式防災小學堂，進行防災深耕，帶動全校「豐」學習' } },
        { id: uuidv4(), type: 'Text1', data: { text: '為提升部落自主防災能力，行動工程師以「防災教育」與「校園永續」兩大核心為主軸，藉由知識整合、互助合作與尊重之防災營隊，舉辦防災小學堂活動，傳遞部落孩童相關知識，安排「手作自製簡易雨量量測筒」活動，透過遊戲 DIY 體驗量測原理，讓孩童自製雨水量測設備，並在家中簡易量測雨量及雨水酸鹼質檢測，認識酸雨與大氣環境。並且舉辦防災系列講座，透過互動課程傳遞防災相關知識予居民，並組織自主防災任務編組，進行防災演練，達到平時巡檢、災前疏散、災中應變以及災後復原的自救能力，增強防災深度廣度，有效助於部落實施自主防災。' } },
        { id: uuidv4(), type: 'Title3', data: { title: '滿足部落用水需求，加值永續衛生健康' } },
        {
          type: 'Row',
          data: {
            components: [
              { id: uuidv4(), type: 'CaptionedImage', data: { url: '/img/image2@2x.png', text: '教導四季國小學生製作簡易濾水系統，學童一起「豐」學習' } },
              { id: uuidv4(), type: 'Text1', data: { text: '四季部落生活用水主要來自於自然湧出之山泉水、未經淨化之河水、以及近年新增的淨化河水（即部落所謂的自來水）。在簡易自來水系統建置後，居民逐漸仰賴，但卻造成淨化廠容量不足之窘境。因此部落內部提出了新設淨水廠的計畫，然而部落用地嚴重不足，且淨水池之載重對於山地部落，有致災的疑慮。所以行動工程師希望運用在海外建置水資源系統之實務經驗，配合於部落的探查成果，檢視水資源利用現況，在避免新設淨化廠的前提下，滿足部落之用水需求。' } },
            ]
          }
        },
        { id: uuidv4(), type: 'Title3', data: { title: '抓住青春的尾巴，勇敢的揮灑一次！' } },
        { id: uuidv4(), type: 'Text1', data: { text: '學；也有從大四到碩士畢業前投入計畫，想看看自己幾年來所學如何結合到實作中；更有畢業後仍不遺餘力回來傳承的校友，除了到新場域貢獻以往施作經驗，也享受著再次跟大家一起捲起袖子動手做並打開心與世界對話。行動工程師結合並展現臺科大學生應用所學動手做之能力，更在與人的相互關懷與互動中，了解傾聽對話，增加與人互動之溫度。前後梯次學員，雖來自不同年級與學院，卻藉參與此培育計畫有著共同的難忘回憶，學員在不同場域施作後，相互的傳承與交流，讓彼此間有著密不可分的默契，更呼應本計畫動手做外，對施作場域與團隊本身傾聽與對話的精神。行動工程師不僅有形的改變了施作場域，也使參與學員們與世界互動的心有了不一樣的感受。適逢今年疫情期間，各期學員們仍如同朋友般，關心著参與過的海內、外國家與各場域居民，維護著彼此間的情誼。' } },
        { id: uuidv4(), type: 'Text1', data: { text: '或許你也曾經跟這群富有執行力的行動工程師一樣，有著一個想為這個社會做些什麼的念頭，但是被課業、社團、亦或是愛情給耽誤了。如果當夜深人靜、午夜夢迴時還是有著那份悸動與一絲絲遺憾，或許該是時候為自己勇敢的任性一次了！也許會很辛苦，也或許會很感動，但可以確定的是為了不讓這份悸動再次落空，這次一定要積極的張開雙手，奮力地緊緊握住青春的尾巴，揮灑你的人生！' } },
        { id: uuidv4(), type: 'Link1', data: { url: 'https://www.2020usrexpo.org/column-detail.php?i=114', title: '大學社會責任實踐 行動工程師培育計畫GO！EIA' } },
      ],
      componentsEn: [],
      fbUrl: '',
      ytUrl: '',
      igUrl: '',
      webUrl: '',
    },
  ],
  about: {
    imgUrl: '/img/image1@2x.png',
    historyProjects: [
      {
        title: '試辦',
        year: '106-107',
        items: [
          { type: '萌芽型', name: 'I-Villages城鄉牽手計畫' },
          { type: '深耕型', name: '邁向可持續性成長的安全綠社區' },
        ]
      },
      {
        title: '第一期',
        year: '107-108',
        items: [
          { type: '種子型', name: '共好：走在我的身邊成為我的夥伴' },
          { type: '萌芽型', name: '無國界行動工程師培育計畫' },
          { type: '萌芽型', name: 'I-Villages城鄉牽手計畫' },
        ]
      },
      {
        title: '第二期',
        year: '109-111',
        items: [
          { type: '大學特色類萌芽型', name: '社區更新活化與韌性提升-以公館城南聚落為例' },
          { type: '大學特色類萌芽型', name: 'i設計 愛學習-營造老少共學的多媒體互動智慧生活環境' },
          { type: '大學特色類萌芽型', name: '共好2.0：走在我的身邊，成為我的夥伴' },
          { type: '大學特色類萌芽型', name: 'I-Villages Plus城鄉牽手深化計畫' },
          { type: '國際連結類深耕型', name: '行動工程師培育計畫' },
        ]
      },
      {
        title: '',
        year: '109-111',
        items: [
          { type: 'USR Hub', name: '跨國合作改善在地水產養殖環境' },
        ]
      },
    ],
    educationCenterImgUrl: '/img/about/course@2x.png',
    educationCenterUrl: 'https://cla.ntust.edu.tw/p/412-1076-8604.php?Lang=zh',
    teamMemberImgUrl: '/img/about/team@2x.png',
    teamMemberImgUrlMobile: '/img/about/team-m@2x.png',
    projects: [
      {
        name: 'A.行動工程師培育計畫',
        members: [
          {
            id: '1',
            url: '/img/about/people1@2x.png',
            type: '其它成員',
            name: '蘇韻涵',
            job: '/行政專員/',
            school: '國立臺灣科技大學',
            department: '人文社會學院',
          },
          {
            id: '2',
            url: '/img/about/people2@2x.png',
            type: '其它成員',
            name: '蘇韻涵',
            job: '/行政專員/',
            school: '國立臺灣科技大學',
            department: '人文社會學院',
          },
          {
            id: '3',
            url: '/img/about/people3@2x.png',
            type: '其它成員',
            name: '蘇韻涵',
            job: '/行政專員/',
            school: '國立臺灣科技大學',
            department: '人文社會學院',
          },
          {
            id: '4',
            url: '/img/about/people4@2x.png',
            type: '其它成員',
            name: '蘇韻涵',
            job: '/行政專員/',
            school: '國立臺灣科技大學',
            department: '人文社會學院',
          },
        ],
      }, {
        name: 'B.I-Villages Plus城鄉牽手深化計畫',
        members: [
          {
            id: '1',
            url: '/img/about/people1@2x.png',
            type: '其它成員',
            name: '蘇韻涵',
            job: '/行政專員/',
            school: '國立臺灣科技大學',
            department: '人文社會學院',
          },
          {
            id: '2',
            url: '/img/about/people2@2x.png',
            type: '其它成員',
            name: '蘇韻涵',
            job: '/行政專員/',
            school: '國立臺灣科技大學',
            department: '人文社會學院',
          },
          {
            id: '3',
            url: '/img/about/people3@2x.png',
            type: '其它成員',
            name: '蘇韻涵',
            job: '/行政專員/',
            school: '國立臺灣科技大學',
            department: '人文社會學院',
          },
          {
            id: '4',
            url: '/img/about/people4@2x.png',
            type: '其它成員',
            name: '蘇韻涵',
            job: '/行政專員/',
            school: '國立臺灣科技大學',
            department: '人文社會學院',
          },
        ],
      }, {
        name: 'C.社區更新活化與韌性提升-以公館城南聚落為例',
        members: [
          {
            id: '1',
            url: '/img/about/people1@2x.png',
            type: '其它成員',
            name: '蘇韻涵',
            job: '/行政專員/',
            school: '國立臺灣科技大學',
            department: '人文社會學院',
          },
          {
            id: '2',
            url: '/img/about/people2@2x.png',
            type: '其它成員',
            name: '蘇韻涵',
            job: '/行政專員/',
            school: '國立臺灣科技大學',
            department: '人文社會學院',
          },
          {
            id: '3',
            url: '/img/about/people3@2x.png',
            type: '其它成員',
            name: '蘇韻涵',
            job: '/行政專員/',
            school: '國立臺灣科技大學',
            department: '人文社會學院',
          },
          {
            id: '4',
            url: '/img/about/people4@2x.png',
            type: '其它成員',
            name: '蘇韻涵',
            job: '/行政專員/',
            school: '國立臺灣科技大學',
            department: '人文社會學院',
          },
        ],
      }, {
        name: 'D.i設計愛學習-營造老少共學的多媒體互動智慧生活環境',
        members: [
          {
            id: '1',
            url: '/img/about/people1@2x.png',
            type: '其它成員',
            name: '蘇韻涵',
            job: '/行政專員/',
            school: '國立臺灣科技大學',
            department: '人文社會學院',
          },
          {
            id: '2',
            url: '/img/about/people2@2x.png',
            type: '其它成員',
            name: '蘇韻涵',
            job: '/行政專員/',
            school: '國立臺灣科技大學',
            department: '人文社會學院',
          },
          {
            id: '3',
            url: '/img/about/people3@2x.png',
            type: '其它成員',
            name: '蘇韻涵',
            job: '/行政專員/',
            school: '國立臺灣科技大學',
            department: '人文社會學院',
          },
          {
            id: '4',
            url: '/img/about/people4@2x.png',
            type: '其它成員',
            name: '蘇韻涵',
            job: '/行政專員/',
            school: '國立臺灣科技大學',
            department: '人文社會學院',
          },
        ],
      }, {
        name: 'E.共好2.0：走在我的身邊，成為我的夥伴',
        members: [
          {
            id: '1',
            url: '/img/about/people1@2x.png',
            type: '其它成員',
            name: '蘇韻涵',
            job: '/行政專員/',
            school: '國立臺灣科技大學',
            department: '人文社會學院',
          },
          {
            id: '2',
            url: '/img/about/people2@2x.png',
            type: '其它成員',
            name: '蘇韻涵',
            job: '/行政專員/',
            school: '國立臺灣科技大學',
            department: '人文社會學院',
          },
          {
            id: '3',
            url: '/img/about/people3@2x.png',
            type: '其它成員',
            name: '蘇韻涵',
            job: '/行政專員/',
            school: '國立臺灣科技大學',
            department: '人文社會學院',
          },
          {
            id: '4',
            url: '/img/about/people4@2x.png',
            type: '其它成員',
            name: '蘇韻涵',
            job: '/行政專員/',
            school: '國立臺灣科技大學',
            department: '人文社會學院',
          },
        ],
      }, {
        name: 'F.跨國合作改善在地水產養殖環境',
        members: [
          {
            id: '1',
            url: '/img/about/people1@2x.png',
            type: '其它成員',
            name: '蘇韻涵',
            job: '/行政專員/',
            school: '國立臺灣科技大學',
            department: '人文社會學院',
          },
          {
            id: '2',
            url: '/img/about/people2@2x.png',
            type: '其它成員',
            name: '蘇韻涵',
            job: '/行政專員/',
            school: '國立臺灣科技大學',
            department: '人文社會學院',
          },
          {
            id: '3',
            url: '/img/about/people3@2x.png',
            type: '其它成員',
            name: '蘇韻涵',
            job: '/行政專員/',
            school: '國立臺灣科技大學',
            department: '人文社會學院',
          },
          {
            id: '4',
            url: '/img/about/people4@2x.png',
            type: '其它成員',
            name: '蘇韻涵',
            job: '/行政專員/',
            school: '國立臺灣科技大學',
            department: '人文社會學院',
          },
        ],
      }
    ]
  },
  projectHome: {
    imgUrl: '/img/image1@2x.png',
  },
  projects: [
    {
      sessionNo: 2,
      sessionName: '第二期 (109-111年)',
      order: 0,
      id: 1,
      image: '/img/projects/project@2x.png',
      title: '行動工程師培育計畫',
      text: '臺科大團隊邀請公館水源地區之富水里、水源里、學府里及國防部共同參與，結合社區推動防救災工作，協助社區進行防災規劃，透過融入歷史建築更新活化議題，加深居民連結，加深居民連結，加深居民連結，加深居民連結，加深居民連結，加深居民連結，加深居民結。',
      fbUrl: 'https://www.facebook.com/',
      ytUrl: 'https://www.youtube.com/',
      igUrl: 'https://www.instagram.com/',
      webUrl: 'https://d37hndef7an9tf.cloudfront.net/',
      managerEmail: '',
      components: [
        { id: uuidv4(), type: 'Title2', data: { title: '/計畫簡介/' } },
        { id: uuidv4(), type: 'Text1', data: { text: '本計畫旨在培育「行動工程師」，以基礎工程技術為出發點，喚醒學生的社會責任，培養出溝通的、關懷的、綠色的新一代工程師。' } },
        { id: uuidv4(), type: 'Text1', data: { text: '授與學生們透過專案學習(PBL)與印尼、越南姐妹校以及宜蘭東澳國小與四季國小師生合作，結合年輕人對於 貢獻社會及國際行動的渴望，創造多元的參與方案，讓同學從社區到國外，從臺灣走向國際，發光發亮。 此外，藉由本計劃，讓參與學生能親身的、深入的了解東南亞國家及台灣原民部落風土民情和社會現況，在與當地人 相處過程中更加認識彼此，並且在日後能與不同文化背景的居民在尊重和了解的基礎上，建立友善和緊密的夥伴關 係。' } },
        { id: uuidv4(), type: 'Text1', data: { text: '為強化行動工程師的實質運作內涵，在參與團隊的選擇與組織運作方面，積極整合校內跨領域系所專業且熱血教 師，包括化工、材料、營建、設計及機械等系所、「行動工程師」團隊學生(含本校本籍生與外籍生)，以及印、越姐 妹校師生參與，期盼藉由校內外的資源整合與建立雙邊學校共學共好機制，厚植本校務實致用與大學社會責任實踐的 體現。' } },
        { id: uuidv4(), type: 'Title2', data: { title: '/計畫理念/' } },
        { id: uuidv4(), type: 'Text1', data: { text: '隨著城鄉發展思維變化及配合政府「新南向政策」，為善盡社會責任，國立臺灣科技大學從防災淨水之特色教學與社會關懷出發，擇定永續關懷主題，提出「行動工程師培育」計畫。' } },
        { id: uuidv4(), type: 'Text1', data: { text: '在智慧與科技應用下，透過本計畫之實踐新思維，提高在地居民對防災與環境問題的知識，意識到問題的正確解決方案，並有能力去解決這些問題，在全球各地中傳遞對自然尊重、在地人文與環境教育，為社區營造真正的永續環境，進而提高全' } },
        { id: uuidv4(), type: 'Title2', data: { title: '/推動場域/' } },
        {
          id: uuidv4(),
          type: 'Carousel1',
          data: {
            slides: [
              {
                imgUrl: '/img/project/Group311@2x.png',
                title: '印尼泗水Kedung Cowek, Kec. Bulak 漁村社區',
                content: '郊區村落資源匱乏，當地許多違建並無自來水 及浴廁等設施。團隊將著重於基礎衛生建設、 強化環境與衛生教育。',
              }, {
                imgUrl: '/img/project/Group311@2x.png',
                title: '印尼泗水Kedung Cowek, Kec. Bulak 漁村社區',
                content: '郊區村落資源匱乏，當地許多違建並無自來水 及浴廁等設施。團隊將著重於基礎衛生建設、 強化環境與衛生教育。',
              }, {
                imgUrl: '/img/project/Group311@2x.png',
                title: '印尼泗水Kedung Cowek, Kec. Bulak 漁村社區',
                content: '郊區村落資源匱乏，當地許多違建並無自來水 及浴廁等設施。團隊將著重於基礎衛生建設、 強化環境與衛生教育。',
              }, {
                imgUrl: '/img/project/Group311@2x.png',
                title: '印尼泗水Kedung Cowek, Kec. Bulak 漁村社區',
                content: '郊區村落資源匱乏，當地許多違建並無自來水 及浴廁等設施。團隊將著重於基礎衛生建設、 強化環境與衛生教育。',
              }, {
                imgUrl: '/img/project/Group311@2x.png',
                title: '印尼泗水Kedung Cowek, Kec. Bulak 漁村社區',
                content: '郊區村落資源匱乏，當地許多違建並無自來水 及浴廁等設施。團隊將著重於基礎衛生建設、 強化環境與衛生教育。',
              }, {
                imgUrl: '/img/project/Group311@2x.png',
                title: '印尼泗水Kedung Cowek, Kec. Bulak 漁村社區',
                content: '郊區村落資源匱乏，當地許多違建並無自來水 及浴廁等設施。團隊將著重於基礎衛生建設、 強化環境與衛生教育。',
              },
            ]
          }
        },
        { id: uuidv4(), type: 'Title2', data: { title: '/對應SDGs/' } },
        {
          id: uuidv4(),
          type: 'List2',
          data: {
            list: [
              {
                imgSrc: '/img/project/Rectangle123@2x.png',
                title: '6.永續水與衛生資源',
                text: '師生經盤點後，將村落所需的永續項目進行實踐，如潔淨生活用水、校園廁所翻修。',
              },
              {
                imgSrc: '/img/project/Rectangle121@2x.png',
                title: '11.社區安全與韌性',
                text: '於村落進行永續教育課程、社區防災監測以及文化保護。藉由設置自動化坡地監測設備，建置防災資料雲端平台，作為環境教育課程教材，以落實永續防災的概念。',
              },
              {
                imgSrc: '/img/project/Rectangle122@2x.png',
                title: '17.夥伴關係',
                text: '透過動員及分享知識、專業與科技等支援，協助當地部落實現永續發展目標。',
              },
            ]
          }
        },
        { id: uuidv4(), type: 'CaptionedImage', data: { url: '/img/project/Group307@2x.png', text: '印尼東爪哇省 Puhsarang Kediri 高地' } },
      ]
    },
    {
      sessionNo: 2,
      sessionName: '第二期 (109-111年)',
      order: 1,
      id: 2,
      image: '/img/projects/project@2x.png',
      title: 'I-Villages Plus城鄉牽手深化計畫',
      text: '臺科大團隊邀請公館水源地區之富水里、水源里、學府里及國防部共同參與，結合社區推動防救災工作，協助社區進行防災規劃，透過融入歷史建築更新活化議題，加深居民連結。協助社區進行防災規劃，透過融入歷史建築更新活化議題，加深居民連結。',
      fbUrl: 'https://www.facebook.com/',
      ytUrl: 'https://www.youtube.com/',
      igUrl: 'https://www.instagram.com/',
      webUrl: 'https://d37hndef7an9tf.cloudfront.net/',
      managerEmail: '',
      components: [
        { id: uuidv4(), type: 'Title2', data: { title: '/計畫簡介/' } },
        { id: uuidv4(), type: 'Text1', data: { text: '本計畫旨在培育「行動工程師」，以基礎工程技術為出發點，喚醒學生的社會責任，培養出溝通的、關懷的、綠色的新一代工程師。' } },
        { id: uuidv4(), type: 'Text1', data: { text: '授與學生們透過專案學習(PBL)與印尼、越南姐妹校以及宜蘭東澳國小與四季國小師生合作，結合年輕人對於 貢獻社會及國際行動的渴望，創造多元的參與方案，讓同學從社區到國外，從臺灣走向國際，發光發亮。 此外，藉由本計劃，讓參與學生能親身的、深入的了解東南亞國家及台灣原民部落風土民情和社會現況，在與當地人 相處過程中更加認識彼此，並且在日後能與不同文化背景的居民在尊重和了解的基礎上，建立友善和緊密的夥伴關 係。' } },
        { id: uuidv4(), type: 'Text1', data: { text: '為強化行動工程師的實質運作內涵，在參與團隊的選擇與組織運作方面，積極整合校內跨領域系所專業且熱血教 師，包括化工、材料、營建、設計及機械等系所、「行動工程師」團隊學生(含本校本籍生與外籍生)，以及印、越姐 妹校師生參與，期盼藉由校內外的資源整合與建立雙邊學校共學共好機制，厚植本校務實致用與大學社會責任實踐的 體現。' } },
        { id: uuidv4(), type: 'Title2', data: { title: '/計畫理念/' } },
        { id: uuidv4(), type: 'Text1', data: { text: '隨著城鄉發展思維變化及配合政府「新南向政策」，為善盡社會責任，國立臺灣科技大學從防災淨水之特色教學與社會關懷出發，擇定永續關懷主題，提出「行動工程師培育」計畫。' } },
        { id: uuidv4(), type: 'Text1', data: { text: '在智慧與科技應用下，透過本計畫之實踐新思維，提高在地居民對防災與環境問題的知識，意識到問題的正確解決方案，並有能力去解決這些問題，在全球各地中傳遞對自然尊重、在地人文與環境教育，為社區營造真正的永續環境，進而提高全' } },
        { id: uuidv4(), type: 'Title2', data: { title: '/推動場域/' } },
        {
          id: uuidv4(),
          type: 'Carousel1',
          data: {
            slides: [
              {
                imgUrl: '/img/project/Group311@2x.png',
                title: '印尼泗水Kedung Cowek, Kec. Bulak 漁村社區',
                content: '郊區村落資源匱乏，當地許多違建並無自來水 及浴廁等設施。團隊將著重於基礎衛生建設、 強化環境與衛生教育。',
              }, {
                imgUrl: '/img/project/Group311@2x.png',
                title: '印尼泗水Kedung Cowek, Kec. Bulak 漁村社區',
                content: '郊區村落資源匱乏，當地許多違建並無自來水 及浴廁等設施。團隊將著重於基礎衛生建設、 強化環境與衛生教育。',
              }, {
                imgUrl: '/img/project/Group311@2x.png',
                title: '印尼泗水Kedung Cowek, Kec. Bulak 漁村社區',
                content: '郊區村落資源匱乏，當地許多違建並無自來水 及浴廁等設施。團隊將著重於基礎衛生建設、 強化環境與衛生教育。',
              }, {
                imgUrl: '/img/project/Group311@2x.png',
                title: '印尼泗水Kedung Cowek, Kec. Bulak 漁村社區',
                content: '郊區村落資源匱乏，當地許多違建並無自來水 及浴廁等設施。團隊將著重於基礎衛生建設、 強化環境與衛生教育。',
              }, {
                imgUrl: '/img/project/Group311@2x.png',
                title: '印尼泗水Kedung Cowek, Kec. Bulak 漁村社區',
                content: '郊區村落資源匱乏，當地許多違建並無自來水 及浴廁等設施。團隊將著重於基礎衛生建設、 強化環境與衛生教育。',
              }, {
                imgUrl: '/img/project/Group311@2x.png',
                title: '印尼泗水Kedung Cowek, Kec. Bulak 漁村社區',
                content: '郊區村落資源匱乏，當地許多違建並無自來水 及浴廁等設施。團隊將著重於基礎衛生建設、 強化環境與衛生教育。',
              },
            ]
          }
        },
        { id: uuidv4(), type: 'Title2', data: { title: '/對應SDGs/' } },
        {
          id: uuidv4(),
          type: 'List2',
          data: {
            list: [
              {
                imgSrc: '/img/project/Rectangle123@2x.png',
                title: '6.永續水與衛生資源',
                text: '師生經盤點後，將村落所需的永續項目進行實踐，如潔淨生活用水、校園廁所翻修。',
              },
              {
                imgSrc: '/img/project/Rectangle121@2x.png',
                title: '11.社區安全與韌性',
                text: '於村落進行永續教育課程、社區防災監測以及文化保護。藉由設置自動化坡地監測設備，建置防災資料雲端平台，作為環境教育課程教材，以落實永續防災的概念。',
              },
              {
                imgSrc: '/img/project/Rectangle122@2x.png',
                title: '17.夥伴關係',
                text: '透過動員及分享知識、專業與科技等支援，協助當地部落實現永續發展目標。',
              },
            ]
          }
        },
        { id: uuidv4(), type: 'CaptionedImage', data: { url: '/img/project/Group307@2x.png', text: '印尼東爪哇省 Puhsarang Kediri 高地' } },
      ]
    },
    {
      sessionNo: 2,
      sessionName: '第二期 (109-111年)',
      order: 2,
      id: 3,
      image: '/img/projects/project@2x.png',
      title: '社區更新活化與韌性提升-以公館城南聚落為例',
      text: '臺科大團隊邀請公館水源地區之富水里、水源里、學府里及國防部共同參與，結合社區推動防救災工作，協助社區進行防災規劃，透過融入歷史建築更新活化議題，加深居民連結。',
      fbUrl: 'https://www.facebook.com/',
      ytUrl: 'https://www.youtube.com/',
      igUrl: 'https://www.instagram.com/',
      webUrl: 'https://d37hndef7an9tf.cloudfront.net/',
      managerEmail: '',
      components: [
        { id: uuidv4(), type: 'Title2', data: { title: '/計畫簡介/' } },
        { id: uuidv4(), type: 'Text1', data: { text: '本計畫旨在培育「行動工程師」，以基礎工程技術為出發點，喚醒學生的社會責任，培養出溝通的、關懷的、綠色的新一代工程師。' } },
        { id: uuidv4(), type: 'Text1', data: { text: '授與學生們透過專案學習(PBL)與印尼、越南姐妹校以及宜蘭東澳國小與四季國小師生合作，結合年輕人對於 貢獻社會及國際行動的渴望，創造多元的參與方案，讓同學從社區到國外，從臺灣走向國際，發光發亮。 此外，藉由本計劃，讓參與學生能親身的、深入的了解東南亞國家及台灣原民部落風土民情和社會現況，在與當地人 相處過程中更加認識彼此，並且在日後能與不同文化背景的居民在尊重和了解的基礎上，建立友善和緊密的夥伴關 係。' } },
        { id: uuidv4(), type: 'Text1', data: { text: '為強化行動工程師的實質運作內涵，在參與團隊的選擇與組織運作方面，積極整合校內跨領域系所專業且熱血教 師，包括化工、材料、營建、設計及機械等系所、「行動工程師」團隊學生(含本校本籍生與外籍生)，以及印、越姐 妹校師生參與，期盼藉由校內外的資源整合與建立雙邊學校共學共好機制，厚植本校務實致用與大學社會責任實踐的 體現。' } },
        { id: uuidv4(), type: 'Title2', data: { title: '/計畫理念/' } },
        { id: uuidv4(), type: 'Text1', data: { text: '隨著城鄉發展思維變化及配合政府「新南向政策」，為善盡社會責任，國立臺灣科技大學從防災淨水之特色教學與社會關懷出發，擇定永續關懷主題，提出「行動工程師培育」計畫。' } },
        { id: uuidv4(), type: 'Text1', data: { text: '在智慧與科技應用下，透過本計畫之實踐新思維，提高在地居民對防災與環境問題的知識，意識到問題的正確解決方案，並有能力去解決這些問題，在全球各地中傳遞對自然尊重、在地人文與環境教育，為社區營造真正的永續環境，進而提高全' } },
        { id: uuidv4(), type: 'Title2', data: { title: '/推動場域/' } },
        {
          id: uuidv4(),
          type: 'Carousel1',
          data: {
            slides: [
              {
                imgUrl: '/img/project/Group311@2x.png',
                title: '印尼泗水Kedung Cowek, Kec. Bulak 漁村社區',
                content: '郊區村落資源匱乏，當地許多違建並無自來水 及浴廁等設施。團隊將著重於基礎衛生建設、 強化環境與衛生教育。',
              }, {
                imgUrl: '/img/project/Group311@2x.png',
                title: '印尼泗水Kedung Cowek, Kec. Bulak 漁村社區',
                content: '郊區村落資源匱乏，當地許多違建並無自來水 及浴廁等設施。團隊將著重於基礎衛生建設、 強化環境與衛生教育。',
              }, {
                imgUrl: '/img/project/Group311@2x.png',
                title: '印尼泗水Kedung Cowek, Kec. Bulak 漁村社區',
                content: '郊區村落資源匱乏，當地許多違建並無自來水 及浴廁等設施。團隊將著重於基礎衛生建設、 強化環境與衛生教育。',
              }, {
                imgUrl: '/img/project/Group311@2x.png',
                title: '印尼泗水Kedung Cowek, Kec. Bulak 漁村社區',
                content: '郊區村落資源匱乏，當地許多違建並無自來水 及浴廁等設施。團隊將著重於基礎衛生建設、 強化環境與衛生教育。',
              }, {
                imgUrl: '/img/project/Group311@2x.png',
                title: '印尼泗水Kedung Cowek, Kec. Bulak 漁村社區',
                content: '郊區村落資源匱乏，當地許多違建並無自來水 及浴廁等設施。團隊將著重於基礎衛生建設、 強化環境與衛生教育。',
              }, {
                imgUrl: '/img/project/Group311@2x.png',
                title: '印尼泗水Kedung Cowek, Kec. Bulak 漁村社區',
                content: '郊區村落資源匱乏，當地許多違建並無自來水 及浴廁等設施。團隊將著重於基礎衛生建設、 強化環境與衛生教育。',
              },
            ]
          }
        },
        { id: uuidv4(), type: 'Title2', data: { title: '/對應SDGs/' } },
        {
          id: uuidv4(),
          type: 'List2',
          data: {
            list: [
              {
                imgSrc: '/img/project/Rectangle123@2x.png',
                title: '6.永續水與衛生資源',
                text: '師生經盤點後，將村落所需的永續項目進行實踐，如潔淨生活用水、校園廁所翻修。',
              },
              {
                imgSrc: '/img/project/Rectangle121@2x.png',
                title: '11.社區安全與韌性',
                text: '於村落進行永續教育課程、社區防災監測以及文化保護。藉由設置自動化坡地監測設備，建置防災資料雲端平台，作為環境教育課程教材，以落實永續防災的概念。',
              },
              {
                imgSrc: '/img/project/Rectangle122@2x.png',
                title: '17.夥伴關係',
                text: '透過動員及分享知識、專業與科技等支援，協助當地部落實現永續發展目標。',
              },
            ]
          }
        },
        { id: uuidv4(), type: 'CaptionedImage', data: { url: '/img/project/Group307@2x.png', text: '印尼東爪哇省 Puhsarang Kediri 高地' } },
      ]
    },
    {
      sessionNo: 2,
      sessionName: '第二期 (109-111年)',
      order: 3,
      id: 4,
      image: '/img/projects/project@2x.png',
      title: 'i設計愛學習-營造老少共學的多媒體互動智慧生活環境',
      text: '臺科大團隊邀請公館水源地區之富水里、水源里、學府里及國防部共同參與，結合社區推動防救災工作，協助社區進行防災規劃，透過融入歷史建築更新活化議題，加深居民連結。協助社區進行防災規劃，透過融入歷史建築更新活化議題，加深居民連結居民連結...',
      fbUrl: 'https://www.facebook.com/',
      ytUrl: 'https://www.youtube.com/',
      igUrl: 'https://www.instagram.com/',
      webUrl: 'https://d37hndef7an9tf.cloudfront.net/',
      managerEmail: '',
      components: [
        { id: uuidv4(), type: 'Title2', data: { title: '/計畫簡介/' } },
        { id: uuidv4(), type: 'Text1', data: { text: '本計畫旨在培育「行動工程師」，以基礎工程技術為出發點，喚醒學生的社會責任，培養出溝通的、關懷的、綠色的新一代工程師。' } },
        { id: uuidv4(), type: 'Text1', data: { text: '授與學生們透過專案學習(PBL)與印尼、越南姐妹校以及宜蘭東澳國小與四季國小師生合作，結合年輕人對於 貢獻社會及國際行動的渴望，創造多元的參與方案，讓同學從社區到國外，從臺灣走向國際，發光發亮。 此外，藉由本計劃，讓參與學生能親身的、深入的了解東南亞國家及台灣原民部落風土民情和社會現況，在與當地人 相處過程中更加認識彼此，並且在日後能與不同文化背景的居民在尊重和了解的基礎上，建立友善和緊密的夥伴關 係。' } },
        { id: uuidv4(), type: 'Text1', data: { text: '為強化行動工程師的實質運作內涵，在參與團隊的選擇與組織運作方面，積極整合校內跨領域系所專業且熱血教 師，包括化工、材料、營建、設計及機械等系所、「行動工程師」團隊學生(含本校本籍生與外籍生)，以及印、越姐 妹校師生參與，期盼藉由校內外的資源整合與建立雙邊學校共學共好機制，厚植本校務實致用與大學社會責任實踐的 體現。' } },
        { id: uuidv4(), type: 'Title2', data: { title: '/計畫理念/' } },
        { id: uuidv4(), type: 'Text1', data: { text: '隨著城鄉發展思維變化及配合政府「新南向政策」，為善盡社會責任，國立臺灣科技大學從防災淨水之特色教學與社會關懷出發，擇定永續關懷主題，提出「行動工程師培育」計畫。' } },
        { id: uuidv4(), type: 'Text1', data: { text: '在智慧與科技應用下，透過本計畫之實踐新思維，提高在地居民對防災與環境問題的知識，意識到問題的正確解決方案，並有能力去解決這些問題，在全球各地中傳遞對自然尊重、在地人文與環境教育，為社區營造真正的永續環境，進而提高全' } },
        { id: uuidv4(), type: 'Title2', data: { title: '/推動場域/' } },
        {
          id: uuidv4(),
          type: 'Carousel1',
          data: {
            slides: [
              {
                imgUrl: '/img/project/Group311@2x.png',
                title: '印尼泗水Kedung Cowek, Kec. Bulak 漁村社區',
                content: '郊區村落資源匱乏，當地許多違建並無自來水 及浴廁等設施。團隊將著重於基礎衛生建設、 強化環境與衛生教育。',
              }, {
                imgUrl: '/img/project/Group311@2x.png',
                title: '印尼泗水Kedung Cowek, Kec. Bulak 漁村社區',
                content: '郊區村落資源匱乏，當地許多違建並無自來水 及浴廁等設施。團隊將著重於基礎衛生建設、 強化環境與衛生教育。',
              }, {
                imgUrl: '/img/project/Group311@2x.png',
                title: '印尼泗水Kedung Cowek, Kec. Bulak 漁村社區',
                content: '郊區村落資源匱乏，當地許多違建並無自來水 及浴廁等設施。團隊將著重於基礎衛生建設、 強化環境與衛生教育。',
              }, {
                imgUrl: '/img/project/Group311@2x.png',
                title: '印尼泗水Kedung Cowek, Kec. Bulak 漁村社區',
                content: '郊區村落資源匱乏，當地許多違建並無自來水 及浴廁等設施。團隊將著重於基礎衛生建設、 強化環境與衛生教育。',
              }, {
                imgUrl: '/img/project/Group311@2x.png',
                title: '印尼泗水Kedung Cowek, Kec. Bulak 漁村社區',
                content: '郊區村落資源匱乏，當地許多違建並無自來水 及浴廁等設施。團隊將著重於基礎衛生建設、 強化環境與衛生教育。',
              }, {
                imgUrl: '/img/project/Group311@2x.png',
                title: '印尼泗水Kedung Cowek, Kec. Bulak 漁村社區',
                content: '郊區村落資源匱乏，當地許多違建並無自來水 及浴廁等設施。團隊將著重於基礎衛生建設、 強化環境與衛生教育。',
              },
            ]
          }
        },
        { id: uuidv4(), type: 'Title2', data: { title: '/對應SDGs/' } },
        {
          id: uuidv4(),
          type: 'List2',
          data: {
            list: [
              {
                imgSrc: '/img/project/Rectangle123@2x.png',
                title: '6.永續水與衛生資源',
                text: '師生經盤點後，將村落所需的永續項目進行實踐，如潔淨生活用水、校園廁所翻修。',
              },
              {
                imgSrc: '/img/project/Rectangle121@2x.png',
                title: '11.社區安全與韌性',
                text: '於村落進行永續教育課程、社區防災監測以及文化保護。藉由設置自動化坡地監測設備，建置防災資料雲端平台，作為環境教育課程教材，以落實永續防災的概念。',
              },
              {
                imgSrc: '/img/project/Rectangle122@2x.png',
                title: '17.夥伴關係',
                text: '透過動員及分享知識、專業與科技等支援，協助當地部落實現永續發展目標。',
              },
            ]
          }
        },
        { id: uuidv4(), type: 'CaptionedImage', data: { url: '/img/project/Group307@2x.png', text: '印尼東爪哇省 Puhsarang Kediri 高地' } },
      ]
    },
    {
      sessionNo: 2,
      sessionName: '第二期 (109-111年)',
      order: 4,
      id: 5,
      image: '/img/projects/project@2x.png',
      title: '共好2.0：走在我的身邊，成為我的夥伴-以公館城南聚落為例以公館城南】',
      text: '臺科大團隊邀請公館水源地區之富水里、水源里、學府里及國防部共同參與，結合社區推動防救災工作，協助社區進行防災規劃，透過融入歷史建築更新活化議題，加深居民連結深居民連深居民連深居民連深居民連深居民連深居民連深居民連深居民連。',
      fbUrl: 'https://www.facebook.com/',
      ytUrl: 'https://www.youtube.com/',
      igUrl: 'https://www.instagram.com/',
      webUrl: 'https://d37hndef7an9tf.cloudfront.net/',
      managerEmail: '',
      components: [
        { id: uuidv4(), type: 'Title2', data: { title: '/計畫簡介/' } },
        { id: uuidv4(), type: 'Text1', data: { text: '本計畫旨在培育「行動工程師」，以基礎工程技術為出發點，喚醒學生的社會責任，培養出溝通的、關懷的、綠色的新一代工程師。' } },
        { id: uuidv4(), type: 'Text1', data: { text: '授與學生們透過專案學習(PBL)與印尼、越南姐妹校以及宜蘭東澳國小與四季國小師生合作，結合年輕人對於 貢獻社會及國際行動的渴望，創造多元的參與方案，讓同學從社區到國外，從臺灣走向國際，發光發亮。 此外，藉由本計劃，讓參與學生能親身的、深入的了解東南亞國家及台灣原民部落風土民情和社會現況，在與當地人 相處過程中更加認識彼此，並且在日後能與不同文化背景的居民在尊重和了解的基礎上，建立友善和緊密的夥伴關 係。' } },
        { id: uuidv4(), type: 'Text1', data: { text: '為強化行動工程師的實質運作內涵，在參與團隊的選擇與組織運作方面，積極整合校內跨領域系所專業且熱血教 師，包括化工、材料、營建、設計及機械等系所、「行動工程師」團隊學生(含本校本籍生與外籍生)，以及印、越姐 妹校師生參與，期盼藉由校內外的資源整合與建立雙邊學校共學共好機制，厚植本校務實致用與大學社會責任實踐的 體現。' } },
        { id: uuidv4(), type: 'Title2', data: { title: '/計畫理念/' } },
        { id: uuidv4(), type: 'Text1', data: { text: '隨著城鄉發展思維變化及配合政府「新南向政策」，為善盡社會責任，國立臺灣科技大學從防災淨水之特色教學與社會關懷出發，擇定永續關懷主題，提出「行動工程師培育」計畫。' } },
        { id: uuidv4(), type: 'Text1', data: { text: '在智慧與科技應用下，透過本計畫之實踐新思維，提高在地居民對防災與環境問題的知識，意識到問題的正確解決方案，並有能力去解決這些問題，在全球各地中傳遞對自然尊重、在地人文與環境教育，為社區營造真正的永續環境，進而提高全' } },
        { id: uuidv4(), type: 'Title2', data: { title: '/推動場域/' } },
        {
          id: uuidv4(),
          type: 'Carousel1',
          data: {
            slides: [
              {
                imgUrl: '/img/project/Group311@2x.png',
                title: '印尼泗水Kedung Cowek, Kec. Bulak 漁村社區',
                content: '郊區村落資源匱乏，當地許多違建並無自來水 及浴廁等設施。團隊將著重於基礎衛生建設、 強化環境與衛生教育。',
              }, {
                imgUrl: '/img/project/Group311@2x.png',
                title: '印尼泗水Kedung Cowek, Kec. Bulak 漁村社區',
                content: '郊區村落資源匱乏，當地許多違建並無自來水 及浴廁等設施。團隊將著重於基礎衛生建設、 強化環境與衛生教育。',
              }, {
                imgUrl: '/img/project/Group311@2x.png',
                title: '印尼泗水Kedung Cowek, Kec. Bulak 漁村社區',
                content: '郊區村落資源匱乏，當地許多違建並無自來水 及浴廁等設施。團隊將著重於基礎衛生建設、 強化環境與衛生教育。',
              }, {
                imgUrl: '/img/project/Group311@2x.png',
                title: '印尼泗水Kedung Cowek, Kec. Bulak 漁村社區',
                content: '郊區村落資源匱乏，當地許多違建並無自來水 及浴廁等設施。團隊將著重於基礎衛生建設、 強化環境與衛生教育。',
              }, {
                imgUrl: '/img/project/Group311@2x.png',
                title: '印尼泗水Kedung Cowek, Kec. Bulak 漁村社區',
                content: '郊區村落資源匱乏，當地許多違建並無自來水 及浴廁等設施。團隊將著重於基礎衛生建設、 強化環境與衛生教育。',
              }, {
                imgUrl: '/img/project/Group311@2x.png',
                title: '印尼泗水Kedung Cowek, Kec. Bulak 漁村社區',
                content: '郊區村落資源匱乏，當地許多違建並無自來水 及浴廁等設施。團隊將著重於基礎衛生建設、 強化環境與衛生教育。',
              },
            ]
          }
        },
        { id: uuidv4(), type: 'Title2', data: { title: '/對應SDGs/' } },
        {
          id: uuidv4(),
          type: 'List2',
          data: {
            list: [
              {
                imgSrc: '/img/project/Rectangle123@2x.png',
                title: '6.永續水與衛生資源',
                text: '師生經盤點後，將村落所需的永續項目進行實踐，如潔淨生活用水、校園廁所翻修。',
              },
              {
                imgSrc: '/img/project/Rectangle121@2x.png',
                title: '11.社區安全與韌性',
                text: '於村落進行永續教育課程、社區防災監測以及文化保護。藉由設置自動化坡地監測設備，建置防災資料雲端平台，作為環境教育課程教材，以落實永續防災的概念。',
              },
              {
                imgSrc: '/img/project/Rectangle122@2x.png',
                title: '17.夥伴關係',
                text: '透過動員及分享知識、專業與科技等支援，協助當地部落實現永續發展目標。',
              },
            ]
          }
        },
        { id: uuidv4(), type: 'CaptionedImage', data: { url: '/img/project/Group307@2x.png', text: '印尼東爪哇省 Puhsarang Kediri 高地' } },
      ]
    },
    {
      sessionNo: 2,
      sessionName: '第二期 (109-111年)',
      order: 5,
      id: 6,
      image: '/img/projects/project@2x.png',
      title: '跨國合作改善在地水產養殖環境',
      text: '臺科大團隊邀請公館水源地區之富水里、水源里、學府里及國防部共同參與，結合社區推動防救災工作，協助社區進行防災規劃，透過融入歷史建築更新活化議題，加深居民連結。協助社區進行防災規劃，透過融入歷史建築更新活化議題，加深居民連結居民連結...',
      fbUrl: 'https://www.facebook.com/',
      ytUrl: 'https://www.youtube.com/',
      igUrl: 'https://www.instagram.com/',
      webUrl: 'https://d37hndef7an9tf.cloudfront.net/',
      managerEmail: '',
      components: [
        { id: uuidv4(), type: 'Title2', data: { title: '/計畫簡介/' } },
        { id: uuidv4(), type: 'Text1', data: { text: '本計畫旨在培育「行動工程師」，以基礎工程技術為出發點，喚醒學生的社會責任，培養出溝通的、關懷的、綠色的新一代工程師。' } },
        { id: uuidv4(), type: 'Text1', data: { text: '授與學生們透過專案學習(PBL)與印尼、越南姐妹校以及宜蘭東澳國小與四季國小師生合作，結合年輕人對於 貢獻社會及國際行動的渴望，創造多元的參與方案，讓同學從社區到國外，從臺灣走向國際，發光發亮。 此外，藉由本計劃，讓參與學生能親身的、深入的了解東南亞國家及台灣原民部落風土民情和社會現況，在與當地人 相處過程中更加認識彼此，並且在日後能與不同文化背景的居民在尊重和了解的基礎上，建立友善和緊密的夥伴關 係。' } },
        { id: uuidv4(), type: 'Text1', data: { text: '為強化行動工程師的實質運作內涵，在參與團隊的選擇與組織運作方面，積極整合校內跨領域系所專業且熱血教 師，包括化工、材料、營建、設計及機械等系所、「行動工程師」團隊學生(含本校本籍生與外籍生)，以及印、越姐 妹校師生參與，期盼藉由校內外的資源整合與建立雙邊學校共學共好機制，厚植本校務實致用與大學社會責任實踐的 體現。' } },
        { id: uuidv4(), type: 'Title2', data: { title: '/計畫理念/' } },
        { id: uuidv4(), type: 'Text1', data: { text: '隨著城鄉發展思維變化及配合政府「新南向政策」，為善盡社會責任，國立臺灣科技大學從防災淨水之特色教學與社會關懷出發，擇定永續關懷主題，提出「行動工程師培育」計畫。' } },
        { id: uuidv4(), type: 'Text1', data: { text: '在智慧與科技應用下，透過本計畫之實踐新思維，提高在地居民對防災與環境問題的知識，意識到問題的正確解決方案，並有能力去解決這些問題，在全球各地中傳遞對自然尊重、在地人文與環境教育，為社區營造真正的永續環境，進而提高全' } },
        { id: uuidv4(), type: 'Title2', data: { title: '/推動場域/' } },
        {
          id: uuidv4(),
          type: 'Carousel1',
          data: {
            slides: [
              {
                imgUrl: '/img/project/Group311@2x.png',
                title: '印尼泗水Kedung Cowek, Kec. Bulak 漁村社區',
                content: '郊區村落資源匱乏，當地許多違建並無自來水 及浴廁等設施。團隊將著重於基礎衛生建設、 強化環境與衛生教育。',
              }, {
                imgUrl: '/img/project/Group311@2x.png',
                title: '印尼泗水Kedung Cowek, Kec. Bulak 漁村社區',
                content: '郊區村落資源匱乏，當地許多違建並無自來水 及浴廁等設施。團隊將著重於基礎衛生建設、 強化環境與衛生教育。',
              }, {
                imgUrl: '/img/project/Group311@2x.png',
                title: '印尼泗水Kedung Cowek, Kec. Bulak 漁村社區',
                content: '郊區村落資源匱乏，當地許多違建並無自來水 及浴廁等設施。團隊將著重於基礎衛生建設、 強化環境與衛生教育。',
              }, {
                imgUrl: '/img/project/Group311@2x.png',
                title: '印尼泗水Kedung Cowek, Kec. Bulak 漁村社區',
                content: '郊區村落資源匱乏，當地許多違建並無自來水 及浴廁等設施。團隊將著重於基礎衛生建設、 強化環境與衛生教育。',
              }, {
                imgUrl: '/img/project/Group311@2x.png',
                title: '印尼泗水Kedung Cowek, Kec. Bulak 漁村社區',
                content: '郊區村落資源匱乏，當地許多違建並無自來水 及浴廁等設施。團隊將著重於基礎衛生建設、 強化環境與衛生教育。',
              }, {
                imgUrl: '/img/project/Group311@2x.png',
                title: '印尼泗水Kedung Cowek, Kec. Bulak 漁村社區',
                content: '郊區村落資源匱乏，當地許多違建並無自來水 及浴廁等設施。團隊將著重於基礎衛生建設、 強化環境與衛生教育。',
              },
            ]
          }
        },
        { id: uuidv4(), type: 'Title2', data: { title: '/對應SDGs/' } },
        {
          id: uuidv4(),
          type: 'List2',
          data: {
            list: [
              {
                imgSrc: '/img/project/Rectangle123@2x.png',
                title: '6.永續水與衛生資源',
                text: '師生經盤點後，將村落所需的永續項目進行實踐，如潔淨生活用水、校園廁所翻修。',
              },
              {
                imgSrc: '/img/project/Rectangle121@2x.png',
                title: '11.社區安全與韌性',
                text: '於村落進行永續教育課程、社區防災監測以及文化保護。藉由設置自動化坡地監測設備，建置防災資料雲端平台，作為環境教育課程教材，以落實永續防災的概念。',
              },
              {
                imgSrc: '/img/project/Rectangle122@2x.png',
                title: '17.夥伴關係',
                text: '透過動員及分享知識、專業與科技等支援，協助當地部落實現永續發展目標。',
              },
            ]
          }
        },
        { id: uuidv4(), type: 'CaptionedImage', data: { url: '/img/project/Group307@2x.png', text: '印尼東爪哇省 Puhsarang Kediri 高地' } },
      ]
    },
  ],
  history: {
    imgUrl: '/img/image1@2x.png',
    reports: [
      {
        time: new Date(2021, 9, 7),
        type: '報紙',
        title: '110年SIG議題交流系列活動-第一場次【大學x部落 - 啟動永續原動力】',
        url: 'https://www.google.com',
      },
      {
        time: new Date(2021, 8, 26),
        type: '雜誌',
        title: '110年SIG議題交流系列活動',
        url: 'https://www.google.com',
      },
      {
        time: new Date(2021, 5, 17),
        type: '採訪',
        title: '社區更新活化與韌性提升-以公館城南聚落為例',
        url: 'https://www.google.com',
      },
      {
        time: new Date(2021, 3, 29),
        type: '雜誌',
        title: '110年SIG議題交流系列活動-第一場次【大學x部落 - 啟動永續原動力】',
        url: 'https://www.google.com',
      },
      {
        time: new Date(2021, 2, 4),
        type: '採訪',
        title: '社區更新活化與韌性提升-以公館城南聚落為例',
        url: 'https://www.google.com',
      },
      {
        time: new Date(2021, 2, 4),
        type: '採訪',
        title: '社區更新活化與韌性提升-以公館城南聚落為例',
        url: 'https://www.google.com',
      },
      {
        time: new Date(2021, 2, 4),
        type: '採訪',
        title: '社區更新活化與韌性提升-以公館城南聚落為例',
        url: 'https://www.google.com',
      },
      {
        time: new Date(2021, 2, 4),
        type: '採訪',
        title: '社區更新活化與韌性提升-以公館城南聚落為例',
        url: 'https://www.google.com',
      },
    ],
    files: [
      {
        title: '社會責任報告書',
        url: '/img/footer@2x.5b34ffff.png',
        time: new Date(2021, 9, 7),
      },
      {
        title: '各計畫執行成果各計畫執行成果',
        url: '/img/footer@2x.5b34ffff.png',
        time: new Date(2021, 5, 16),
      },
    ]
  },
  highlights: [
    {
      id: 1,
      time: new Date(),
      imgSrc: '/img/history/history-image-1.png',
      title: '建構印尼泗水漁村小學簡易的飲用水(過濾+紫外線消毒)設備',
      text: 'Bulak社區小學內無乾淨的飲用水，師生習慣飲用瓶裝水，花錢而且造成過多的廢容器。2018年8月完成簡易的飲用水設備，以及教導社區居民、學生使用方式，並且將其設備維護管理交於當地社區居民。',
      components: [
        { id: uuidv4(), type: 'Text1', data: { text: '江布妮(Takbanuaz Ishahavut Pune)曾在流行時裝業工作多年，從台北回到故鄉加拿部落，生活中不時看到精緻的月桃編織，與母親閒聊，才發現自己對相伴長大的工藝，竟比不上對世界服飾潮流的認識，既歸斯土，當為斯人，不知不覺中，月桃成為布妮回到自身文化的連結。這些年來，布妮以時裝專業視角研究布農編織與符號體系，不僅成為了國家認證的工藝老師，巡迴各鄉鎮部落教授月桃編織，更利用每次出差與田調，帶回各地特有月桃，在家中的林園，進行保種與保育。' } },
        { id: uuidv4(), type: 'Text1', data: { text: '臺科大師生前年首次與布妮老師相遇，發現老師的月桃知識系統與作品，展現極高的教育價值及地方創生潛力，因長期耕耘，已建立核心團隊與教育場域，然而加拿部落位處花東縱谷山區，幽芳自放，一般大眾無從進入，部落的地域條件，有利於月桃保育，卻也限制了月桃工藝文化的推廣。' } },
        { id: uuidv4(), type: 'Text1', data: { text: '根據經濟學理論，移動力是個人突破地域限制，創造新機會的根源。在國家現代化的過程中，都會區域高密度的資源，吸引大量城鄉移民，也引致了偏鄉的空洞化。「I-Villages Plus城鄉牽手深化計畫」一直致力透過「移動力」的再建構，推動「由城入鄉、由鄉入城」跨域合作，協助偏鄉夥伴將影響力投射到地域之外，接軌都會區高密度資源。' } },
        { id: uuidv4(), type: 'Text1', data: { text: '我們的想法與布妮老師的願景產生了化學變化，在與深具文化推廣經驗的「玉溪有容」教育基金會深入討論後，三方決定攜手合作，由布妮老師帶領大家回到臺北都會區，分享月桃來自原鄉土地的雅緻與芬芳。' } },
        { id: uuidv4(), type: 'Text1', data: { text: '臺科大師生於去年寒假開始進入加拿部落「馬斯林恩月桃園區」，由布妮老師團隊指導月桃之生態、生活運用與工藝，並熟悉布農族的空間樣態。同一時間，玉溪有容基金會也派出專員全程紀錄觀察，並參與操作，提出在臺北Studio 94舉辦「場域移動」大型月桃展覽的構想。在此共同目標下，臺科大、基金會與布妮老師三方團隊展開協同操作，一方面透過月桃園區的環境改造實作，讓臺科大師生了解場域特性，建立日後於臺北展場布展能量，一方面布妮老師核心團隊對Studio 94進行空間解析，規劃月桃植栽、工藝品大規模移動與布農族場域的再現，而基金會則出錢出力，協助規劃展示之文宣、影像，邀請對象與導覽，以求展覽之最大擴散效應。三方合作，創造了大學社會責任實踐新的典範。' } },
        { id: uuidv4(), type: 'Text1', data: { text: '「SIZU 惜路-愛上月桃」展歷時二個月，成效卓著，參觀的個人、公司、社團超過千人，更有啟明學校學生，因地利之便，第一次進入場域，透過接觸、氣味、聲響，體會布農族月桃文化。布妮老師團隊受這次經驗的鼓勵，跨出下一步，成立「馬斯林恩布農文化環境保育協會」，繼續在地深耕，「SIZU 惜路」主題展於暑假後受邀到大稻埕「簡單生活」藝廊續展，由文化推廣跨入藝術商業，開啟了地方創生的新契機。臺科大與加拿部落建立堅定的夥伴關係，持續送訓學生，參與地方事務，也為花東縱谷關山生活圈之永續環境營造，帶來一股新的活力。' } },
        { id: uuidv4(), type: 'CaptionedImage', data: { url: '/img/highlight/picture@2x.png', text: '「SIZU惜路」-愛上月桃的布妮老師與夥伴們' } },
        { id: uuidv4(), type: 'Text2', data: { text: '臺科大、玉溪有容教育基金會及布妮老師三方團隊展開協同操作，透過月桃園區的環境改造實作，讓臺科大師生了解場域特性，建立日後於臺北展場布展能量；布妮老師核心團隊對Studio 94進行空間解析，規劃月桃植栽、工藝品大規模移動及布農族場域的再現；基金會則出錢出力，協助規劃展示之文宣、影像，邀請對象與導覽。' } },
      ],
      order: 0,
    },
    {
      id: 2,
      time: new Date(),
      imgSrc: '/img/history/history-image-2.png',
      title: '獲得聯發科技2020「智在家鄉」數位社會創新競賽優',
      text: '臺科大團隊邀請公館水源地區之富水里、水源里、學府里及國防部共同參與，結合社區推動防救災工作，協助社區進行防災規劃，透過融入歷史建築更新活化議題，透過融入歷史建築更新活化議題，透過融入歷史建築更新活化議題。',
      components: [
        { id: uuidv4(), type: 'Text1', data: { text: '江布妮(Takbanuaz Ishahavut Pune)曾在流行時裝業工作多年，從台北回到故鄉加拿部落，生活中不時看到精緻的月桃編織，與母親閒聊，才發現自己對相伴長大的工藝，竟比不上對世界服飾潮流的認識，既歸斯土，當為斯人，不知不覺中，月桃成為布妮回到自身文化的連結。這些年來，布妮以時裝專業視角研究布農編織與符號體系，不僅成為了國家認證的工藝老師，巡迴各鄉鎮部落教授月桃編織，更利用每次出差與田調，帶回各地特有月桃，在家中的林園，進行保種與保育。' } },
        { id: uuidv4(), type: 'Text1', data: { text: '臺科大師生前年首次與布妮老師相遇，發現老師的月桃知識系統與作品，展現極高的教育價值及地方創生潛力，因長期耕耘，已建立核心團隊與教育場域，然而加拿部落位處花東縱谷山區，幽芳自放，一般大眾無從進入，部落的地域條件，有利於月桃保育，卻也限制了月桃工藝文化的推廣。' } },
        { id: uuidv4(), type: 'Text1', data: { text: '根據經濟學理論，移動力是個人突破地域限制，創造新機會的根源。在國家現代化的過程中，都會區域高密度的資源，吸引大量城鄉移民，也引致了偏鄉的空洞化。「I-Villages Plus城鄉牽手深化計畫」一直致力透過「移動力」的再建構，推動「由城入鄉、由鄉入城」跨域合作，協助偏鄉夥伴將影響力投射到地域之外，接軌都會區高密度資源。' } },
        { id: uuidv4(), type: 'Text1', data: { text: '我們的想法與布妮老師的願景產生了化學變化，在與深具文化推廣經驗的「玉溪有容」教育基金會深入討論後，三方決定攜手合作，由布妮老師帶領大家回到臺北都會區，分享月桃來自原鄉土地的雅緻與芬芳。' } },
        { id: uuidv4(), type: 'Text1', data: { text: '臺科大師生於去年寒假開始進入加拿部落「馬斯林恩月桃園區」，由布妮老師團隊指導月桃之生態、生活運用與工藝，並熟悉布農族的空間樣態。同一時間，玉溪有容基金會也派出專員全程紀錄觀察，並參與操作，提出在臺北Studio 94舉辦「場域移動」大型月桃展覽的構想。在此共同目標下，臺科大、基金會與布妮老師三方團隊展開協同操作，一方面透過月桃園區的環境改造實作，讓臺科大師生了解場域特性，建立日後於臺北展場布展能量，一方面布妮老師核心團隊對Studio 94進行空間解析，規劃月桃植栽、工藝品大規模移動與布農族場域的再現，而基金會則出錢出力，協助規劃展示之文宣、影像，邀請對象與導覽，以求展覽之最大擴散效應。三方合作，創造了大學社會責任實踐新的典範。' } },
        { id: uuidv4(), type: 'Text1', data: { text: '「SIZU 惜路-愛上月桃」展歷時二個月，成效卓著，參觀的個人、公司、社團超過千人，更有啟明學校學生，因地利之便，第一次進入場域，透過接觸、氣味、聲響，體會布農族月桃文化。布妮老師團隊受這次經驗的鼓勵，跨出下一步，成立「馬斯林恩布農文化環境保育協會」，繼續在地深耕，「SIZU 惜路」主題展於暑假後受邀到大稻埕「簡單生活」藝廊續展，由文化推廣跨入藝術商業，開啟了地方創生的新契機。臺科大與加拿部落建立堅定的夥伴關係，持續送訓學生，參與地方事務，也為花東縱谷關山生活圈之永續環境營造，帶來一股新的活力。' } },
        { id: uuidv4(), type: 'CaptionedImage', data: { url: '/img/highlight/picture@2x.png', text: '「SIZU惜路」-愛上月桃的布妮老師與夥伴們' } },
        { id: uuidv4(), type: 'Text2', data: { text: '臺科大、玉溪有容教育基金會及布妮老師三方團隊展開協同操作，透過月桃園區的環境改造實作，讓臺科大師生了解場域特性，建立日後於臺北展場布展能量；布妮老師核心團隊對Studio 94進行空間解析，規劃月桃植栽、工藝品大規模移動及布農族場域的再現；基金會則出錢出力，協助規劃展示之文宣、影像，邀請對象與導覽。' } },
      ],
      order: 1,
    },
    {
      id: 3,
      time: new Date(),
      imgSrc: '/img/history/history-image-2.png',
      title: '共好2.0：走在我的身邊，成為我的夥伴成為我的夥伴',
      text: '臺科大團隊邀請公館水源地區之富水里、水源里、學府里及國防部共同參與，結合社區推動防救災工作，協助社區進行防災規劃，透過融入歷史建築更新活化議題。',
      components: [
        { id: uuidv4(), type: 'Text1', data: { text: '江布妮(Takbanuaz Ishahavut Pune)曾在流行時裝業工作多年，從台北回到故鄉加拿部落，生活中不時看到精緻的月桃編織，與母親閒聊，才發現自己對相伴長大的工藝，竟比不上對世界服飾潮流的認識，既歸斯土，當為斯人，不知不覺中，月桃成為布妮回到自身文化的連結。這些年來，布妮以時裝專業視角研究布農編織與符號體系，不僅成為了國家認證的工藝老師，巡迴各鄉鎮部落教授月桃編織，更利用每次出差與田調，帶回各地特有月桃，在家中的林園，進行保種與保育。' } },
        { id: uuidv4(), type: 'Text1', data: { text: '臺科大師生前年首次與布妮老師相遇，發現老師的月桃知識系統與作品，展現極高的教育價值及地方創生潛力，因長期耕耘，已建立核心團隊與教育場域，然而加拿部落位處花東縱谷山區，幽芳自放，一般大眾無從進入，部落的地域條件，有利於月桃保育，卻也限制了月桃工藝文化的推廣。' } },
        { id: uuidv4(), type: 'Text1', data: { text: '根據經濟學理論，移動力是個人突破地域限制，創造新機會的根源。在國家現代化的過程中，都會區域高密度的資源，吸引大量城鄉移民，也引致了偏鄉的空洞化。「I-Villages Plus城鄉牽手深化計畫」一直致力透過「移動力」的再建構，推動「由城入鄉、由鄉入城」跨域合作，協助偏鄉夥伴將影響力投射到地域之外，接軌都會區高密度資源。' } },
        { id: uuidv4(), type: 'Text1', data: { text: '我們的想法與布妮老師的願景產生了化學變化，在與深具文化推廣經驗的「玉溪有容」教育基金會深入討論後，三方決定攜手合作，由布妮老師帶領大家回到臺北都會區，分享月桃來自原鄉土地的雅緻與芬芳。' } },
        { id: uuidv4(), type: 'Text1', data: { text: '臺科大師生於去年寒假開始進入加拿部落「馬斯林恩月桃園區」，由布妮老師團隊指導月桃之生態、生活運用與工藝，並熟悉布農族的空間樣態。同一時間，玉溪有容基金會也派出專員全程紀錄觀察，並參與操作，提出在臺北Studio 94舉辦「場域移動」大型月桃展覽的構想。在此共同目標下，臺科大、基金會與布妮老師三方團隊展開協同操作，一方面透過月桃園區的環境改造實作，讓臺科大師生了解場域特性，建立日後於臺北展場布展能量，一方面布妮老師核心團隊對Studio 94進行空間解析，規劃月桃植栽、工藝品大規模移動與布農族場域的再現，而基金會則出錢出力，協助規劃展示之文宣、影像，邀請對象與導覽，以求展覽之最大擴散效應。三方合作，創造了大學社會責任實踐新的典範。' } },
        { id: uuidv4(), type: 'Text1', data: { text: '「SIZU 惜路-愛上月桃」展歷時二個月，成效卓著，參觀的個人、公司、社團超過千人，更有啟明學校學生，因地利之便，第一次進入場域，透過接觸、氣味、聲響，體會布農族月桃文化。布妮老師團隊受這次經驗的鼓勵，跨出下一步，成立「馬斯林恩布農文化環境保育協會」，繼續在地深耕，「SIZU 惜路」主題展於暑假後受邀到大稻埕「簡單生活」藝廊續展，由文化推廣跨入藝術商業，開啟了地方創生的新契機。臺科大與加拿部落建立堅定的夥伴關係，持續送訓學生，參與地方事務，也為花東縱谷關山生活圈之永續環境營造，帶來一股新的活力。' } },
        { id: uuidv4(), type: 'CaptionedImage', data: { url: '/img/highlight/picture@2x.png', text: '「SIZU惜路」-愛上月桃的布妮老師與夥伴們' } },
        { id: uuidv4(), type: 'Text2', data: { text: '臺科大、玉溪有容教育基金會及布妮老師三方團隊展開協同操作，透過月桃園區的環境改造實作，讓臺科大師生了解場域特性，建立日後於臺北展場布展能量；布妮老師核心團隊對Studio 94進行空間解析，規劃月桃植栽、工藝品大規模移動及布農族場域的再現；基金會則出錢出力，協助規劃展示之文宣、影像，邀請對象與導覽。' } },
      ],
      order: 2,
    },
    {
      id: 4,
      time: new Date(),
      imgSrc: '/img/history/history-image-2.png',
      title: '共好2.0：走在我的身邊，成為我的夥伴成為我的夥伴成為我的',
      text: '臺科大團隊邀請公館水源地區之富水里、水源里、學府里及國防部共同參與，結合社區推動防救災工作，協助社區進行防災規劃，透過融入歷史建築更新活化議題，透過融入歷史建築更新活化議題，透過融入歷史建築更新活化議題，加深居民連結加深居民連結加深居民連結加深居 民連結加深居民連結加深居民連結民連結民連結民連結連結結連結。',
      components: [
        { id: uuidv4(), type: 'Text1', data: { text: '江布妮(Takbanuaz Ishahavut Pune)曾在流行時裝業工作多年，從台北回到故鄉加拿部落，生活中不時看到精緻的月桃編織，與母親閒聊，才發現自己對相伴長大的工藝，竟比不上對世界服飾潮流的認識，既歸斯土，當為斯人，不知不覺中，月桃成為布妮回到自身文化的連結。這些年來，布妮以時裝專業視角研究布農編織與符號體系，不僅成為了國家認證的工藝老師，巡迴各鄉鎮部落教授月桃編織，更利用每次出差與田調，帶回各地特有月桃，在家中的林園，進行保種與保育。' } },
        { id: uuidv4(), type: 'Text1', data: { text: '臺科大師生前年首次與布妮老師相遇，發現老師的月桃知識系統與作品，展現極高的教育價值及地方創生潛力，因長期耕耘，已建立核心團隊與教育場域，然而加拿部落位處花東縱谷山區，幽芳自放，一般大眾無從進入，部落的地域條件，有利於月桃保育，卻也限制了月桃工藝文化的推廣。' } },
        { id: uuidv4(), type: 'Text1', data: { text: '根據經濟學理論，移動力是個人突破地域限制，創造新機會的根源。在國家現代化的過程中，都會區域高密度的資源，吸引大量城鄉移民，也引致了偏鄉的空洞化。「I-Villages Plus城鄉牽手深化計畫」一直致力透過「移動力」的再建構，推動「由城入鄉、由鄉入城」跨域合作，協助偏鄉夥伴將影響力投射到地域之外，接軌都會區高密度資源。' } },
        { id: uuidv4(), type: 'Text1', data: { text: '我們的想法與布妮老師的願景產生了化學變化，在與深具文化推廣經驗的「玉溪有容」教育基金會深入討論後，三方決定攜手合作，由布妮老師帶領大家回到臺北都會區，分享月桃來自原鄉土地的雅緻與芬芳。' } },
        { id: uuidv4(), type: 'Text1', data: { text: '臺科大師生於去年寒假開始進入加拿部落「馬斯林恩月桃園區」，由布妮老師團隊指導月桃之生態、生活運用與工藝，並熟悉布農族的空間樣態。同一時間，玉溪有容基金會也派出專員全程紀錄觀察，並參與操作，提出在臺北Studio 94舉辦「場域移動」大型月桃展覽的構想。在此共同目標下，臺科大、基金會與布妮老師三方團隊展開協同操作，一方面透過月桃園區的環境改造實作，讓臺科大師生了解場域特性，建立日後於臺北展場布展能量，一方面布妮老師核心團隊對Studio 94進行空間解析，規劃月桃植栽、工藝品大規模移動與布農族場域的再現，而基金會則出錢出力，協助規劃展示之文宣、影像，邀請對象與導覽，以求展覽之最大擴散效應。三方合作，創造了大學社會責任實踐新的典範。' } },
        { id: uuidv4(), type: 'Text1', data: { text: '「SIZU 惜路-愛上月桃」展歷時二個月，成效卓著，參觀的個人、公司、社團超過千人，更有啟明學校學生，因地利之便，第一次進入場域，透過接觸、氣味、聲響，體會布農族月桃文化。布妮老師團隊受這次經驗的鼓勵，跨出下一步，成立「馬斯林恩布農文化環境保育協會」，繼續在地深耕，「SIZU 惜路」主題展於暑假後受邀到大稻埕「簡單生活」藝廊續展，由文化推廣跨入藝術商業，開啟了地方創生的新契機。臺科大與加拿部落建立堅定的夥伴關係，持續送訓學生，參與地方事務，也為花東縱谷關山生活圈之永續環境營造，帶來一股新的活力。' } },
        { id: uuidv4(), type: 'CaptionedImage', data: { url: '/img/highlight/picture@2x.png', text: '「SIZU惜路」-愛上月桃的布妮老師與夥伴們' } },
        { id: uuidv4(), type: 'Text2', data: { text: '臺科大、玉溪有容教育基金會及布妮老師三方團隊展開協同操作，透過月桃園區的環境改造實作，讓臺科大師生了解場域特性，建立日後於臺北展場布展能量；布妮老師核心團隊對Studio 94進行空間解析，規劃月桃植栽、工藝品大規模移動及布農族場域的再現；基金會則出錢出力，協助規劃展示之文宣、影像，邀請對象與導覽。' } },
      ],
      order: 3,
    },
    {
      id: 5,
      time: new Date(),
      imgSrc: '/img/history/history-image-2.png',
      title: '獲得聯發科技2020「智在家鄉」數位社會創新競賽優',
      text: '臺科大團隊邀請公館水源地區之富水里、水源里、學府里及國防部共同參與，結合社區推動防救災工作，協助社區進行防災規劃，透過融入歷史建築更新活化議題，透過融入歷史建築更新活化議題，透過融入歷史建築更新活化議題。',
      components: [
        { id: uuidv4(), type: 'Text1', data: { text: '江布妮(Takbanuaz Ishahavut Pune)曾在流行時裝業工作多年，從台北回到故鄉加拿部落，生活中不時看到精緻的月桃編織，與母親閒聊，才發現自己對相伴長大的工藝，竟比不上對世界服飾潮流的認識，既歸斯土，當為斯人，不知不覺中，月桃成為布妮回到自身文化的連結。這些年來，布妮以時裝專業視角研究布農編織與符號體系，不僅成為了國家認證的工藝老師，巡迴各鄉鎮部落教授月桃編織，更利用每次出差與田調，帶回各地特有月桃，在家中的林園，進行保種與保育。' } },
        { id: uuidv4(), type: 'Text1', data: { text: '臺科大師生前年首次與布妮老師相遇，發現老師的月桃知識系統與作品，展現極高的教育價值及地方創生潛力，因長期耕耘，已建立核心團隊與教育場域，然而加拿部落位處花東縱谷山區，幽芳自放，一般大眾無從進入，部落的地域條件，有利於月桃保育，卻也限制了月桃工藝文化的推廣。' } },
        { id: uuidv4(), type: 'Text1', data: { text: '根據經濟學理論，移動力是個人突破地域限制，創造新機會的根源。在國家現代化的過程中，都會區域高密度的資源，吸引大量城鄉移民，也引致了偏鄉的空洞化。「I-Villages Plus城鄉牽手深化計畫」一直致力透過「移動力」的再建構，推動「由城入鄉、由鄉入城」跨域合作，協助偏鄉夥伴將影響力投射到地域之外，接軌都會區高密度資源。' } },
        { id: uuidv4(), type: 'Text1', data: { text: '我們的想法與布妮老師的願景產生了化學變化，在與深具文化推廣經驗的「玉溪有容」教育基金會深入討論後，三方決定攜手合作，由布妮老師帶領大家回到臺北都會區，分享月桃來自原鄉土地的雅緻與芬芳。' } },
        { id: uuidv4(), type: 'Text1', data: { text: '臺科大師生於去年寒假開始進入加拿部落「馬斯林恩月桃園區」，由布妮老師團隊指導月桃之生態、生活運用與工藝，並熟悉布農族的空間樣態。同一時間，玉溪有容基金會也派出專員全程紀錄觀察，並參與操作，提出在臺北Studio 94舉辦「場域移動」大型月桃展覽的構想。在此共同目標下，臺科大、基金會與布妮老師三方團隊展開協同操作，一方面透過月桃園區的環境改造實作，讓臺科大師生了解場域特性，建立日後於臺北展場布展能量，一方面布妮老師核心團隊對Studio 94進行空間解析，規劃月桃植栽、工藝品大規模移動與布農族場域的再現，而基金會則出錢出力，協助規劃展示之文宣、影像，邀請對象與導覽，以求展覽之最大擴散效應。三方合作，創造了大學社會責任實踐新的典範。' } },
        { id: uuidv4(), type: 'Text1', data: { text: '「SIZU 惜路-愛上月桃」展歷時二個月，成效卓著，參觀的個人、公司、社團超過千人，更有啟明學校學生，因地利之便，第一次進入場域，透過接觸、氣味、聲響，體會布農族月桃文化。布妮老師團隊受這次經驗的鼓勵，跨出下一步，成立「馬斯林恩布農文化環境保育協會」，繼續在地深耕，「SIZU 惜路」主題展於暑假後受邀到大稻埕「簡單生活」藝廊續展，由文化推廣跨入藝術商業，開啟了地方創生的新契機。臺科大與加拿部落建立堅定的夥伴關係，持續送訓學生，參與地方事務，也為花東縱谷關山生活圈之永續環境營造，帶來一股新的活力。' } },
        { id: uuidv4(), type: 'CaptionedImage', data: { url: '/img/highlight/picture@2x.png', text: '「SIZU惜路」-愛上月桃的布妮老師與夥伴們' } },
        { id: uuidv4(), type: 'Text2', data: { text: '臺科大、玉溪有容教育基金會及布妮老師三方團隊展開協同操作，透過月桃園區的環境改造實作，讓臺科大師生了解場域特性，建立日後於臺北展場布展能量；布妮老師核心團隊對Studio 94進行空間解析，規劃月桃植栽、工藝品大規模移動及布農族場域的再現；基金會則出錢出力，協助規劃展示之文宣、影像，邀請對象與導覽。' } },
      ],
      order: 4,
    },
    {
      id: 6,
      time: new Date(),
      imgSrc: '/img/history/history-image-2.png',
      title: '獲得聯發科技2020「智在家鄉」數位社會創新競賽優',
      text: '臺科大團隊邀請公館水源地區之富水里、水源里、學府里及國防部共同參與，結合社區推動防救災工作，協助社區進行防災規劃，透過融入歷史建築更新活化議題，透過融入歷史建築更新活化議題，透過融入歷史建築更新活化議題。',
      components: [
        { id: uuidv4(), type: 'Text1', data: { text: '江布妮(Takbanuaz Ishahavut Pune)曾在流行時裝業工作多年，從台北回到故鄉加拿部落，生活中不時看到精緻的月桃編織，與母親閒聊，才發現自己對相伴長大的工藝，竟比不上對世界服飾潮流的認識，既歸斯土，當為斯人，不知不覺中，月桃成為布妮回到自身文化的連結。這些年來，布妮以時裝專業視角研究布農編織與符號體系，不僅成為了國家認證的工藝老師，巡迴各鄉鎮部落教授月桃編織，更利用每次出差與田調，帶回各地特有月桃，在家中的林園，進行保種與保育。' } },
        { id: uuidv4(), type: 'Text1', data: { text: '臺科大師生前年首次與布妮老師相遇，發現老師的月桃知識系統與作品，展現極高的教育價值及地方創生潛力，因長期耕耘，已建立核心團隊與教育場域，然而加拿部落位處花東縱谷山區，幽芳自放，一般大眾無從進入，部落的地域條件，有利於月桃保育，卻也限制了月桃工藝文化的推廣。' } },
        { id: uuidv4(), type: 'Text1', data: { text: '根據經濟學理論，移動力是個人突破地域限制，創造新機會的根源。在國家現代化的過程中，都會區域高密度的資源，吸引大量城鄉移民，也引致了偏鄉的空洞化。「I-Villages Plus城鄉牽手深化計畫」一直致力透過「移動力」的再建構，推動「由城入鄉、由鄉入城」跨域合作，協助偏鄉夥伴將影響力投射到地域之外，接軌都會區高密度資源。' } },
        { id: uuidv4(), type: 'Text1', data: { text: '我們的想法與布妮老師的願景產生了化學變化，在與深具文化推廣經驗的「玉溪有容」教育基金會深入討論後，三方決定攜手合作，由布妮老師帶領大家回到臺北都會區，分享月桃來自原鄉土地的雅緻與芬芳。' } },
        { id: uuidv4(), type: 'Text1', data: { text: '臺科大師生於去年寒假開始進入加拿部落「馬斯林恩月桃園區」，由布妮老師團隊指導月桃之生態、生活運用與工藝，並熟悉布農族的空間樣態。同一時間，玉溪有容基金會也派出專員全程紀錄觀察，並參與操作，提出在臺北Studio 94舉辦「場域移動」大型月桃展覽的構想。在此共同目標下，臺科大、基金會與布妮老師三方團隊展開協同操作，一方面透過月桃園區的環境改造實作，讓臺科大師生了解場域特性，建立日後於臺北展場布展能量，一方面布妮老師核心團隊對Studio 94進行空間解析，規劃月桃植栽、工藝品大規模移動與布農族場域的再現，而基金會則出錢出力，協助規劃展示之文宣、影像，邀請對象與導覽，以求展覽之最大擴散效應。三方合作，創造了大學社會責任實踐新的典範。' } },
        { id: uuidv4(), type: 'Text1', data: { text: '「SIZU 惜路-愛上月桃」展歷時二個月，成效卓著，參觀的個人、公司、社團超過千人，更有啟明學校學生，因地利之便，第一次進入場域，透過接觸、氣味、聲響，體會布農族月桃文化。布妮老師團隊受這次經驗的鼓勵，跨出下一步，成立「馬斯林恩布農文化環境保育協會」，繼續在地深耕，「SIZU 惜路」主題展於暑假後受邀到大稻埕「簡單生活」藝廊續展，由文化推廣跨入藝術商業，開啟了地方創生的新契機。臺科大與加拿部落建立堅定的夥伴關係，持續送訓學生，參與地方事務，也為花東縱谷關山生活圈之永續環境營造，帶來一股新的活力。' } },
        { id: uuidv4(), type: 'CaptionedImage', data: { url: '/img/highlight/picture@2x.png', text: '「SIZU惜路」-愛上月桃的布妮老師與夥伴們' } },
        { id: uuidv4(), type: 'Text2', data: { text: '臺科大、玉溪有容教育基金會及布妮老師三方團隊展開協同操作，透過月桃園區的環境改造實作，讓臺科大師生了解場域特性，建立日後於臺北展場布展能量；布妮老師核心團隊對Studio 94進行空間解析，規劃月桃植栽、工藝品大規模移動及布農族場域的再現；基金會則出錢出力，協助規劃展示之文宣、影像，邀請對象與導覽。' } },
      ],
      order: 5,
    },
  ],
} as USRState)

export const usrPreviewState = reactive({
  home: {
    imgUrl: '',
    bannerImages: [],
    newsTitle: '',
    newsTitleEn: '',
    news: [],
    activityTitle: '近期活動',
    activityTitleEn: 'ACTIVITYS',
    videoTitle: '影音資訊',
    videoTitleEn: 'INFORMATION VIDEOS',
    videos: [],
    enewsTitle: '電子報',
    enewsTitleEn: 'E-NEWSLETTER',
  },
  activity: {
    id: 0,
    timestamp: new Date(),
    imgUrl: '',
    title: '',
    local: '',
    time: '',
    dmList: [],
    components: [],
  },
  news: {
    id: 0,
    time: new Date(),
    imgUrl: '',
    periods: '',
    periodsEn: '',
    title: '',
    titleEn: '',
    components: [],
    componentsEn: [],
    fbUrl: '',
    ytUrl: '',
    igUrl: '',
    webUrl: '',
  },
  about: {
    imgUrl: '',
    historyProjects: [],
    educationCenterImgUrl: '',
    educationCenterUrl: '',
    teamMemberImgUrl: '',
    teamMemberImgUrlMobile: '',
    projects: [],
  },
  projectHome: {
    imgUrl: '',
  },
  project: {
    sessionNo: 2,
    sessionName: '第二期 (109-111年)',
    id: 0,
    order: 0,
    image: '',
    title: '',
    text: '',
    managerEmail: '',
    components: [],
    fbUrl: '',
    ytUrl: '',
    igUrl: '',
    webUrl: '',
  },
  history: {
    imgUrl: '',
    reports: [],
    files: [],
  },
  highlight: {
    id: 0,
    time: new Date(),
    imgSrc: '',
    title: '',
    text: '',
    components: [],
    order: 0,
  },
} as USRPreviewState)

const updateState = (resource: USRState) => {
  usrState.home = Object.assign({
    newsTitle: '最新公告',
    newsTitleEn: 'WHAT’S NEWS',
    activityTitle: '近期活動',
    activityTitleEn: 'ACTIVITYS',
    videoTitle: '影音資訊',
    videoTitleEn: 'INFORMATION VIDEOS',
    enewsTitle: '電子報',
    enewsTitleEn: 'E-NEWSLETTER',
  }, resource.home) // failover newsTitle, activityTitle, videoTitle, enewsTitle
  usrState.about = resource.about
  usrState.history = resource.history
  usrState.activities = resource.activities
  usrState.news = resource.news
  usrState.projectHome = Object.assign({ imgUrl: '/img/image1@2x.png' }, resource.projectHome) // failover when no imgUrl
  usrState.projects = resource.projects
  usrState.highlights = resource.highlights
}

export const loadCache = (): void => {
  try {
    if (localStorage.getItem('resources')) {
      const resource = JSON.parse(localStorage.getItem('resources') as string) as USRState
      updateState(resource)
    }
  } catch (error) {
    console.log('load cache failed', error)
    localStorage.removeItem('resources')
  }

  try {
    if (localStorage.getItem('resourcesPreview')) {
      const resource = JSON.parse(localStorage.getItem('resourcesPreview') as string) as USRPreviewState
      Object.assign(usrPreviewState.home, resource.home)
      Object.assign(usrPreviewState.activity, resource.activity)
      Object.assign(usrPreviewState.news, resource.news)
      Object.assign(usrPreviewState.about, resource.about)
      Object.assign(usrPreviewState.projectHome, resource.projectHome)
      Object.assign(usrPreviewState.project, resource.project)
      Object.assign(usrPreviewState.history, resource.history)
      Object.assign(usrPreviewState.highlight, resource.highlight)
    }
  } catch (error) {
    console.log('load preview cache failed', error)
    localStorage.removeItem('resourcesPreview')
  }
}

export const updateResource = async (): Promise<void> => {
  return api.getResource().then(resource => {
    localStorage.setItem('resources', JSON.stringify(resource))
    updateState(resource)
  })
}

export interface ProjectSession {
  session: Session
  projects: Project[]
}

interface USRHelper {
  about: ComputedRef<About>
  home: ComputedRef<Home>
  projectHome: ComputedRef<ProjectHome>
  news: ComputedRef<News>
  project: ComputedRef<Project>
  activity: ComputedRef<Activity>
  highlight: ComputedRef<Highlight>
  history: ComputedRef<History>
  projectSessions: ComputedRef<ProjectSession[]>
  newsTitle: ComputedRef<string>
  newsComponents: ComputedRef<USRComponentType[]>
}

export const useUsrHelper = (): USRHelper => {
  const route = useRoute()
  const isPreview = computed(() => route.path.includes('Preview'))
  const isEn = computed(() => route.query.lang === 'en')

  const about = computed(() => (isPreview.value ? usrPreviewState : usrState).about as About)
  const home = computed(() => (isPreview.value ? usrPreviewState : usrState).home as Home)
  const projectHome = computed(() => (isPreview.value ? usrPreviewState : usrState).projectHome as ProjectHome)
  const news = computed(() => {
    const id = parseInt(route.params.id as string, 10)
    return (isPreview.value
      ? usrPreviewState.news
      : usrState.news.find(enews => enews.id === id)) as News
  })
  const project = computed(() => {
    const id = parseInt(route.params.id as string, 10)

    if (isPreview.value) {
      return usrPreviewState.project as Project
    }
    return usrState.projects.find(project => project.id === id) as Project
  })
  const activity = computed(() => {
    if (isPreview.value) {
      return usrPreviewState.activity as Activity
    }

    const id = parseInt(route.params.id as string, 10)
    return usrState.activities.find(activity => activity.id === id) as Activity
  })
  const highlight = computed(() => {
    if (isPreview.value) {
      return usrPreviewState.highlight as Highlight
    }

    const id = parseInt(route.params.id as string, 10)
    return usrState.highlights.find(highlight => highlight.id === id) as Highlight
  })
  const history = computed(() => {
    return ((isPreview.value ? usrPreviewState : usrState) as USRState).history
  })
  const projectSessions = computed(() => {
    return usrState.projects.reduce((projectSessions, project) => {
      let projectSession = projectSessions.find(projectSession => projectSession.session.sessionNo === project.sessionNo)
      if (!projectSession) {
        projectSession = {
          session: {
            sessionNo: project.sessionNo,
            sessionName: project.sessionName,
          },
          projects: [],
        }

        projectSessions.push(projectSession)
      }

      projectSession.projects.push(project)
      return projectSessions
    }, [] as ProjectSession[])
  })

  const newsTitle = computed(() => isEn.value ? news.value.titleEn : news.value.title)
  const newsComponents = computed(() => isEn.value ? news.value.componentsEn : news.value.components)

  return {
    about,
    home,
    projectHome,
    news,
    project,
    activity,
    highlight,
    history,
    projectSessions,
    newsTitle,
    newsComponents,
  }
}
