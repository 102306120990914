<template>
  <div class="content">
    <h1 :style="`font-size: var(--el-font-size-extra-large)`">首頁內容管理</h1>

    <El-Form ref="form" :model="state.editingData" label-width="80px"
      v-loading="state.editing"
    >
      <El-Form-Item label="Banner" v-if="state.isAdmin">
        <El-Card class="content">
          <El-Space wrap>
            <El-Card class="box-card" v-for="(bannerImage, index) in state.editingData.bannerImages" :key="index">
              <El-Row justify="end" style="font-size: var(--el-font-size-medium); margin-bottom: 10px">
                <El-Button
                  circle
                  @click="removeFromArray(state.editingData.bannerImages, bannerImage)"
                ><El-Icon><Close /></El-Icon></El-Button>
              </El-Row>
              <El-Form ref="form" :model="bannerImage" label-width="50px">
                <El-Form-Item label="PC">
                  <ImageUploader v-model="bannerImage.imgSrc" @upload="handleUpload" />
                </El-Form-Item>
              </El-Form>
              <El-Form ref="form" :model="bannerImage" label-width="50px">
                <El-Form-Item label="mobile">
                  <ImageUploader v-model="bannerImage.imgSrcMobile" @upload="handleUpload" />
                </El-Form-Item>
              </El-Form>
            </El-Card>
            <El-Button
              v-if="state.editingData.bannerImages.length < 5"
              circle
              @click="addToArray(state.editingData.bannerImages, bannerImageUrlTemplate, state.editingData.bannerImages.length)"
            ><El-Icon><Plus /></El-Icon></El-Button>
          </El-Space>
        </El-Card>
      </El-Form-Item>

      <El-Form-Item label="最新公告" v-if="state.isAdmin">
        <El-Form-Item label="中文標題">
          <El-Input v-model="state.editingData.newsTitle"></El-Input>
        </El-Form-Item>
        <El-Form-Item label="英文標題">
          <El-Input v-model="state.editingData.newsTitleEn"></El-Input>
        </El-Form-Item>
        <El-Card class="box-card" v-for="(item, index) in state.editingData.news" :key="index">
          <El-Row justify="end" style="font-size: var(--el-font-size-medium); margin-bottom: 10px">
            <El-Button
              circle
              @click="removeFromArray(state.editingData.news, item)"
            ><El-Icon><Close /></El-Icon></El-Button>
          </El-Row>
          <El-Form ref="form" :model="item" label-width="80px">
            <El-Form-Item label="時間">
              <El-Date-Picker v-model="item.time" type="date" placeholder="Pick a day" :default-value="new Date()" />
            </El-Form-Item>
            <El-Form-Item label="類型">
              <El-Input v-model="item.type"></El-Input>
            </El-Form-Item>
            <El-Form-Item label="標題">
              <El-Input v-model="item.title"></El-Input>
            </El-Form-Item>
            <El-Form-Item label="網址">
              <El-Input v-model="item.url"></El-Input>
            </El-Form-Item>
          </El-Form>
        </El-Card>
        <El-Row justify="center">
          <El-Button
            circle
            @click="addToArray(state.editingData.news, newsTemplate, state.editingData.news.length)"
          ><El-Icon><Plus /></El-Icon></El-Button>
        </El-Row>
      </El-Form-Item>

      <El-Form-Item label="近期活動" v-if="state.isAdmin">
        <El-Form-Item label="中文標題">
          <El-Input v-model="state.editingData.activityTitle"></El-Input>
        </El-Form-Item>
        <El-Form-Item label="英文標題">
          <El-Input v-model="state.editingData.activityTitleEn"></El-Input>
        </El-Form-Item>
      </El-Form-Item>

      <El-Form-Item label="影音資訊">
        <El-Form-Item label="中文標題" v-if="state.isAdmin">
          <El-Input v-model="state.editingData.videoTitle"></El-Input>
        </El-Form-Item>
        <El-Form-Item label="英文標題" v-if="state.isAdmin">
          <El-Input v-model="state.editingData.videoTitleEn"></El-Input>
        </El-Form-Item>

        <draggable
          tag="div"
          :list="state.editingData.videos"
          handle=".handle"
          item-key="id"
        >
          <template #item="{ element: item }">
            <El-Card class="box-card">
              <El-Row justify="space-between" align="middle" style="font-size: var(--el-font-size-medium); margin-bottom: 10px">
                <El-Icon class="handle"><D-Caret /></El-Icon>
                <El-Button
                  circle
                  @click="removeFromArray(state.editingData.videos, item)"
                ><El-Icon><Close /></El-Icon></El-Button>
              </El-Row>
              <El-Form ref="form" :model="item" label-width="100px">
                <El-Form-Item label="Youtube網址">
                  <El-Input v-model="item.ytUrl"></El-Input>
                </El-Form-Item>
                <El-Form-Item label="標題">
                  <El-Input v-model="item.title"></El-Input>
                </El-Form-Item>
                <El-Form-Item label="說明">
                  <El-Input v-model="item.text"></El-Input>
                </El-Form-Item>
              </El-Form>
            </El-Card>
          </template>
        </draggable>
        <El-Row justify="center">
          <El-Button
            circle
            @click="addToArray(state.editingData.videos, videoTemplate, state.editingData.videos.length)"
          ><El-Icon><Plus /></El-Icon></El-Button>
        </El-Row>
      </El-Form-Item>

      <El-Form-Item label="電子報" v-if="state.isAdmin">
        <El-Form-Item label="中文標題">
          <El-Input v-model="state.editingData.enewsTitle"></El-Input>
        </El-Form-Item>
        <El-Form-Item label="英文標題">
          <El-Input v-model="state.editingData.enewsTitleEn"></El-Input>
        </El-Form-Item>
      </El-Form-Item>
    </El-Form>
    <El-Row justify="center">
      <El-Button-Group>
        <El-Button type="info" @click="cancel">Cancel</El-Button>
        <El-Button type="primary" @click="save">Save</El-Button>
        <El-Button type="warning" @click="preview">Preview</El-Button>
      </El-Button-Group>
    </El-Row>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive, watch } from 'vue'
import ManagementLayout from '@/layout/ManagementLayout.vue'
import { usrState, usrPreviewState, HomeNews, HomeVideo, Home, updateResource } from '@/model'
import { Close, Plus, DCaret } from '@element-plus/icons'
import { addToArray, isAuthUserAdmin, removeFromArray } from '@/utils'
import { ElLoading } from 'element-plus'
import api from '@/api'
import ImageUploader from '@/components/ImageUploader.vue'
import draggable from 'vuedraggable'

export default defineComponent({
  name: 'ManageHomePage',
  components: {
    Close,
    Plus,
    DCaret,
    ImageUploader,
    draggable,
  },
  created() {
    this.$emit('update:layout', ManagementLayout)
  },
  setup() {
    function getEditingData() {
      return JSON.parse(JSON.stringify(usrState.home)) as Home
    }

    const state = reactive({
      isAdmin: false,
      editing: false,
      editingData: getEditingData() as Home,
    })

    isAuthUserAdmin().then(isAdmin => {
      state.isAdmin = isAdmin
    })

    watch(() => usrState.home, () => {
      console.log('updated!')
      state.editingData = getEditingData()
    })

    const cancel = () => {
      state.editingData = getEditingData()
    }

    const save = () => {
      const loading = ElLoading.service({
        lock: true,
        text: 'Loading',
        background: 'rgba(0, 0, 0, 0.7)',
      })

      api.updateHome(state.editingData as Home) // workaround as Home
        .then(() => updateResource())
        .then(() => loading.close())
        .catch(() => loading.close())
    }

    const preview = () => {
      usrPreviewState.home = JSON.parse(JSON.stringify(state.editingData))
      localStorage.setItem('resourcesPreview', JSON.stringify(usrPreviewState))
      window.open('/homePreview', '_blank')
    }

    const newsTemplate: HomeNews = { time: new Date(), type: '', title: '', url: '' }
    const videoTemplate: HomeVideo = { ytUrl: '', title: '', text: '' }
    const bannerImageUrlTemplate = { imgSrc: '' }

    const handleUpload = (promise: Promise<void>) => {
      const loading = ElLoading.service({
        lock: true,
        text: 'Loading',
        background: 'rgba(0, 0, 0, 0.7)',
      })
      promise
        .then(() => loading.close())
        .catch(() => loading.close())
    }

    return {
      state,
      newsTemplate,
      videoTemplate,
      bannerImageUrlTemplate,
      addToArray,
      removeFromArray,
      cancel,
      save,
      preview,
      handleUpload,
    }
  },
})
</script>

<style lang="scss" scoped>
</style>
