
import { defineComponent, reactive } from 'vue'
import MainLayout from '@/layout/MainLayout.vue'
import USRComponentList from '@/components/USRComponentList.vue'
import Breadcrumb from '@/components/Breadcrumb.vue'
import Title1 from '@/components/Title1.vue'
import { useUsrHelper } from '@/model'

export default defineComponent({
  components: {
    Breadcrumb,
    Title1,
    USRComponentList,
  },
  created() {
    this.$emit('update:layout', MainLayout)
  },
  setup() {
    const helper = useUsrHelper()

    const state = reactive({
      project: helper.project,
    })

    return {
      state,
    }
  },
})
