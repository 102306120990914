
import { computed, defineComponent, reactive } from 'vue'
import { Carousel, Slide, Navigation, Pagination } from 'vue3-carousel'
import MainLayout from '@/layout/MainLayout.vue'
import { usrState, Activity, useUsrHelper } from '@/model'
import { splitArrayList, formatTime } from '@/utils'
import Title1 from '@/components/Title1.vue'
import Breadcrumb from '@/components/Breadcrumb.vue'

export default defineComponent({
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
    Breadcrumb,
    Title1,
  },
  created() {
    this.$emit('update:layout', MainLayout)
  },
  setup () {
    const helper = useUsrHelper()

    const bannerSettings = {
      itemsToShow: 1,
      autoplay: 9000,
      transition: 1000,
    }

    const newsSettings = {
      itemsToShow: 1,
      snapAlign: 'start'
    }

    const activitySettings = {
      itemsToShow: 1,
      snapAlign: 'center'
    }
    const activityBreakpoints = {
      992: {
        itemsToShow: 3,
        snapAlign: 'start',
      },
    }
    const videoSettings = {
      itemsToShow: 1,
      snapAlign: 'start',
    }
    const videoBreakpoints = {
      992: {
        itemsToShow: 4,
        snapAlign: 'start',
      },
    }
    const enewsSettings = {
      itemsToShow: 1,
      snapAlign: 'start',
    }

    const state = reactive({
      home: helper.home,
      newsList: computed(() => splitArrayList(helper.home.value.news, 5)),
      activities: computed(() => usrState.activities.map(activity => activity)),
      enewsList: splitArrayList(usrState.news, 5),
    })

    const ytIframe = (ytUrl: string) => {
      const params = new URLSearchParams(ytUrl.split('?')[1])
      const v = params.get('v')

      return `https://www.youtube.com/embed/${v}?controls=0`
    }

    const activityImage = (activity: Activity) => {
      if (!activity.dmList || activity.dmList.length === 0) {
        return '/img/activity/default_banner.jpg'
      }
      return activity.dmList[0].imgSrc
    }

    return {
      state,
      bannerSettings,
      newsSettings,
      activitySettings,
      activityBreakpoints,
      videoSettings,
      videoBreakpoints,
      enewsSettings,
      activityImage,
      ytIframe,
      formatTime,
    }
  }
})
