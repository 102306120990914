<template>
  <div
    class="usr-title-4 wow fadeInUp"
    data-wow-duration="2s"
    v-html="title"
  >
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Title4',
  props: {
    title: {
      type: String,
      required: true,
    }
  },
  setup() {
  },
})
</script>

<style lang="scss">
.usr-title-4 {
  padding: 12px 0;
  background: transparent linear-gradient(270deg, #2C6D20 0%, #337321 8%, #88C12D 100%) 0% 0% no-repeat padding-box;
  text-align: center;
  font-size: 30px;
  line-height: 36px;
  font-weight: bold;
  font-family: Arial;
  letter-spacing: 0px;
  color: #F9F9F7;

  sup {
    vertical-align: super;
    font-weight: inherit;
  }
}

@media screen and (max-width: 991px) {
  .usr-title-4 {
    padding: 6px 12px 8px;
    font-size: 12px;
    line-height: 18px;
  }
}
</style>
