
import { defineComponent, PropType } from 'vue'
import { List2Item } from '@/model'

export default defineComponent({
  name: 'List2',
  props: {
    list: {
      type: Array as PropType<Array<List2Item>>,
      required: true,
    },
  },
})
