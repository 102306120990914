
import { List1Item } from '@/model'
import { defineComponent, computed, PropType } from 'vue'

export default defineComponent({
  name: 'List1',
  props: {
    list: {
      type: Array as PropType<Array<List1Item>>,
      required: true,
    },
  },
  setup(props) {
    const linkifyList = computed(() => {
      const urlRegex = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/g
      return props.list.map((item) => {
        return item.text.replace(urlRegex, (a: string, url: string) => `<a href="${url}" target="_blank">${url}</a>`)
      })
    })

    return {
      linkifyList,
    }
  },
})
