import { Auth } from 'aws-amplify'
import moment from 'moment'
import { CognitoUser } from 'amazon-cognito-identity-js'

export const splitArrayList = <T=unknown>(array: T[], length: number): T[][] => {
  const arrayCopy = [...array]
  const arrayList = []

  while (arrayCopy.length > 0) {
    arrayList.push(arrayCopy.splice(0, length))
  }

  return arrayList
}

export const formatTime = (date: Date, format: string): string => moment(date).format(format)

export const addToArray = (list: any[], item: any, index: number) => {
  const clonedItem = JSON.parse(JSON.stringify(item))
  list.splice(index, 0, clonedItem)
}

export const removeFromArray = (list: any[], item: any) => {
  const index = list.indexOf(item)
  list.splice(index, 1)
}

export const replaceArray = (list: any[], newList: any[]) => {
  list.splice(0, list.length, ...newList)
}

export default {
  splitArrayList,
  formatTime,
  addToArray,
  removeFromArray,
  replaceArray,
}

export const isAuthUserAdmin = async (): Promise<boolean> => {
  return Auth.currentAuthenticatedUser().then((user: CognitoUser) => {
    const groups = user.getSignInUserSession()?.getAccessToken().payload['cognito:groups'] || [] as string[]
    return groups.includes('admin')
  })
}
