<template>
  <table class="usr-table-1">
    <thead>
      <tr>
        <th>/&nbsp;年度&nbsp;/</th>
        <th>/&nbsp;類型&nbsp;/</th>
        <th>/&nbsp;計畫名稱&nbsp;/</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      <template v-for="(yearly, yearlyIndex) in data" :key="yearly.title + yearly.year">
        <tr :class="{ striped: yearlyIndex % 2 == 1}" v-for="(item, index) in yearly.items" :key="index">
          <td :rowspan="yearly.items.length" v-if="index==0">{{yearly.title}}<br v-if="yearly.title">{{yearly.year}}</td>
          <td :class="{ 'border-top': index > 0 }">{{item.type}}</td>
          <td :class="{ 'border-top': index > 0 }">{{item.name}}</td>
          <td></td>
        </tr>
      </template>
    </tbody>
  </table>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import { Table1Item } from '@/model'

export default defineComponent({
  name: 'Table1',
  props: {
    data: {
      type: Array as PropType<Array<Table1Item>>,
      required: true,
    }
  },
})
</script>

<style lang="scss">
.usr-table-1 {
  thead {
    tr {
      background: #2F752A;

      th {
        text-align: center;
        font-size: 22px;
        font-weight: bold;
        font-family: Arial;
        letter-spacing: 2.2px;
        color: #FFFFFF;
        padding-top: 15px;
        padding-bottom: 20px;

        &:nth-child(1) {
          width: 160px;
        }

        &:nth-child(2) {
          width: 240px;
          text-align: left;
        }

        &:nth-child(3) {
          text-align: left;
        }

        &:nth-child(4) {
          width: 67px;
        }
      }
    }
  }

  tbody {
    tr {
      background: #F5F3F1;

      td {
        padding-top: 13px;
        padding-bottom: 13px;
        font-size: 22px;
        font-family: Arial;
        letter-spacing: 2.2px;
        color: #040404;

        &[rowspan] {
          vertical-align: middle;
          text-align: center;
        }
      }

      &.striped {
        background: #DCD9D4;
      }
    }
  }

  .border-top {
    border-top: 1px solid #797070;
  }
}

@media screen and (max-width: 991px) {
  .usr-table-1 {
    thead {
      tr {
        background: #2F752A;

        th {
          font-size: 9px;
          padding-top: 6px;
          padding-bottom: 9px;

          &:nth-child(1) {
            width: 56px;
          }

          &:nth-child(2) {
            width: 90px;
          }

          &:nth-child(4) {
            width: 11px;
          }
        }
      }
    }

    tbody {
      tr {
        td {
          padding-top: 5px;
          padding-bottom: 3px;
          font-size: 9px;
          letter-spacing: 0;
        }
      }
    }
  }
}
</style>
