
import { defineComponent } from 'vue'
import USRComponent from '@/components/USRComponent.vue'

export default defineComponent({
  name: 'USRComponentList',
  props: {
    components: {
      type: Array,
      required: true,
    }
  },
  components: {
    USRComponent,
  },
  setup() {
  },
})
