
import { defineComponent, getCurrentInstance, reactive } from 'vue'
import ManagementLayout from '@/layout/ManagementLayout.vue'
import { useRouter } from 'vue-router'
import { usrState, Highlight, updateResource } from '@/model'
import { formatTime, isAuthUserAdmin } from '@/utils'
import { SortDown, SortUp } from '@element-plus/icons'
import { ElLoading } from 'element-plus'
import api from '@/api'

export default defineComponent({
  name: 'ManageHighlightListPage',
  components: {
    SortDown,
    SortUp,
  },
  created() {
    this.$emit('update:layout', ManagementLayout)
  },
  setup() {
    const proxy = getCurrentInstance()?.proxy
    const router = useRouter()

    const state = reactive({
      isAdmin: false,
      highlights: usrState.highlights,
      loading: false,
    })

    isAuthUserAdmin().then(isAdmin => {
      state.isAdmin = isAdmin
    })

    const handleEdit = (index: number, item: Highlight) => {
      router.push('/admin/highlights/' + item.id)
    }

    const handleDelete = (index: number, highlight: Highlight) => {
      console.log('handleDeleteHighlight', { index, highlight })
      proxy?.$confirm(`確認刪除「${highlight.title}(${highlight.id})」？`)
        .then(async () => {
          const loading = ElLoading.service({
            lock: true,
            text: 'Loading',
            background: 'rgba(0, 0, 0, 0.7)',
          })

          api.deleteHighlight(highlight.id)
            .then(() => updateResource())
            .then(() => loading.close())
            .catch(() => loading.close())
        })
        .catch(() => { /* prevent exception */ })
    }

    const handleChangeOrder = (highlight: Highlight, newOrder: number) => {
      console.log('handleChangeOrder', { highlight, newOrder })
      proxy?.$confirm(`修改順序「${highlight.title}(${highlight.id})」？`)
        .then(async () => {
          const loading = ElLoading.service({
            lock: true,
            text: 'Loading',
            background: 'rgba(0, 0, 0, 0.7)',
          })

          api.updateHighlight({ ...highlight, order: newOrder })
            .then(() => updateResource())
            .then(() => loading.close())
            .catch(() => loading.close())
        })
        .catch(() => { /* prevent exception */ })
    }

    return {
      state,
      handleEdit,
      handleDelete,
      handleChangeOrder,
      formatTime,
    }
  },
})
