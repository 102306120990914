<template>
  <div class="usr-breadcrumb">
    <a :href="url1">{{text1}}</a>
    <span>{{text2}}</span>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Breadcrumb',
  props: {
    url1: {
      type: String,
      required: true,
    },
    text1: {
      type: String,
      required: true,
    },
    text2: {
      type: String,
    },
  },
  setup() {
  },
})
</script>

<style lang="scss">
.usr-breadcrumb {
  a, span {
    font-size: 20px;
    letter-spacing: 1px;
    line-height: 24px;
    color: #262727;
  }

  * + *:before {
    content: " > ";
  }
}

@media screen and (max-width: 991px) {
  .usr-breadcrumb {
    a, span {
      font-size: 11px;
      letter-spacing: 0.55px;
      line-height: 13.2px;
    }
  }
}
</style>
