<template>
  <div class="highlight">
    <banner :url="state.highlight.imgSrc" />
    <div class="container content">
      <Breadcrumb text1="實踐紀錄" url1="/history" text2="亮點成果" />
      <Title1 title="亮點成果" subtitle="PROJECT HIGHLIGHTS" />
      <Title4 :title="state.highlight.title" />
      <USRComponentList :components="state.highlight.components" />
   </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive } from 'vue'
import Banner from '@/components/Banner.vue'
import Breadcrumb from '@/components/Breadcrumb.vue'
import Title1 from '@/components/Title1.vue'
import Title4 from '@/components/Title4.vue'
import USRComponentList from '@/components/USRComponentList.vue'
import MainLayout from '@/layout/MainLayout.vue'
import { useUsrHelper } from '@/model'

export default defineComponent({
  name: 'Highlight',
  components: {
    Banner,
    Breadcrumb,
    Title1,
    Title4,
    USRComponentList,
  },
  created() {
    this.$emit('update:layout', MainLayout)
  },
  setup () {
    const helper = useUsrHelper()

    const state = reactive({
      highlight: helper.highlight,
    })

    return {
      state,
    }
  }
})
</script>

<style lang="scss">
.highlight {
  > p {
    font: normal normal normal 25px/36px Arial;
    letter-spacing: 0px;
    color: #2F752A;
    opacity: 1;
  }
}
</style>
