<template>
  <div class="main-layout">
    <header :class="{active: state.active}" ref="header$">
      <a href="https://www.ntust.edu.tw"><img src="../assets/logo.svg" alt="" class="logo"></a>
      <label class="menu-button" :class="{active: state.active}" for="menuActive">
        <div></div>
        <div></div>
        <div></div>
        <input type="checkbox" name="" id="menuActive" v-model="state.active" hidden>
      </label>
      <nav class="nav">
        <div class="menu">
          <input id="nav_home" type="checkbox" hidden>
          <label class="hide-pc plus" for="nav_home"><img src="../assets/images/menu-plus.svg" alt=""></label>
          <label class="hide-pc minus" for="nav_home"><img src="../assets/images/menu-minus.svg" alt=""></label>
          <router-link to="/" :class="homeClass" @click="closeMenu">首頁</router-link>
          <ul @click="closeMenu">
            <li><a href="/#news">最新公告</a></li>
            <li><a href="/#activity">近期活動</a></li>
            <li><a href="/#video">影音資訊</a></li>
            <li><a href="/#enews">電子報</a></li>
          </ul>
        </div>
        <div class="menu">
          <input id="nav_about" type="checkbox" hidden>
          <label class="hide-pc plus" for="nav_about"><img src="../assets/images/menu-plus.svg" alt=""></label>
          <label class="hide-pc minus" for="nav_about"><img src="../assets/images/menu-minus.svg" alt=""></label>
          <router-link to="/about" :class="aboutClass" @click="closeMenu">關於我們</router-link>
          <ul @click="closeMenu">
            <li><a href="/about#ntustUsr">NTUST USR</a></li>
            <li><a href="/about#team">團隊人員簡介</a></li>
          </ul>
        </div>
        <div class="menu">
          <input id="nav_team" type="checkbox" hidden>
          <label class="hide-pc plus" for="nav_team"><img src="../assets/images/menu-plus.svg" alt=""></label>
          <label class="hide-pc minus" for="nav_team"><img src="../assets/images/menu-minus.svg" alt=""></label>
          <router-link to="/projects" :class="projectClass" @click="closeMenu">團隊與場域</router-link>
          <ul class="hide-pc">
            <li v-for="projectSession in sessions" :key="projectSession.session.sessionNo">
              <div class="menu">
                <input :id="'nav_session' + projectSession.session.sessionNo" type="checkbox" hidden>
                <label class="hide-pc plus" :for="'nav_session' + projectSession.session.sessionNo"><img src="../assets/images/menu-plus.svg" alt=""></label>
                <label class="hide-pc minus" :for="'nav_session' + projectSession.session.sessionNo"><img src="../assets/images/menu-minus.svg" alt=""></label>
                <router-link :to="'/projects?session=' + projectSession.session.sessionNo" @click="closeMenu">
                  {{projectSession.session.sessionName}}
                </router-link>
                <ul @click="closeMenu">
                  <li v-for="project in projectSession.projects" :key="project.id">
                    <router-link :to="'/projects/' + project.id">
                      {{project.title}}
                    </router-link>
                  </li>
                </ul>
              </div>
            </li>
          </ul>
          <ul class="hide-mobile" @click="closeMenu">
            <li v-for="projectSession in sessions" :key="projectSession.session.sessionNo">
              <router-link :to="'/projects?session=' + projectSession.session.sessionNo">
                {{projectSession.session.sessionName}}
              </router-link>
            </li>
          </ul>
        </div>
        <div class="menu">
          <input id="nav_history" type="checkbox" hidden>
          <label class="hide-pc plus" for="nav_history"><img src="../assets/images/menu-plus.svg" alt=""></label>
          <label class="hide-pc minus" for="nav_history"><img src="../assets/images/menu-minus.svg" alt=""></label>
          <router-link to="/history" :class="historyClass" @click="closeMenu">實踐紀錄</router-link>
          <ul @click="closeMenu">
            <li><a href="/history#report">媒體報導</a></li>
            <li><a href="/history#highlight">亮點成果</a></li>
            <li><a href="/history#files">檔案下載</a></li>
          </ul>
        </div>
      </nav>
    </header>
    <slot />
    <footer>
      <img class="hide-mobile" src="@/assets/images/footer@2x.png" title="footer">
      <img class="hide-pc" src="@/assets/images/footer-m@2x.png" title="footer">
      <div>
        <h1>國立臺灣科技大學</h1>
        <p><img src="@/assets/images/address.svg" alt="">106335 臺北市大安區基隆路 4 段 43 號</p>
        <p><img src="@/assets/images/phone.svg" alt="">(02) 2733-3141 #6946 #6953</p>
        <p>Copyright ©2021 國立臺灣科技大學</p>
      </div>
    </footer>
    <a class="sustainability" href="https://po.ntust.edu.tw/p/404-1059-126738.php?Lang=zh-tw" target="_blank">
      <img src="@/assets/images/sustainability.png" alt="">
    </a>
    <ScrollTop />
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, reactive, onMounted, onUnmounted, ref, watch } from 'vue'
import ScrollTop from '@/components/ScrollTop.vue'
import { useUsrHelper } from '@/model'
import { useRoute, useRouter } from 'vue-router'
export default defineComponent({
  name: 'MainLayout',
  components: {
    ScrollTop,
  },
  setup() {
    const helper = useUsrHelper()
    const router = useRouter()
    const route = useRoute()

    const closeMenu = () => {
      state.active = false
    }

    const state = reactive({
      active: false,
    })

    const homeClass = computed(() => {
      return { 'router-link-active': router.currentRoute.value.path.match(/homePreview|news|activities/g), }
    })
    const aboutClass = computed(() => {
      return { 'router-link-active': router.currentRoute.value.path.match(/about/g), }
    })
    const projectClass = computed(() => {
      return { 'router-link-active': router.currentRoute.value.path.match(/projects/g), }
    })
    const historyClass = computed(() => {
      return { 'router-link-active': router.currentRoute.value.path.match(/history|highlights/g), }
    })

    const header$ = ref()

    function handleClick(e: MouseEvent) {
      if (!header$.value?.contains(e.target)) {
        state.active = false
      }
    }

    watch(() => route.path, () => {
      window.scrollTo(0, 0)
    })

    onMounted(() => {
      document.addEventListener('click', handleClick)
    })

    onUnmounted(() => {
      document.removeEventListener('click', handleClick)
    })
    return {
      state,
      sessions: helper.projectSessions,
      header$,
      homeClass,
      aboutClass,
      projectClass,
      historyClass,
      closeMenu,
    }
  },
})
</script>

<style lang="scss">
.main-layout {
  text-align: justify;

  > header{
    width: 100%;
    height: 78px;
    background: #EBEBEB;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .logo {
      margin-left: 103px;
      height: 35px;
    }

    .menu-button {
      display: none;
    }

    nav {
      align-self: stretch;
      margin-right: 113px;
      width: auto;
      display: flex;

      .menu {
        position: relative;
        display: flex;
        align-items: center;

        > a {
          padding: 0 44px;
          font-size: 20px;
          line-height: 28px;
          letter-spacing: 3px;
          color: #3D3C3C;

          &.router-link-active {
            font-weight: bold;
            color: #277B32;
          }
        }

        &:not(:hover) {
          > ul {
            display: none;
          }
        }

        > ul {
          position: absolute;
          z-index: 10000;
          left: 0;
          right: 0;
          top: 100%;

          > li {
            > a {
              padding: 9px 19px;
              display: flex;
              justify-content: center;
              align-items: center;
              min-width: 141px;
              max-width: 221px;
              min-height: 43px;
              font-size: 15px;
              line-height: 17px;
              font-family: Arial;
              color: #656161;
              background-color: #EBEBEB;

              &:hover {
                background-color: #2F752A;
                color: white;
              }
            }
          }

          > li + li {
            > a {
              border-top: 1px solid #C0C1C1;
            }
          }
        }
      }

      > .menu + .menu {
        > a {
          border-left: 1px solid #C0C1C1;
        }
      }
    }
  }

  > footer{
    position: relative;
    display: flex;
    align-items: flex-end;
    justify-content: center;

    img {
      width: 100%;
    }

    div {
      position: absolute;
      display: flex;
      flex-direction: column;
      align-items: center;

      img {
        width: 17px;
        height: 17px;
        margin-right: 10px;
      }

      >:nth-child(1) {
        margin-bottom: 10px;
        font: normal normal bold 26px/26px Arial;
        letter-spacing: 1.3px;
        color: #FFFFFF;
      }

      >:nth-child(2) {
        margin-bottom: 10px;
        letter-spacing: 0.42px;
        font-size: 17px;
        color: #FFFFFF;
      }

      >:nth-child(3) {
        margin-bottom: 50px;
        letter-spacing: 0.42px;
        font: normal normal normal 17px/17px Arial;
        color: #FFFFFF;
      }

      >:nth-child(4) {
        margin-bottom: 37px;
        letter-spacing: 2.1px;
        font-size: 14px;
        color: #E1DED9;
      }
    }
  }

  .sustainability {
    position: fixed;
    right: 83px;
    bottom: 220px;
    cursor: pointer;

    img {
      width: 101px;
    }
  }
}

@media (max-width: 1210px) {
  .main-layout {
    > header {
      nav {
        > div {
          > a {
            padding: 0 24px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 991px) {
  :target {
    scroll-margin-top: 100px;
  }

  .main-layout {
    > header {
      height: 65px;
      position: fixed;
      z-index: 10000;

      .logo {
        margin-left: 33px;
        height: 25px;
      }

      .menu-button {
        margin-right: 27px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 26px;
        height: 18px;
        cursor: pointer;

        * {
          transition: 0.5s;
          border: 1px solid #6C6C6D;
        }

        &.active {
          position: relative;
          justify-content: center;

          *:nth-child(1) {
            transform: translateY(2px) rotate(45deg);
          }

          *:nth-child(2) {
            opacity: 0;
          }

          *:nth-child(3) {
            transform: translateY(-2px) rotate(-45deg);
          }
        }
      }

      nav {
        width: 239px;
        position: fixed;
        top: 65px;
        right: 0;
        max-height: calc(100% - 65px);
        max-width: 239px;
        overflow: auto;
        flex-direction: column;
        align-self: stretch;
        margin-right: 0;
        display: flex;
        background-color: #EBEAE6;

        .menu {
          display: flex;
          flex-direction: column;
          align-items: stretch;

          label {
            position: absolute;
            top: 13px;
            right: 13px;
          }

          input:checked + label.plus {
            display: none;
          }
          input:not(:checked) + label.plus + label.minus {
            display: none;
          }

          input:not(:checked) + label.plus + label.minus + a + ul {
            display: none;
          }

          input:checked + label.plus + label.minus + a {
            color: #040404;
            background-color: #2F752A;
            color: white;

            &:before {
              border-color: transparent transparent white transparent ;
            }
          }

          > a {
            padding: 15px 24px 13px;
            height: 50px;
            display: flex;
            align-items: center;
            text-align: left;
            font-size: 15px;
            font-weight: bold;
            line-height: 18px;
            color: #040404;

            &:before {
              margin-right: 7px;
              content: '';
              display: block;
              width: 0;
              height: 0;
              border-width: 0 0 8px 8px;
              border-style: solid;
              border-color: transparent transparent #2F752A transparent ;
            }

            &.router-link-active {
              color: #040404;
            }
          }

          &:not(:hover) {
            ul {
              display: revert;
            }
          }
          > ul {
            position: static;
            display: revert;

            > li {
              > a {
                padding-left: 57px;
                display: flex;
                justify-content: flex-start;
                max-width: initial;
                min-height: auto;
                font-size: 13px;
                line-height: 16px;
                color: #656161;
                background-color: #EBEBEB;

                &:hover {
                  background-color: #2F752A;
                  color: white;
                }
              }
            }
          }
        }

        > .menu + .menu {
          border-top: 1px solid #afafaf;

          > a {
            border-left: none;
          }
        }

        // layer 1
        > .menu > li + li {
          > a {
            border-top: 1px solid #afafaf;
          }
        }

        // layer 2
        > .menu > ul > li > .menu {
          > a {
            padding-left: 40px;
          }

          > ul {
            > li {
              > a {
                font-family: Arial;
                font-weight: normal;
                font-size: 13px;
                line-height: 15.6px;
                text-align: left;
                color: #256a20;

                &:hover {
                  color: white;
                }
              }
            }

            > li + li {
              > a {
                border-top: none;
              }
            }
          }
        }
      }
      &:not(.active) {
        nav {
          display: none;
        }
      }
    }

    > footer {
      div {
        img {
          width: 13px;
          height: 13px;
          margin-right: 10px;
        }

        >:nth-child(1) {
          margin-bottom: 6px;
          font: normal normal bold 20px/19px Arial;
          letter-spacing: 0.98px;
        }

        >:nth-child(2) {
          margin-bottom: 8px;
          letter-spacing: 0.32px;
          font-size: 13px;
        }

        >:nth-child(3) {
          margin-bottom: 31px;
          letter-spacing: 0.32px;
          font: normal normal normal 13px/13px Arial;
        }

        >:nth-child(4) {
          margin-bottom: 21px;
          letter-spacing: 0.6px;
          font-size: 12px;
        }
      }
    }

    > header + * {
      padding-top: 65px;
    }

    .sustainability {
      right: 20px;
      bottom: 85px;

      img {
        width: 50px;
      }
    }
  }
}
</style>
