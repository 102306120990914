
import { defineComponent, PropType } from 'vue'
import { Table1Item } from '@/model'

export default defineComponent({
  name: 'Table1',
  props: {
    data: {
      type: Array as PropType<Array<Table1Item>>,
      required: true,
    }
  },
})
