<template>
  <div class="content">
    <h1 :style="`font-size: var(--el-font-size-extra-large)`">{{isNew ? '建立電子報' : '編輯電子報'}}</h1>
    <!-- Debug: {{state.editingData}} -->
    <El-Form ref="form" :model="state.editingData" label-width="120px"
      v-loading="state.editing"
    >
      <El-Form-Item label="Id" v-if="state.editingData.id">
        <El-Input v-model="state.editingData.id" disabled></El-Input>
      </El-Form-Item>
      <El-Form-Item label="時間">
        <El-Date-Picker v-model="state.editingData.time" type="datetime" placeholder="Pick date and time" :default-value="new Date()" />
      </El-Form-Item>
      <El-Form-Item label="Banner">
        <ImageUploader v-model="state.editingData.imgUrl" @upload="handleUpload" />
      </El-Form-Item>
      <El-Form-Item label="Facebook網址">
        <El-Input v-model="state.editingData.fbUrl"></El-Input>
      </El-Form-Item>
      <El-Form-Item label="Youtube網址">
        <El-Input v-model="state.editingData.ytUrl"></El-Input>
      </El-Form-Item>
      <El-Form-Item label="Instagram網址">
        <El-Input v-model="state.editingData.igUrl"></El-Input>
      </El-Form-Item>
      <El-Form-Item label="網站">
        <El-Input v-model="state.editingData.webUrl"></El-Input>
      </El-Form-Item>
      <h2 :style="`font-size: var(--el-font-size-large)`">中文內容</h2>
      <El-Form-Item label="標題">
        <El-Input v-model="state.editingData.title"></El-Input>
      </El-Form-Item>
      <El-Form-Item label="期號">
        <El-Input v-model="state.editingData.periods"></El-Input>
      </El-Form-Item>
      <El-Form-Item label="自訂內容">
        <USRComponentListEditor :components="state.editingData.components" />
      </El-Form-Item>
      <h2 :style="`font-size: var(--el-font-size-large)`">英文內容</h2>
      <El-Form-Item label="標題">
        <El-Input v-model="state.editingData.titleEn"></El-Input>
      </El-Form-Item>
      <El-Form-Item label="期號">
        <El-Input v-model="state.editingData.periodsEn"></El-Input>
      </El-Form-Item>
      <El-Form-Item label="自訂內容">
        <USRComponentListEditor :components="state.editingData.componentsEn" />
      </El-Form-Item>
    </El-Form>

    <El-Row justify="center">
      <El-Button-Group>
        <El-Button type="info" @click="cancel">Cancel</El-Button>
        <El-Button type="primary" @click="save">{{isNew ? 'Add' : 'Edit'}}</El-Button>
        <El-Button type="warning" @click="preview">Preview</El-Button>
        <El-Button type="warning" @click="previewEn">Preview En</El-Button>
      </El-Button-Group>
    </El-Row>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive, watch } from 'vue'
import ManagementLayout from '@/layout/ManagementLayout.vue'
import { News, usrState, usrPreviewState, updateResource } from '@/model'
import { useRoute } from 'vue-router'
import USRComponentListEditor from '@/components/USRComponentListEditor.vue'
import { addToArray, removeFromArray } from '@/utils'
import { ElLoading } from 'element-plus'
import api from '@/api'
import ImageUploader from '@/components/ImageUploader.vue'

interface State {
  editing: boolean;
  editingData: News;
}

export default defineComponent({
  name: 'ManageNewsPage',
  components: {
    USRComponentListEditor,
    ImageUploader,
  },
  created() {
    this.$emit('update:layout', ManagementLayout)
  },
  setup() {
    const route = useRoute()
    const pId = route.params.id as string
    const isNew = pId === 'new'

    const editingData = getEditingData()

    function getEditingData(): News {
      const emptyNews = {
        id: 0,
        time: new Date(),
        imgUrl: '',
        periods: '',
        periodsEn: '',
        title: '',
        titleEn: '',
        components: [],
        componentsEn: [],
        fbUrl: '',
        ytUrl: '',
        igUrl: '',
        webUrl: '',
      } as News

      return Object.assign(emptyNews, isNew ? {} : usrState.news.find(news => news.id === parseInt(pId, 10)))
    }

    const state = reactive({
      editing: false,
      editingData,
    }) as State

    watch(() => usrState.news, () => {
      console.log('updated!')
      state.editingData = getEditingData()
    })

    const cancel = () => {
      state.editingData = getEditingData()
    }

    const save = () => {
      const loading = ElLoading.service({
        lock: true,
        text: 'Loading',
        background: 'rgba(0, 0, 0, 0.7)',
      })

      api.updateNews(state.editingData)
        .then(() => updateResource())
        .then(() => loading.close())
        .catch(() => loading.close())
    }

    const preview = () => {
      usrPreviewState.news = JSON.parse(JSON.stringify(state.editingData))
      localStorage.setItem('resourcesPreview', JSON.stringify(usrPreviewState))
      window.open('/news/Preview', '_blank')
    }

    const previewEn = () => {
      usrPreviewState.news = JSON.parse(JSON.stringify(state.editingData))
      localStorage.setItem('resourcesPreview', JSON.stringify(usrPreviewState))
      window.open('/news/Preview?lang=en', '__blank')
    }

    const handleUpload = (promise: Promise<void>) => {
      const loading = ElLoading.service({
        lock: true,
        text: 'Loading',
        background: 'rgba(0, 0, 0, 0.7)',
      })
      promise
        .then(() => loading.close())
        .catch(() => loading.close())
    }

    return {
      isNew,
      state,
      addToArray,
      removeFromArray,
      cancel,
      save,
      preview,
      previewEn,
      handleUpload,
    }
  },
})
</script>
<style lang="scss">
  .mt-10 {
    margin-top: 10px;
  }

  .el-card + * {
    margin-top: 10px
  }

  .el-form-item + * {
    margin-top: 10px;
  }
</style>
