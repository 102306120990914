<template>
  <img class="usr-banner" :src="url" alt="">
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Banner',
  props: {
    url: {
      type: String,
      required: true,
    },
  },
  setup() {
  },
})
</script>

<style lang="scss">
.usr-banner{
  width: 100%;
}

@media screen and (max-width: 991px) {
  .usr-link {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    span {
      padding: 1px 6px 1px;
      font-size: 11px;
      letter-spacing: 0.55px;
      line-height: 13.2px;
    }

    a {
      margin-left: 0;
      margin-top: 7px;
      font-size: 11px;
      letter-spacing: 0.55px;
      line-height: 13.2px;
    }
  }
}
</style>
